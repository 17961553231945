import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { RouteComponentProps, Redirect } from '@reach/router';
import { AuthContext } from '../../services/auth.service';
import SignIn from './SignIn';
import SignUp from './SignUp';
import ConfirmAccount from './ConfirmAccount';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Banner from '../Banner';

const Wrapper = styled.div`
  min-height: calc(100vh - 100px);
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoginScreen = (props: RouteComponentProps) => {
  const authFromContext: any = useContext(AuthContext);
  const [currentAuth, setCurrentAuth] = useState<string>('sign-in');
  const [banner, setBanner] = useState<{
    msg: string;
    type: 'success' | 'error';
    show: boolean;
  }>({
    msg: '',
    type: 'success',
    show: false,
  });
  const [autoFillUser, setAutoFillUser] = useState<{
    email: string;
    password: string;
  }>({ email: '', password: '' });

  return (
    <>
      {authFromContext === null ? (
        <Wrapper>
          {currentAuth === 'sign-in' && (
            <SignIn
              changeAuthState={setCurrentAuth}
              setBannerDetails={setBanner}
              setAutoFillData={setAutoFillUser}
            />
          )}
          {currentAuth === 'sign-up' && (
            <SignUp
              changeAuthState={setCurrentAuth}
              setBannerDetails={setBanner}
              setAutoFillData={setAutoFillUser}
            />
          )}
          {currentAuth === 'confirm-account' && (
            <ConfirmAccount
              changeAuthState={setCurrentAuth}
              setBannerDetails={setBanner}
              autoFillData={autoFillUser}
            />
          )}
          {currentAuth === 'forgot-password' && (
            <ForgotPassword
              changeAuthState={setCurrentAuth}
              setBannerDetails={setBanner}
              setAutoFillData={setAutoFillUser}
            />
          )}
          {currentAuth === 'reset-password' && (
            <ResetPassword
              changeAuthState={setCurrentAuth}
              autoFillData={autoFillUser}
              setBannerDetails={setBanner}
            />
          )}
          {banner.show && (
            <Banner
              msg={banner.msg}
              setShowBanner={(flag: boolean) => {
                setBanner({ ...banner, show: flag });
              }}
              type={banner.type}
            />
          )}
        </Wrapper>
      ) : (
        <Redirect to="/" noThrow />
      )}
    </>
  );
};

export default LoginScreen;
