import React from 'react';
import Cropper from 'react-cropper';
import { FormattedMessage } from 'react-intl';
import './cropper.css';
import styled from 'styled-components';
import Colors from '../../globals/Colors';
import { mobile, tablet } from '../../globals/Devices';

interface CropperProps {
  imageURL: string | ArrayBuffer;
  setCroppedData: any;
  height: number;
  width: number;
}

const CropTool = styled(Cropper)`
  display: flex;
  justify-content: center;
`;

const Wrapper = styled.div`
  width: 802px;
  border: 1px solid ${Colors.lightGrey};
  border-radius: 4px;
  background-color: ${Colors.zircon6};
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    width: 652px;
  }
  @media ${mobile} {
    width: 358px;
  }
`;

const Info = styled.div`
  color: ${Colors.black};
  font-size: 14px;
  padding: 5px;
`;

// const CropperDimensions = {
//   desktop: { height: 450, width: 800 },
//   mobile: { height: 200, width: 356 },
// };

const ImageCropper = (props: CropperProps) => {
  const { imageURL, setCroppedData, height, width } = props;

  function cropImage(cropper: any) {
    const croppedCanvas = cropper.getCroppedCanvas().toDataURL();
    setCroppedData(croppedCanvas);
    // console.log(cropper.getCroppedCanvas().toDataURL(), '---');
  }

  return (
    <>
      <Wrapper>
        <CropTool
          viewMode={3}
          src={imageURL.toString()}
          style={{
            height,
            width,
          }}
          guides={false}
          center={false}
          highlight={false}
          aspectRatio={16 / 9}
          zoomable={false}
          zoomOnTouch={false}
          zoomOnWheel={false}
          dragMode="move"
          cropBoxResizable={false}
          cropBoxMovable={false}
          minCanvasWidth={width}
          minCanvasHeight={height}
          minCropBoxWidth={width}
          minCropBoxHeight={height}
          autoCrop
          ready={(event: any) => cropImage(event.target.cropper)}
          cropend={(event: any) => cropImage(event.target.cropper)}
        />
        <Info>
          <FormattedMessage
            id="label.dragPhoto"
            defaultMessage="Drag to reposition cover photo"
          />
        </Info>
      </Wrapper>
    </>
  );
};

export default ImageCropper;
