import * as React from 'react';
import styled from 'styled-components';
import Colors from '../../globals/Colors';

const Thumbnail = styled.div`
  background-image: url(${({ url }: Props) => url});
  background-repeat: no-repeat;
  height: 186px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: ${Colors.lightGrey};
  border-radius: 4px;
  cursor: pointer;
`;

type Props = {
  url: string;
};

export default function PetitionImage(props: Props) {
  const { url } = props;
  const thumbnailUrl = url
    .split('?')[0]
    .replace(/\/public\//, '/public/thumbnails/thumbnail-');

  return <Thumbnail url={thumbnailUrl} />;
}
