import styled from 'styled-components';
import Colors from '../../../globals/Colors';
import { mobile } from '../../../globals/Devices';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  border: 0.3px solid ${Colors.lightGrey};
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  width: 450px;
  .title {
    margin: 20px 0 30px;
    font-size: 20px;
    font-weight: bold;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .link {
      width: 100%;
      text-align: left;
      padding-bottom: 10px;
      font-size: 14px;
      span {
        color: ${Colors.redOrange};
        cursor: pointer;
      }
    }
  }
  @media ${mobile} {
    width: 100%;
    border: 0;
    box-shadow: none;
    padding: 20px 10px;
    align-items: center;
  }
`;

export const Margin = styled.div`
  margin: 10px 0;
`;

export const Divider = styled.div`
  height: 40px;
  width: 100%;
  text-align: center;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  div {
    cursor: pointer;
    color: ${Colors.redOrange};
    font-size: 16px;
  }
`;
