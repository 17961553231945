import { Auth } from 'aws-amplify';
import { createContext } from 'react';

export function checkUser() {
  Auth.currentAuthenticatedUser()
    .then(user => console.log('Current user:', { user }))
    .catch(err => console.log('Could not retreive current user.', err));
}
export function signOut() {
  Auth.signOut()
    .then(data => console.log('User signed out.', data))
    .catch(err => console.log('User could not be signed out', err));
}

export const AuthContext = createContext(null);
