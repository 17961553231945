// tslint:disable
// this is an auto generated file. This will be overwritten

export const getPetitionFlag = /* GraphQL */ `
  query GetPetitionFlag($id: ID!) {
    getPetitionFlag(id: $id) {
      comment
      reason
      petitionId
      createdAt
      dateGrouping
      petition {
        id
        title
        target
        moderationStatus
        body
        createdAt
        owner
        imageUrl
        user {
          name
          email
        }
        publishedAt
        actionCounter
        queryParams
        flags {
          nextToken
        }
        lang
      }
    }
  }
`;
export const listPetitionFlags = /* GraphQL */ `
  query ListPetitionFlags(
    $filter: ModelPetitionFlagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPetitionFlags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        comment
        reason
        petitionId
        createdAt
        dateGrouping
        petition {
          id
          title
          target
          moderationStatus
          body
          createdAt
          owner
          imageUrl
          publishedAt
          actionCounter
          queryParams
          lang
        }
      }
      nextToken
    }
  }
`;
export const listActions = /* GraphQL */ `
  query ListActions(
    $email: String
    $petitionId: ModelIDKeyConditionInput
    $filter: ModelActionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listActions(
      email: $email
      petitionId: $petitionId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        petitionId
        email
        name
        country
        phoneNumber
        postalCode
        consent
        doubleOptIn
        queryParams
        createdAt
        lang
      }
      nextToken
    }
  }
`;
export const flagsByDateGrouping = /* GraphQL */ `
  query FlagsByDateGrouping(
    $dateGrouping: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPetitionFlagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    flagsByDateGrouping(
      dateGrouping: $dateGrouping
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        comment
        reason
        petitionId
        createdAt
        dateGrouping
        petition {
          id
          title
          target
          moderationStatus
          body
          createdAt
          owner
          imageUrl
          publishedAt
          actionCounter
          queryParams
          lang
          flags {
            items {
              comment
              reason
              petitionId
              createdAt
              dateGrouping
            }
            nextToken
          }
          user {
            name
            email
          }
        }
      }
      nextToken
    }
  }
`;
export const flagsByPetition = /* GraphQL */ `
  query FlagsByPetition(
    $petitionId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPetitionFlagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    flagsByPetition(
      petitionId: $petitionId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        comment
        reason
        petitionId
        createdAt
        dateGrouping
        petition {
          id
          title
          target
          moderationStatus
          body
          createdAt
          owner
          imageUrl
          publishedAt
          actionCounter
          queryParams
          lang
        }
      }
      nextToken
    }
  }
`;
export const listPetitions = /* GraphQL */ `
  query ListPetitions(
    $filter: ModelPetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPetitions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        target
        moderationStatus
        body
        createdAt
        owner
        imageUrl
        user {
          name
          email
        }
        publishedAt
        actionCounter
        queryParams
        flags {
          nextToken
        }
        lang
      }
      nextToken
    }
  }
`;
export const getPetition = /* GraphQL */ `
  query GetPetition($id: ID!) {
    getPetition(id: $id) {
      id
      title
      target
      moderationStatus
      body
      createdAt
      owner
      lang
      imageUrl
      user {
        name
        email
      }
      publishedAt
      actionCounter
      queryParams
      flags {
        items {
          comment
          reason
          petitionId
          createdAt
          dateGrouping
        }
        nextToken
      }
    }
  }
`;
export const petitionsByModerationStatus = /* GraphQL */ `
  query PetitionsByModerationStatus(
    $moderationStatus: ModerationStatus
    $publishedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    petitionsByModerationStatus(
      moderationStatus: $moderationStatus
      publishedAt: $publishedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        target
        moderationStatus
        body
        createdAt
        owner
        imageUrl
        user {
          name
          email
        }
        flags {
          items {
            comment
            reason
            petitionId
            createdAt
            dateGrouping
          }
          nextToken
        }
        publishedAt
        actionCounter
        queryParams
        flags {
          nextToken
        }
        lang
      }
      nextToken
    }
  }
`;
export const petitionsByOwner = /* GraphQL */ `
  query PetitionsByOwner(
    $owner: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPetitionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    petitionsByOwner(
      owner: $owner
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        target
        moderationStatus
        body
        createdAt
        owner
        imageUrl
        user {
          name
          email
        }
        publishedAt
        actionCounter
        queryParams
        flags {
          nextToken
        }
        lang
      }
      nextToken
    }
  }
`;
