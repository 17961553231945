import React from 'react';
import styled from 'styled-components';
import Pulse from 'react-reveal/Pulse';
import { FormattedMessage } from 'react-intl';

const PetitionNotAvailable = () => {
  const Content = styled.div`
    display: flex;
    flex-direction: column;
    height: 90vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    .main {
      font-size: 28px;
      font-weight: bold;
    }
  `;
  return (
    <Pulse>
      <Content>
        <span className="main">
          <FormattedMessage
            id="label.petitionNotAvailable"
            defaultMessage="The petition you're looking for isn't available."
          />
        </span>
        <br />
        <br />
        <span>
          <FormattedMessage
            id="label.urlIncorrect"
            defaultMessage="Either the URL is incorrect, or it violated our Community Guidelines, and was removed."
          />
        </span>
      </Content>
    </Pulse>
  );
};

export default PetitionNotAvailable;
