import React, { useState } from 'react';
import Moment from 'react-moment';
import { API, graphqlOperation } from 'aws-amplify';

import {
  Card,
  Header,
  Author,
  Avatar,
  Details,
  Flag,
  Icon,
  Status,
  ImageFromS3,
  Title,
  Supporters,
  ActionButton,
} from './ListingStyles';
import ReportReasonsModal from './ReportReasonsModal';
import { PetitionInfo } from './PetitionInterfaces';
import TakeActionModal from './TakeActionModal';
import { updatePetition } from '../../graphql/mutations';

type PetitionProps = {
  petition: PetitionInfo;
  currentStatusType: string;
};

const PetitionCard = (props: PetitionProps) => {
  const { petition, currentStatusType } = props;
  const [reportReasonsModal, setReportReasonsModal] = useState<boolean>(false);
  const [modalData, setModalData] = useState<{
    petition: PetitionInfo | null;
    open: boolean;
  }>({ petition: null, open: false });

  function getInitials(name: string | null) {
    if (name) {
      const splitName = name.split(' ');
      return splitName.length > 1
        ? splitName[0].substr(0, 1) + splitName[1].substr(0, 1)
        : name.substr(0, 1);
    }
    return '';
  }

  function getImageUrl(imageUrl: string) {
    const thumbnailUrl = imageUrl
      .split('?')[0]
      .replace(/\/public\//, '/public/thumbnails/thumbnail-');
    return thumbnailUrl;
  }

  async function updatePetitionData(values: any) {
    // eslint-disable-next-line no-return-await
    return await API.graphql(
      graphqlOperation(updatePetition, {
        input: values,
      })
    );
  }

  function savePetition(petitionData: PetitionInfo | null, status: string) {
    if (petitionData) {
      const values = {
        id: petition.id,
        moderationStatus: status,
      };

      updatePetitionData({ ...values })
        .then((resp: any) => {
          petition.moderationStatus = status;
          setModalData({ petition: null, open: false });
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  return (
    <Card>
      <Header>
        <Author>
          <Avatar>{getInitials(petition?.user?.name)}</Avatar>
          <Details>
            <a
              href={`mailto:${petition.user.email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="name"
            >
              {petition.user.name}
            </a>
            <span className="time">
              {petition.publishedAt ? (
                <Moment fromNow>{petition.publishedAt.substring(3)}</Moment>
              ) : (
                ''
              )}
            </span>
          </Details>
        </Author>
        {petition.flags.items?.length > 0 && (
          <Flag>
            {currentStatusType === 'Reported' && (
              <Status>{petition.moderationStatus}</Status>
            )}
            <Icon onClick={() => setReportReasonsModal(true)}>
              <i className="fa fa-exclamation-triangle" aria-hidden="true" />
            </Icon>
            <ReportReasonsModal
              reasons={petition.flags.items}
              openModal={reportReasonsModal}
              setOpenModal={() => setReportReasonsModal(false)}
            />
          </Flag>
        )}
      </Header>
      <ImageFromS3 src={getImageUrl(petition.imageUrl)} />
      <Title
        href={`${window.location.origin}/petition/${petition.id}/`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {petition.title}
      </Title>
      <Supporters>
        Currently has {petition.actionCounter ? petition.actionCounter : 0}{' '}
        supporters
      </Supporters>
      {currentStatusType !== 'Reported' &&
      petition.moderationStatus !== currentStatusType.toUpperCase() ? (
        <ActionButton
          type="button"
          style={{
            fontSize: '14px',
          }}
          disabled
        >
          Changed to {petition.moderationStatus} just now
        </ActionButton>
      ) : (
        <ActionButton
          type="button"
          onClick={() => {
            setModalData({ petition, open: true });
          }}
        >
          Take Action
        </ActionButton>
      )}
      <TakeActionModal
        modalData={modalData}
        petitionListStatusType={currentStatusType}
        setModalData={(petition: PetitionInfo | null, open: boolean) =>
          setModalData({ petition, open })
        }
        savePetition={(petitionData: PetitionInfo, statusSelected: string) => {
          savePetition(petitionData, statusSelected);
        }}
      />
    </Card>
  );
};

export default PetitionCard;
