/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Colors from '../../globals/Colors';

interface KnownUserProps {
  user: any;
  setHandleReset: any;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  div {
    display: flex;
    flex-direction: row;
    &:focus {
      outline: none;
    }
    span {
      font-weight: 600;
    }
  }
  .link {
    color: ${Colors.redOrange};
    font-size: 14px;
    cursor: pointer;
  }
`;

const stroke = keyframes`
  100% { stroke-dashoffset: 0; }
`;

const SVGCircle = styled.svg`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: ${Colors.white};
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px ${Colors.robinsEggBlue};
  box-shadow: inset 0px 0px 0px 30px ${Colors.robinsEggBlue};
  margin-right: 10px;
`;

const Circle = styled.circle`
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: ${Colors.robinsEggBlue};
  fill: none;
  stroke-dashoffset: 0;
`;
const Tick = styled.path`
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
`;

const KnownUser = (props: KnownUserProps) => {
  const { user, setHandleReset } = props;
  return (
    <Wrapper>
      <div>
        <SVGCircle xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <Circle cx="26" cy="26" r="25" fill="none" />
          <Tick fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </SVGCircle>
        <span className="Name">{user.name}</span>
      </div>
      <div className="link" onClick={() => setHandleReset()}>
        <FormattedMessage id="label.reset" defaultMessage="Reset" />
      </div>
    </Wrapper>
  );
};

export default KnownUser;
