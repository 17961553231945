/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import Input from '../Input';
import Button from '../Button';
import FBLogin from './FacebookLogin';
import { Wrapper, Margin, Divider, Links } from './helpers/AuthStyles';
import { SignInValidationSchema } from './helpers/ValidationSchema';

interface SignInProps {
  changeAuthState: any;
  setBannerDetails: any;
  setAutoFillData: any;
}
interface SignInFormValues {
  email: string;
  password: string;
}

const SignIn = (props: SignInProps) => {
  const { changeAuthState, setBannerDetails, setAutoFillData } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const { formatMessage: fm } = useIntl();

  const initialValues: SignInFormValues = {
    email: '',
    password: '',
  };

  function determineFlow(errCode: string, values: SignInFormValues) {
    switch (errCode) {
      case 'UserNotConfirmedException':
        setAutoFillData({
          email: values.email,
          password: values.password,
        });
        setBannerDetails({
          msg: fm({
            id: 'label.verifyPwd',
            defaultMessage: 'Please verify your account',
          }),
          type: 'success',
          show: true,
        });
        Auth.resendSignUp(values.email);
        changeAuthState('confirm-account');
        break;
      case 'UserNotFoundException':
      case 'ResourceNotFoundException':
        setBannerDetails({
          msg: fm(
            {
              id: 'msg.noAccExists',
              defaultMessage: `No account exists for user with ${values.email}, Create one to login.`,
            },
            { email: values.email }
          ),
          type: 'error',
          show: true,
        });
        break;
      case 'NotAuthorizedException':
        setBannerDetails({
          msg: fm({
            id: 'msg.incorrectCreds',
            defaultMessage: `Incorrect username or password, please check again`,
          }),
          type: 'error',
          show: true,
        });
        break;
      default:
        setBannerDetails({
          msg: fm({
            id: 'msg.tryAgainLater',
            defaultMessage: `Please try again later`,
          }),
          type: 'error',
          show: true,
        });
        break;
    }
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: false,
    validationSchema: SignInValidationSchema,
    onSubmit: (values: SignInFormValues) => {
      setBannerDetails({ msg: '', type: 'success', show: false });
      setLoading(true);
      Auth.signIn({
        username: values.email,
        password: values.password,
      })
        .then((resp: any) => {
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          if (err && err.code) {
            determineFlow(err.code, values);
          }
        });
    },
  });

  return (
    <Wrapper>
      <div className="title">
        <FormattedMessage
          id="label.signInLocal"
          defaultMessage="Sign in to your account"
        />
      </div>
      <FBLogin
        label={fm({
          id: 'label.signInFB',
          defaultMessage: 'Sign in with Facebook',
        })}
      />
      <Divider>
        <FormattedMessage id="label.or" defaultMessage="or" />
      </Divider>
      <form onSubmit={formik.handleSubmit}>
        <Input
          autoFocus={false}
          required
          id="email"
          name="email"
          type="text"
          placeholder={fm({
            id: 'label.email',
            defaultMessage: 'Email',
          })}
          onChange={formik.handleChange}
          value={formik.values.email}
          error={
            formik.errors.email ? fm({ id: `${formik.errors.email}` }) : ''
          }
        />
        <Margin />
        <Input
          autoFocus={false}
          required
          id="password"
          name="password"
          type="password"
          placeholder={fm({
            id: 'label.password',
            defaultMessage: 'Password',
          })}
          onChange={formik.handleChange}
          value={formik.values.password}
          error={
            formik.errors.password
              ? fm({ id: `${formik.errors.password}` })
              : ''
          }
        />
        <Margin />
        <Button
          type="submit"
          disabled={loading}
          onClick={() => {
            formik.validateForm().then((errors: {}) => {
              console.log(Object.keys(errors));
            });
          }}
        >
          {loading
            ? fm({
                id: 'msg.signingIn',
                defaultMessage: 'Signing in...',
              })
            : fm({
                id: 'label.signIn',
                defaultMessage: 'Sign in',
              })}
        </Button>
      </form>
      <Links>
        <div onClick={() => changeAuthState('forgot-password')}>
          <FormattedMessage
            id="label.forgotPwd"
            defaultMessage="Forgot password"
          />
        </div>
        <div onClick={() => changeAuthState('sign-up')}>
          <FormattedMessage id="label.signUp" defaultMessage="Sign Up" />
        </div>
      </Links>
    </Wrapper>
  );
};

export default SignIn;
