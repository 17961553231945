import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import { navigate, globalHistory } from '@reach/router';
import { FormattedMessage } from 'react-intl';
import { Auth } from 'aws-amplify';
import LoginButton from './LoginButton';
import Colors from '../../globals/Colors';
import { mobile } from '../../globals/Devices';
import { AuthContext } from '../../services/auth.service';
import darkLogo from '../../images/sumofus-logo-horizontal.svg';
import whiteLogo from '../../images/sumofus-white-logo-horizontal.svg';

type WrapperProps = {
  isPageScrolled: boolean;
};
const Wrapper = styled.header<WrapperProps>`
  width: 100%;
  position: fixed;
  top: 0;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 99;
  box-shadow: ${({ isPageScrolled }) =>
    isPageScrolled ? '0 1px 6px 0 rgba(32, 33, 36, 0.28)' : 'unset'};
  background-color: ${({ isPageScrolled }) =>
    isPageScrolled ? '#fff' : 'transparent'};
  border-bottom: ${({ isPageScrolled }) =>
    isPageScrolled ? 'rgba(23, 35, 64, 0.15) 0.3px solid' : 'none'};
  @media ${mobile} {
    padding: 10px 5px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media ${mobile} {
    width: 70%;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: baseline;
  flex-direction: column;
`;

const SOULogo = styled.img`
  min-width: 142px;
  height: 27px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
  @media ${mobile} {
    min-width: 110px;
  }
`;

interface NavOptionsInterface {
  isDarkColor: boolean;
}
const NavOptions = styled.div<NavOptionsInterface>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
    color: ${({ isDarkColor }) => (isDarkColor ? Colors.black : Colors.white)};
    &:hover {
      color: ${({ isDarkColor }) =>
        isDarkColor ? Colors.redOrange : Colors.robinsEggBlue};
    }
  }
  @media ${mobile} {
    display: none;
  }
`;

interface NavIconColor {
  navIconColor: string;
  isOpen: boolean;
}
const NavIcon = styled.div<NavIconColor>`
  display: none;
  width: 24px;
  margin: -25px 20px 0 10px;
  position: relative;
  z-index: 999;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  span {
    display: block;
    position: absolute;
    height: 2.5px;
    width: 100%;
    background: ${({ navIconColor }) => navIconColor};
    border-radius: 50px;
    opacity: 1;
    left: 0px;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
    &:nth-child(1) {
      top: ${({ isOpen }) => (isOpen ? '8px' : 0)};
      transform: ${({ isOpen }) => (isOpen ? 'rotate(135deg)' : 0)};
      width: ${({ isOpen }) => (isOpen ? '20px' : '25px')};
    }
    &:nth-child(2) {
      top: ${({ isOpen }) => (isOpen ? 0 : '8px')};
      opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
      left: ${({ isOpen }) => (isOpen ? '-60px' : 0)};
      width: ${({ isOpen }) => (isOpen ? '20px' : '20px')};
    }
    &:nth-child(3) {
      top: ${({ isOpen }) => (isOpen ? '8px' : '16px')};
      transform: ${({ isOpen }) => (isOpen ? 'rotate(-135deg)' : 0)};
      width: ${({ isOpen }) => (isOpen ? '20px' : '15px')};
    }
  }
  @media ${mobile} {
    display: block;
  }
`;
interface NavItemsProps {
  isOpen: boolean;
}
const NavItems = styled.div<NavItemsProps>`
  position: absolute;
  top: 80px;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: ${({ isOpen }) => (isOpen ? 'auto' : '0')};
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  background: ${Colors.white};
  border-bottom: solid 0.4px ${Colors.lightGray};
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  transform: ${({ isOpen }) =>
    isOpen ? 'translateY(0px)' : 'translateY(-30px)'};
  transition: transform 0.5s ease-in-out;
  div {
    color: ${Colors.blueWhale};
    font-weight: 400;
    padding: 0 10px;
    font-size: 18px;
    padding: 10px;
    &::hover {
      color: ${Colors.redOrange};
    }
  }
`;

const MenuItem = styled.div`
  font-size: 14px;
  margin: 0 10px;
  font-weight: 600;
  text-decoration: none;
  letter-spacing: 0.4px;
  cursor: pointer;
  text-transform: uppercase;
`;

interface TitleStyleProps {
  isDark: boolean;
}
const Title = styled.div<TitleStyleProps>`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: ${({ isDark }) => (isDark ? Colors.redOrange : Colors.robinsEggBlue)};
`;

export default function Header() {
  const [scrolled, setScrolled] = useState<boolean>(false);
  const [isDarkLogo, setIsDarkLogo] = useState<boolean>(
    window.location.pathname !== '/'
  );
  const [navBarOpen, setNavBarOpen] = useState<boolean>(false);

  const authContext: any = useContext(AuthContext);
  let isAdmin = false;
  if (authContext) {
    if (authContext.signInUserSession.accessToken.payload['cognito:groups']) {
      isAdmin = authContext.signInUserSession.accessToken.payload[
        'cognito:groups'
      ].includes('Admin');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.location.pathname.includes('/dashboard')) {
        setScrolled(true);
      } else {
        setScrolled(window.scrollY > 50);
      }
    });
    if (window.location.pathname.includes('/dashboard')) setScrolled(true);
    globalHistory.listen(() => {
      if (window.location.pathname.includes('/dashboard')) setScrolled(true);
      setIsDarkLogo(window.location.pathname !== '/');
    });
  }, []);

  useEffect(() => {
    if (window.location.pathname === '/') {
      setIsDarkLogo(scrolled);
    }
  }, [scrolled]);

  return (
    <>
      <Wrapper isPageScrolled={scrolled}>
        <MenuWrapper>
          <NavIcon
            isOpen={navBarOpen}
            onClick={() => {
              setNavBarOpen(!navBarOpen);
            }}
            navIconColor={isDarkLogo ? Colors.black : Colors.white}
          >
            <span />
            <span />
            <span />
          </NavIcon>
          <NavItems isOpen={navBarOpen}>
            <div
              onClick={() => {
                setNavBarOpen(false);
                navigate('/u/new');
              }}
            >
              <FormattedMessage
                id="label.petitionStarterButton"
                defaultMessage="Start a petition"
              />
            </div>
            <div
              onClick={() => {
                setNavBarOpen(false);
                if (authContext) {
                  navigate('/my-petitions/');
                } else {
                  localStorage.setItem('previousPath', '/my-petitions/');
                  navigate('/login');
                }
              }}
            >
              <FormattedMessage
                id="label.myPetitions"
                defaultMessage="My petitions"
              />
            </div>
            {authContext !== null ? (
              <div
                onClick={() => {
                  sessionStorage.removeItem('isAdmin');
                  setNavBarOpen(false);
                  Auth.signOut();
                }}
              >
                <FormattedMessage
                  id="label.signOut"
                  defaultMessage="Sign out"
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  setNavBarOpen(false);
                  navigate('/login');
                }}
              >
                <FormattedMessage id="label.login" defaultMessage="Login" />
              </div>
            )}
          </NavItems>
          <LogoWrapper>
            <SOULogo
              src={isDarkLogo ? darkLogo : whiteLogo}
              onClick={() => navigate('/')}
            />
            <Title isDark={isDarkLogo}>Community Petitions</Title>
          </LogoWrapper>
        </MenuWrapper>
        <NavOptions isDarkColor={isDarkLogo}>
          <MenuItem onClick={() => navigate('/u/new/')}>
            <FormattedMessage
              id="label.startPetition"
              defaultMessage="Start a petition"
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              if (authContext) {
                navigate('/my-petitions/');
              } else {
                localStorage.setItem('previousPath', '/my-petitions/');
                navigate('/login');
              }
            }}
          >
            <FormattedMessage
              id="label.myPetitions"
              defaultMessage="My petitions"
            />
          </MenuItem>
          {isAdmin && (
            <MenuItem onClick={() => navigate('/dashboard/')}>
              <FormattedMessage id="label.admin" defaultMessage="Admin Panel" />
            </MenuItem>
          )}
        </NavOptions>
        <LoginButton isDarkLogo={isDarkLogo} />
      </Wrapper>
    </>
  );
}
