// tslint:disable
// this is an auto generated file. This will be overwritten

export const confirmConsent = /* GraphQL */ `
  mutation ConfirmConsent($input: ConfirmConsentInput!) {
    confirmConsent(input: $input) {
      petitionId
      email
      name
      country
      phoneNumber
      postalCode
      consent
      doubleOptIn
      queryParams
      createdAt
    }
  }
`;
export const publishPetition = /* GraphQL */ `
  mutation PublishPetition($input: PublishPetitionInput!) {
    publishPetition(input: $input) {
      id
      title
      target
      moderationStatus
      body
      createdAt
      owner
      imageUrl
      user {
        name
        email
      }
      publishedAt
      actionCounter
      queryParams
      flags {
        items {
          comment
          reason
          petitionId
          createdAt
          dateGrouping
        }
        nextToken
      }
      lang
    }
  }
`;
export const updatePetitionFlag = /* GraphQL */ `
  mutation UpdatePetitionFlag($input: UpdatePetitionFlagInput!) {
    updatePetitionFlag(input: $input) {
      comment
      reason
      petitionId
      createdAt
      dateGrouping
      petition {
        id
        title
        target
        moderationStatus
        body
        createdAt
        owner
        imageUrl
        user {
          name
          email
        }
        publishedAt
        actionCounter
        queryParams
        flags {
          nextToken
        }
        lang
      }
    }
  }
`;
export const deletePetitionFlag = /* GraphQL */ `
  mutation DeletePetitionFlag($input: DeletePetitionFlagInput!) {
    deletePetitionFlag(input: $input) {
      comment
      reason
      petitionId
      createdAt
      dateGrouping
      petition {
        id
        title
        target
        moderationStatus
        body
        createdAt
        owner
        imageUrl
        user {
          name
          email
        }
        publishedAt
        actionCounter
        queryParams
        flags {
          nextToken
        }
        lang
      }
    }
  }
`;
export const createPetitionDownload = /* GraphQL */ `
  mutation CreatePetitionDownload($input: CreatePetitionDownloadInput!) {
    createPetitionDownload(input: $input) {
      petitionId
      createdAt
      completedAt
      email
      key
    }
  }
`;
export const updatePetitionDownload = /* GraphQL */ `
  mutation UpdatePetitionDownload($input: UpdatePetitionDownloadInput!) {
    updatePetitionDownload(input: $input) {
      petitionId
      createdAt
      completedAt
      email
      key
    }
  }
`;
export const deletePetitionDownload = /* GraphQL */ `
  mutation DeletePetitionDownload($input: DeletePetitionDownloadInput!) {
    deletePetitionDownload(input: $input) {
      petitionId
      createdAt
      completedAt
      email
      key
    }
  }
`;
export const createPetition = /* GraphQL */ `
  mutation CreatePetition($input: CreatePetitionInput!) {
    createPetition(input: $input) {
      id
      title
      target
      moderationStatus
      body
      createdAt
      owner
      imageUrl
      user {
        name
        email
      }
      publishedAt
      actionCounter
      queryParams
      flags {
        items {
          comment
          reason
          petitionId
          createdAt
          dateGrouping
        }
        nextToken
      }
      lang
    }
  }
`;
export const updatePetition = /* GraphQL */ `
  mutation UpdatePetition($input: UpdatePetitionInput!) {
    updatePetition(input: $input) {
      id
      title
      target
      moderationStatus
      body
      createdAt
      owner
      imageUrl
      user {
        name
        email
      }
      publishedAt
      actionCounter
      queryParams
      flags {
        items {
          comment
          reason
          petitionId
          createdAt
          dateGrouping
        }
        nextToken
      }
      lang
    }
  }
`;
export const deletePetition = /* GraphQL */ `
  mutation DeletePetition($input: DeletePetitionInput!) {
    deletePetition(input: $input) {
      id
      title
      target
      moderationStatus
      body
      createdAt
      owner
      imageUrl
      user {
        name
        email
      }
      publishedAt
      actionCounter
      queryParams
      flags {
        items {
          comment
          reason
          petitionId
          createdAt
          dateGrouping
        }
        nextToken
      }
      lang
    }
  }
`;
export const createPetitionFlag = /* GraphQL */ `
  mutation CreatePetitionFlag($input: CreatePetitionFlagInput!) {
    createPetitionFlag(input: $input) {
      comment
      reason
      petitionId
      createdAt
      dateGrouping
      petition {
        id
        title
        target
        moderationStatus
        body
        createdAt
        imageUrl
        actionCounter
        queryParams
        lang
      }
    }
  }
`;
export const createAction = /* GraphQL */ `
  mutation CreateAction($input: CreateActionInput!) {
    createAction(input: $input) {
      petitionId
      email
      name
      country
      phoneNumber
      postalCode
      consent
      doubleOptIn
      queryParams
      createdAt
      token
    }
  }
`;
export const closePetition = /* GraphQL */ `
  mutation ClosePetition($id: ID!) {
    closePetition(id: $id) {
      moderationStatus
    }
  }
`;
