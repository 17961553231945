/* eslint-disable import/prefer-default-export */

// GraphQL API wrapper with defined TS generics.
// See `src/API.ts` for various GraphQL types.
// Function forces us to define types when executing a GraphQL query/mutation.

import { API } from 'aws-amplify';
import { GRAPHQL_AUTH_MODE } from '../../node_modules/@aws-amplify/api/lib-esm/types';

export const gqlOp = async <
  ResultType extends {},
  VariablesType extends {} = {}
>(
  query: string,
  authMode: 'AMAZON_COGNITO_USER_POOLS' | 'API_KEY',
  variables?: VariablesType
) => {
  console.log('running');
  const { data } = (await API.graphql({
    query,
    authMode: GRAPHQL_AUTH_MODE[authMode],
    variables: variables || {},
  })) as {
    data: ResultType;
  };
  return data;
};
