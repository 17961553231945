import styled from 'styled-components';
import Colors from '../../globals/Colors';
import Button from '../Button';

export const Wrapper = styled.div`
  margin: 30px 20px;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Heading = styled.h2``;

export const SearchInput = styled.input`
  display: none;
  width: 400px;
  padding: 15px 20px;
  margin: 10px;
  border: 1px solid ${Colors.lightGray};
  border-radius: 30px;
  transition: width 0.5s ease-in-out;
  &:focus {
    outline: none;
    width: calc(100% - 40px);
  }
`;

export const CardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 80vh;
  .new {
    position: relative;
    overflow: hidden;
    &:after {
      content: '✭';
      padding-left: 32px;
      padding-top: 50px;
      color: ${Colors.goldenYellow};
      position: absolute;
      white-space: pre;
      margin: -20px;
      width: 80px;
      height: 80px;
      top: -20px;
      right: -20px;
      transform: rotate(45deg);
      background-color: ${Colors.redOrange};
    }
  }
`;
export const SortWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  div {
    padding: 5px 10px;
    border: 0.5px solid black;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 10px;
  }
  .active {
    font-weight: 500;
    box-shadow: 2px 2px 8px 0 rgba(0, 0, 0, 0.15);
    background: ${Colors.redOrange};
    color: ${Colors.white};
    border-color: ${Colors.redOrange};
  }
`;

export const Card = styled.div`
  padding: 10px;
  height: 500px;
  width: 350px;
  margin: 20px;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: ${Colors.seaFogOpacited3};
  color: ${Colors.black};
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
export const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 20px;
  position: relative;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 20px;
  .name {
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: ${Colors.blueWhale};
    &:hover {
      color: ${Colors.robinsEggBlue};
    }
  }
  .time {
    font-size: 12px;
  }
`;

export const Avatar = styled.div`
  height: 40px;
  min-width: 40px;
  background: ${Colors.robinsEggBlue};
  box-shadow: 0px 0px 1px 3px ${Colors.suvaGrey2};
  color: ${Colors.white};
  border-radius: 50%;
  margin-right: 10px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
`;

export const Flag = styled.div`
  display: flex;
  margin-right: 10px;
`;
export const Status = styled.div`
  font-size: 12px;
  padding-right: 5px;
`;
export const Icon = styled.div`
  cursor: pointer;
  font-size: 20px;
  .fa {
    color: ${Colors.redOrange};
  }
`;

export const ImageFromS3 = styled.img`
  display: block;
  border-radius: 4px;
  height: 220px;
  width: 100%;
  border: 0.3px solid ${Colors.lightGrey};
  border-radius: 4px;
  background-color: ${Colors.lightGrey};
`;

export const Title = styled.a`
  font-weight: bold;
  cursor: pointer;
  color: ${Colors.regalBlue};
  font-weight: 600;
  font-size: 16px;
  max-height: 58px;
  min-height: 58px;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  &:hover {
    color: ${Colors.robinsEggBlue};
  }
`;

export const Supporters = styled.div`
  font-size: 14px;
`;

export const ActionButton = styled(Button)`
  height: 50px;
  width: 100%;
  max-width: 100%;
  margin: 5px 0;
`;
