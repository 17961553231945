import React, { useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import Colors from '../../globals/Colors';
import PetitionImage from './PetitionImage';
import { PetitionContext } from '../../services/petition.service';
import Input from '../Input';
import { mobile, tablet } from '../../globals/Devices';

type TitleProps = {
  petitionTitle: string;
  editMode: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 70px 0 20px 0;
  div {
    position: unset !important;
  }
`;

const Heading = styled.h3`
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0.25px;
  color: ${Colors.blueWhale};
  text-align: center;
  @media ${mobile} {
    font-size: 26px;
    margin-top: calc(35vh + 20px);
  }
  @media ${tablet} {
    text-align: left;
    margin-top: calc(40vh + 20px);
    margin-bottom: 0;
    font-weight: 500;
  }
`;

const Title = (props: TitleProps) => {
  const { editMode } = props;
  const petitionContextFromLocal: any = useContext(PetitionContext);
  const { petitionDetails } = petitionContextFromLocal;
  const [title, setTitle] = useState(petitionDetails.title);

  const { formatMessage: fm } = useIntl();

  useEffect(() => {
    if (title.length) {
      localStorage.setItem('editedTitle', title);
    } else {
      localStorage.setItem('editedTitle', petitionDetails.title);
    }
  }, [title]);

  return (
    <>
      <Wrapper>
        {!editMode && (
          <PetitionImage imageUrl={petitionDetails.imageUrl} isMobile />
        )}
        {editMode ? (
          <Input
            id="title"
            name="title"
            type="text"
            placeholder={fm({
              id: 'label.titlePlaceholder',
              defaultMessage: 'What do you want to achieve?',
            })}
            maxLength={140}
            onChange={(event) => setTitle(event.currentTarget.value)}
            value={title}
          />
        ) : (
          <Heading>{petitionDetails.title}</Heading>
        )}
      </Wrapper>
    </>
  );
};

export default Title;
