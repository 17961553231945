import React from 'react';
import styled, { keyframes } from 'styled-components';
import Colors from '../../globals/Colors';
import { mobile, tablet, mobileSmall } from '../../globals/Devices';

interface PetitionImageProps {
  imageUrl: string;
  isMobile: boolean;
}

const loading = keyframes`
  0% { background: ${Colors.lightGrey} }
  25% { background: ${Colors.darkGray} }
  50% { background: ${Colors.lightGrey} }
  75% { background: ${Colors.darkGray} }
  100% { background: ${Colors.lightGrey} }
`;
const ImageWrapper = styled.div`
  max-width: 570px;
  max-height: 350px;
  width: auto;
  background-color: ${Colors.lightGrey};
  border-radius: 8px;
  height: 300px;
  margin-bottom: 50px;
  animation-name: ${loading};
  animation-duration: 3s;
  animation-iteration-count: 15;
  @media ${tablet} {
    display: none;
    max-width: 720px;
    max-height: 440px;
    height: 400px;
  }
  @media ${mobile} {
    max-width: calc(100% - 20px);
    max-height: 240px;
    height: 200px;
  }
  @media ${mobileSmall} {
    max-width: calc(100% - 20px);
    max-height: 240px;
    height: 150px;
  }

  img {
    margin-bottom: 0;
  }
`;

const Image = styled.img`
  display: block;
  max-width: 570px;
  max-height: 350px;
  width: auto;
  height: auto;
  border: 0.3px solid ${Colors.lightGrey};
  border-radius: 8px;
  background-color: ${Colors.lightGrey};
  @media ${tablet} {
    display: none;
    max-width: 720px;
    max-height: 440px;
  }
  @media ${mobile} {
    max-width: 100%;
    max-height: 100%;
  }
`;

const loadingMobile = keyframes`
  0% { background: ${Colors.lightGrey} }
  25% { background: ${Colors.darkGray} }
  50% { background: ${Colors.lightGrey} }
  75% { background: ${Colors.darkGray} }
  100% { background: ${Colors.lightGrey} }
`;
const ImageMobile = styled.img`
  border: 0.3px solid ${Colors.lightGrey};
  background: ${Colors.lightGrey};
  position: absolute;
  left: 0;
  display: flex;
  width: 100vw;
  height: 35vh;
  display: none;
  animation-name: ${loadingMobile};
  animation-duration: 3s;
  animation-iteration-count: 15;
  top: 90px;
  border-left: 0;
  border-right: 0;
  @media ${tablet} {
    display: block;
    height: 40vh;
  }
`;

const PetitionImage = (props: PetitionImageProps) => {
  const { imageUrl, isMobile } = props;

  function buildImageUrl(key: string) {
    return key
      .split('?')[0]
      .replace(/\/public\//, '/public/thumbnails/thumbnail-');
  }

  return (
    <>
      {isMobile ? (
        <ImageMobile src={buildImageUrl(imageUrl)} />
      ) : (
        <ImageWrapper>
          <Image src={buildImageUrl(imageUrl)} />
        </ImageWrapper>
      )}
    </>
  );
};

export default PetitionImage;
