import * as React from 'react';
import styled from 'styled-components';

export interface Props {
  width: number;
}

const Progress = styled.div`
  width: ${({ width }: Props) => (width > 100 ? `100%` : `${width}%`)};
  background: linear-gradient(
    to right,
    rgba(4, 204, 222, 0.3),
    rgba(4, 204, 222, 1)
  );
  height: 15px;
  border-radius: 10px;
  border-bottom-right-radius: ${({ width }: Props) =>
    width < 99 ? `0` : `10px`};
  border-top-right-radius: ${({ width }: Props) =>
    width < 99 ? `0%` : `10px`};
  transition: 800ms linear;
  transition-property: width;
`;

const ProgressBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 15px;
  border: 0.3px solid #efefef;
  border-radius: 10px;
  background-color: #efefef;
`;

const Arrow = styled.div`
  top: -6px;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid rgb(4, 204, 222);
`;

const Thermometer = (props: Props) => {
  const { width } = props;
  const [thermometerValue, setThermometerValue] = React.useState(0);

  React.useEffect(() => {
    setTimeout(() => {
      setThermometerValue(width);
    }, 200);
  }, [width]);

  return (
    <ProgressBar>
      <Progress width={thermometerValue} />
      {thermometerValue > 15 && thermometerValue < 99 && <Arrow />}
    </ProgressBar>
  );
};

export default Thermometer;
