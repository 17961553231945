export const getPublishedPetition = /* GraphQL */ `
  query GetPublishedPetition($id: ID!) {
    getPetition(id: $id) {
      id
      title
      target
      body
      imageUrl
      moderationStatus
      user {
        name
      }
      actionCounter
    }
  }
`;
