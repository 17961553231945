/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { FormattedMessage, useIntl } from 'react-intl';
import Colors from '../../globals/Colors';
import Button from '../Button';
import { ReportCategories } from '../Constants';
import { gqlOp } from '../../services/graphql';
import {
  CreatePetitionFlagMutation,
  CreatePetitionFlagMutationVariables,
} from '../../API';
import { createPetitionFlag } from '../../graphql/mutations';

interface ReportPetitionProps {
  petitionId: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: baseline;
  margin: 20px 0;
  border: 0.3px solid ${Colors.lightGray};
  border-radius: 6px;
`;

interface HeaderWrapperProps {
  showBox: boolean;
}
const HeaderWrapper = styled.div<HeaderWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;
  width: 100%;
  cursor: pointer;
  background: ${Colors.seaFogOpacited3};
  &::after {
    font-family: FontAwesome;
    content: '\f106';
    content: ${({ showBox }) => (showBox ? `'\f106'` : `'\f107'`)};
    display: inline-block;
    padding-left: 5px;
    vertical-align: middle;
    font-weight: 900;
    float: right;
  }
`;

const Title = styled.div`
  font-size: 16px;
  font-weight: 600;
  /* text-decoration: underline; */
`;

interface ContentWrapperProps {
  showBox: boolean;
}
const ContentWrapper = styled.div<ContentWrapperProps>`
  width: 100%;
  margin: ${({ showBox }) => (showBox ? '10px 0' : 0)};
  padding: ${({ showBox }) => (showBox ? '10px 20px' : 0)};
  max-height: ${({ showBox }) => (showBox ? '950px' : 0)};
  overflow: hidden;
  transition: max-height 500ms ease-in-out;
`;

const ContentTitle = styled.div`
  font-weight: 600;
  text-align: center;
  margin: 5px 0 20px;
`;

const ContentDesc1 = styled.div`
  font-size: 16px;
  color: ${Colors.regalBlue};
  a {
    color: ${Colors.regalBlue};
    &:hover {
      color: ${Colors.redOrange};
    }
  }
`;

const RadioWrapper = styled.div`
  padding: 20px 0;
`;

interface RadioOptionWrapperProps {
  selected: boolean;
}
const RadioOptionWrapper = styled.div<RadioOptionWrapperProps>`
  margin: 5px;
  cursor: pointer;
  width: 100%;
  position: relative;
  label {
    font-size: 14px;
  }
  span {
    padding-left: 30px;
  }
  &::before {
    content: '';
    border-radius: 100%;
    border: 1px solid ${(props) => (props.selected ? '#00c0cf' : '#d7d7d7')};
    background: ${(props) => (props.selected ? '#00c0cf' : '#d7d7d7')};
    width: 20px;
    height: 20px;
    position: absolute;
    top: 5px;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
    transition: background 0.2s;
  }
`;

const RadioOption = styled.input`
  margin: 0 10px;
  width: 20px;
  height: 20px;
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }

  &:checked {
    &::before {
      opacity: 1;
      transition: opacity 1s ease;
    }
  }
`;

const StatusDesc = styled.textarea`
  resize: none;
  outline: 0;
  padding: 10px;
  margin: 10px 0;
  border: 0.5px solid ${Colors.darkGray};
  border-radius: 6px;
  width: 100%;
  font-size: 14px;
  &::placeholder {
    font-size: 14px;
    font-style: italic;
  }
`;

const SuccessMsg = styled.div`
  font-size: 18px;
  font-weight: bold;
  padding: 5px 0px;
  color: ${Colors.green};
`;

const ErrorMsg = styled(SuccessMsg)`
  font-size: 16px;
  color: ${Colors.red};
`;

const ContentMsg = styled.div`
  font-size: 14px;
  text-align: center;
`;

const ReportPetition = (props: ReportPetitionProps) => {
  const { petitionId } = props;
  const [openReportBox, setOpenReportBox] = useState<boolean>(false);
  const [helpers, setHelpers] = useState<{
    loading: boolean;
    success: boolean;
    error: boolean;
  }>({
    loading: false,
    success: false,
    error: false,
  });
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [reportReason, setReportReason] = useState<string>('');

  const { formatMessage: fm } = useIntl();

  async function saveReportData(data: any) {
    const resp = await gqlOp<
      CreatePetitionFlagMutation,
      CreatePetitionFlagMutationVariables
    >(createPetitionFlag, 'API_KEY', {
      input: data,
    });

    return resp;
  }

  function reportPetition() {
    setHelpers({ ...helpers, loading: true });

    const values = {
      petitionId,
      reason: selectedOption,
      comment: reportReason,
    };
    saveReportData(values)
      .then((r: any) => {
        ReactGA.event({
          category: 'Petition Creation',
          action: 'Submitted',
          label: selectedOption,
        });
        setHelpers({ ...helpers, loading: false, success: true });
        setSelectedOption('');
        setReportReason('');
        setTimeout(() => {
          setHelpers({ ...helpers, success: false });
        }, 1000 * 25);
        console.log(r);
      })
      .catch((error: any) => {
        setHelpers({ ...helpers, loading: false, error: true });
        setTimeout(() => {
          setHelpers({ ...helpers, error: false });
        }, 1000 * 25);
      });
  }

  return (
    <Wrapper>
      <HeaderWrapper
        showBox={openReportBox}
        onClick={() => {
          setOpenReportBox(!openReportBox);
          setSelectedOption('');
          setReportReason('');
          setHelpers({
            loading: false,
            success: false,
            error: false,
          });
        }}
      >
        <Title>
          <FormattedMessage
            id="label.reportOptionHeading"
            defaultMessage="Report a policy violation"
          />
        </Title>
      </HeaderWrapper>

      <ContentWrapper showBox={openReportBox} id="report-content">
        {openReportBox && (
          <>
            <ContentTitle>
              <FormattedMessage
                id="label.reportHeading"
                defaultMessage="Report abuse"
              />
            </ContentTitle>
            <ContentDesc1>
              <span>
                <FormattedMessage
                  id="label.reportContent1"
                  defaultMessage="Please report any content that may violate sumofus.org’s "
                />
              </span>
              <a
                href="https://www.sumofus.org/privacy/#user-contributions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FormattedMessage
                  id="label.reportContent2"
                  defaultMessage="Terms of Service or Community Guidelines"
                />
              </a>
            </ContentDesc1>
            <RadioWrapper>
              {ReportCategories.map((radioOption) => {
                return (
                  <RadioOptionWrapper
                    selected={selectedOption === radioOption.key}
                    key={Math.random() * 10}
                  >
                    <label>
                      <RadioOption
                        type="radio"
                        id="yes"
                        name="reportOptionType"
                        value={radioOption.key}
                        checked={selectedOption === radioOption.key}
                        aria-checked={selectedOption === radioOption.key}
                        onChange={() => {
                          setSelectedOption(radioOption.key);
                        }}
                      />
                      <span>
                        {fm({
                          id: radioOption.intlId,
                          defaultMessage: radioOption.value,
                        })}
                      </span>
                    </label>
                  </RadioOptionWrapper>
                );
              })}
            </RadioWrapper>
            <StatusDesc
              onChange={(e: any) => setReportReason(e.target.value)}
              placeholder={fm({
                id: 'label.reportContent3',
                defaultMessage:
                  'Please explain why you are reporting this petition and include any information that might assist us in reviewing this content.',
              })}
              name="reason"
              value={reportReason}
              wrap=""
              rows={5}
            />
            <Button
              disabled={
                selectedOption === '' || reportReason === '' || helpers.loading
              }
              onClick={() => {
                reportPetition();
              }}
            >
              {helpers.loading ? (
                <FormattedMessage
                  id="label.submitting"
                  defaultMessage="Submitting..."
                />
              ) : (
                <FormattedMessage id="label.submit" defaultMessage="Submit" />
              )}
            </Button>
            {!helpers.loading && helpers.success && (
              <SuccessMsg>
                <FormattedMessage
                  id="label.reportSuccess"
                  defaultMessage="Reported Successfully!!"
                />
              </SuccessMsg>
            )}
            {!helpers.loading && helpers.error && (
              <ErrorMsg>
                <FormattedMessage
                  id="label.reportFailure"
                  defaultMessage="Failed to Report, please try again later"
                />
              </ErrorMsg>
            )}
            <ContentMsg>
              <FormattedMessage
                id="label.reportContent4"
                defaultMessage="Flagged petitions are reviewed by sumofus.org staff to determine if they violate our Terms of Service or Community Guidelines."
              />
            </ContentMsg>
          </>
        )}
      </ContentWrapper>
    </Wrapper>
  );
};

export default ReportPetition;
