import * as React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import { graphqlOperation, API } from 'aws-amplify';
import ReactGA from 'react-ga';
import { FormattedMessage, useIntl } from 'react-intl';
import Pulse from 'react-reveal/Pulse';
import Button from '../Button';
import Colors from '../../globals/Colors';
import { PetitionContext } from '../../services/petition.service';
import { AuthContext } from '../../services/auth.service';
import { updatePetition, publishPetition } from '../../graphql/mutations';
import ChangePhotoModal from './ChangePhotoModal';
import ConfirmPublishModal from './ConfirmPublishModal';
import { mobile } from '../../globals/Devices';
import Banner from '../Banner';

interface ActionButtonProps {
  editMode: boolean;
  switchEditMode: any;
}

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 10px 0;
  @media ${mobile} {
    flex-wrap: wrap;
  }
`;

const EditButton = styled(Button)`
  background-color: ${Colors.white};
  color: ${Colors.redOrange};
  margin: 0 20px;
  width: 100px;
  height: 40px;
  @media ${mobile} {
    margin: 10px;
  }
`;

const PublishButton = styled(Button)`
  margin: 0;
  width: 150px;
  height: 40px;

  @media ${mobile} {
    margin: 10px;
  }
`;

const PhotoButton = styled(EditButton)`
  width: 180px;
  margin: 0;
  @media ${mobile} {
    font-size: 14px;
    margin: 10px;
  }
`;

const PreviewActionButtons = (props: ActionButtonProps) => {
  const { editMode, switchEditMode } = props;
  const petitionDetailsFromContext: any = React.useContext(PetitionContext);
  const { petitionDetails } = petitionDetailsFromContext;
  const authContext = React.useContext(AuthContext);
  const [openModal, setModalOpen] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [saveError, setSaveError] = React.useState<boolean>(false);
  const [confirmModal, setConfirmModal] = React.useState<{
    open: boolean;
    consent: boolean;
  }>({ open: false, consent: false });

  const { formatMessage: fm } = useIntl();

  function openEditMode() {
    localStorage.setItem('editedTitle', petitionDetails.title);
    localStorage.setItem('editedTarget', petitionDetails.target);
    localStorage.setItem('editedDesc', petitionDetails.body);
    localStorage.setItem('editedImageUrl', petitionDetails.imageUrl);
    localStorage.setItem('petitionId', petitionDetails.id);
    switchEditMode(true);
  }

  async function updatePetitionData(values: any) {
    // eslint-disable-next-line no-return-await
    return await API.graphql(
      graphqlOperation(updatePetition, {
        input: values,
      })
    );
  }

  function resetLocalVariables() {
    localStorage.removeItem('petitionId');
    localStorage.removeItem('editedTitle');
    localStorage.removeItem('editedTarget');
    localStorage.removeItem('editedDesc');
    localStorage.removeItem('editedImageUrl');
  }

  function savePetition() {
    const values = {
      id: localStorage.getItem('petitionId'),
      title: localStorage.getItem('editedTitle'),
      target: localStorage.getItem('editedTarget'),
      body: localStorage.getItem('editedDesc'),
      imageUrl: localStorage.getItem('editedImageUrl'),
    };
    setLoading(true);
    updatePetitionData({ ...values })
      .then((petition: any) => {
        localStorage.setItem(
          'petitionDetails',
          JSON.stringify({
            ...petition.data.updatePetition,
          })
        );
        ReactGA.event({
          category: 'Petition Creation',
          action: `Petition edited`,
        });
        resetLocalVariables();
        navigate(0);
      })
      .catch((error: any) => {
        setSaveError(true);
        ReactGA.event({
          category: 'Error',
          action: 'Error displayed',
          label: `Update petition error`,
        });
        switchEditMode(false);
        setLoading(false);
      });
  }

  async function publishPetitionFunc(values: any) {
    // eslint-disable-next-line no-return-await
    return await API.graphql(
      graphqlOperation(publishPetition, {
        input: values,
      })
    );
  }

  React.useEffect(() => {
    if (confirmModal.consent) {
      setLoading(true);
      publishPetitionFunc({
        id: petitionDetails.id,
        lang: petitionDetails.lang || 'en',
      })
        .then((petition: any) => {
          ReactGA.event({
            category: 'Petition Creation',
            action: `Petition published`,
          });
          sessionStorage.setItem('isPetitionSigned', 'false');
          navigate(`/petition/${petitionDetails.id}/share`);
          console.log(petition);
        })
        .catch((error: any) => {
          setSaveError(true);
          ReactGA.event({
            category: 'Error',
            action: 'Error displayed',
            label: `Publish petition error`,
          });
          console.log('error', error);
          setLoading(false);
        });
    }
  }, [confirmModal]);

  return (
    <>
      {authContext !== undefined && authContext !== null && (
        <>
          <ButtonWrapper>
            {editMode ? (
              <>
                <PhotoButton
                  disabled={loading}
                  onClick={() => setModalOpen(true)}
                >
                  <FormattedMessage
                    id="label.changePhoto"
                    defaultMessage="Change Photo"
                  />
                </PhotoButton>
                <EditButton
                  disabled={loading}
                  onClick={() => {
                    resetLocalVariables();
                    switchEditMode(false);
                  }}
                >
                  <FormattedMessage id="label.cancel" defaultMessage="Cancel" />
                </EditButton>
                <PublishButton
                  disabled={loading}
                  onClick={() => savePetition()}
                >
                  {loading
                    ? fm({
                        id: 'label.saving',
                        defaultMessage: 'Saving...',
                      })
                    : fm({
                        id: 'label.save',
                        defaultMessage: 'Save',
                      })}
                </PublishButton>
              </>
            ) : (
              <>
                {petitionDetails.publishedAt === null && (
                  <>
                    <Pulse>
                      <EditButton onClick={() => openEditMode()}>
                        <FormattedMessage
                          id="label.edit"
                          defaultMessage="Edit"
                        />
                      </EditButton>
                    </Pulse>
                    <Pulse>
                      <PublishButton
                        onClick={() => {
                          setConfirmModal({ open: true, consent: false });
                        }}
                      >
                        <FormattedMessage
                          id="label.publish"
                          defaultMessage="Publish"
                        />
                      </PublishButton>
                      <ConfirmPublishModal
                        open={confirmModal}
                        setOpen={(openFlag: boolean, consentFlag: boolean) => {
                          setConfirmModal({
                            open: openFlag,
                            consent: consentFlag,
                          });
                        }}
                      />
                    </Pulse>
                  </>
                )}
              </>
            )}
          </ButtonWrapper>
          {!loading && saveError && (
            <Banner
              msg={fm({
                id: 'label.updateFailure',
                defaultMessage:
                  'Sorry! We were unable to update your petition. Please try again later.',
              })}
              setShowBanner={setSaveError}
              type="error"
            />
          )}
          <ChangePhotoModal
            openModal={openModal}
            setModalOpen={(flag: boolean) => setModalOpen(flag)}
            setFileProps={() => savePetition()}
          />
        </>
      )}
    </>
  );
};

export default PreviewActionButtons;
