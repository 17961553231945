import React from 'react';
import styled from 'styled-components';
import './layout.css';
import Header from './Header/index';
import Footer from './Footer';

const Main = styled.div`
  margin: 0 auto;
  max-width: 1020px;
  padding: 0 1.0875rem;
`;

const Layout = ({ children }: any) => {
  return (
    <>
      <Header />
      <Main id="parent">
        <main>{children}</main>
      </Main>
      <Footer />
    </>
  );
};

export default Layout;
