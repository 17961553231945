import React, { useState, useContext, useEffect } from 'react';
import captcha from '../../recaptcha';
// import ReCaptchaBranding from '../ReCaptchaBranding';
import styled from 'styled-components';
import ReactPixel from 'react-facebook-pixel';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import queryString from 'querystring';
import { FormattedMessage, IntlProvider, createIntl } from 'react-intl';
import { API } from 'aws-amplify';
import { navigate } from '@reach/router';
import ReactGA from 'react-ga';
import Select from '../Select';
import Input from '../Input';
import {
  doubleOptInCountries,
  countriesList,
  getCountryValue,
  isEnableGDPR,
} from './countries';
import Button from '../Button';
import Consent from '../Consent/Consent';
import { createAction } from '../../graphql/mutations';
import Colors from '../../globals/Colors';
import Banner from '../Banner';
import KnownUser from './KnownUser';
import { I18n } from 'globals/Locale';
import { LangContext } from 'services/lang.service';

interface FormValues {
  email: string;
  name: string;
  country: { value: string; label: string };
  postalCode?: string;
  phone?: string;
  consent: string;
  doubleOptIn: string;
  petitionId: string;
}



const ValidationSchema = Yup.object().shape({
  email: Yup.string().required('msg.emailRequired').email('msg.emailNotValid'),
  name: Yup.string().required('msg.nameRequired').min(3, 'msg.nameMin3'),
  country: Yup.object().shape({
    value: Yup.string().test(
      'emptyObject',
      'label.countryProceed',
      (selectedOption) => selectedOption && selectedOption.value !== ''
    ),
    label: Yup.string(),
  }),
  postalCode: Yup.string(),
  phone: Yup.string(),
  consent: Yup.string().when('country', {
    is: (country) => isEnableGDPR(country.value),
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
});

interface PetitionActionProps {
  petitionId: string;
}

// const { REACT_APP_TEST_RECAPTCHA_SITE_KEY, REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

// const reCAPTCHASiteKey = REACT_APP_TEST_RECAPTCHA_SITE_KEY || REACT_APP_RECAPTCHA_SITE_KEY;

const ActionForm = (props: PetitionActionProps) => {
  const { petitionId } = props;
  const signedUser = localStorage.getItem('signedUser');
  const [userDetails, setUserDetails] = useState(
    signedUser ? JSON.parse(signedUser) : {}
  );


  const langContext: any = useContext(LangContext);
  const { lang } = langContext;

  const [intl, setIntl] = useState(
    createIntl({
      locale: lang,
      messages: I18n[lang],
    })
  );
  useEffect(() => {
    setIntl(
      createIntl({
        locale: lang,
        messages: I18n[lang],
      })
    );
  }, [lang]);

  function hasConsented() {
    if (doubleOptInCountries.includes(userDetails.country)) {
      return (
        userDetails.consent === 'true' && userDetails.doubleOptIn === 'true'
      );
    }
    return userDetails.consent === 'true';
  }

  const [showConsent, setShowConsent] = useState(
    isEnableGDPR(userDetails.country) && !hasConsented()
  );
  const [savingSignature, setSavingSignature] = useState<{
    saveFlag: boolean;
    error: boolean;
  }>({ saveFlag: false, error: false });

  const initialValues: FormValues = {
    // TODO: These must not be empty strings - DynamoDB doesn't allow persisting empty strings
    //  message: 'One or more parameter values were invalid: An AttributeValue may not contain an empty string',
    email: userDetails.email || '',
    name: userDetails.name || '',
    country: {
      value: getCountryValue(userDetails.country) || '',
      label: userDetails.country || '',
    },
    postalCode: userDetails.postalCode || '',
    phone: userDetails.phoneNumber || '',
    consent: hasConsented() ? 'true' : '',
    doubleOptIn: userDetails.doubleOptIn === 'true' ? 'true' : 'false',
    petitionId,
  };

  async function saveActionFormData(values: any) {
    // eslint-disable-next-line no-return-await
    return await API.graphql({
      query: createAction,
      variables: { input: values },
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      authMode: 'API_KEY',
    });
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: ValidationSchema,
    onSubmit: async (values: FormValues) => {
      const recaptcha_action = `action`;
      const input = {
        token: await captcha.execute({ action: recaptcha_action }),
        email: values.email,
        name: values.name,
        country: values.country.label,
        postalCode: values.postalCode,
        phoneNumber: values.phone,
        consent: isEnableGDPR(values.country.value) ? values.consent : 'false',
        doubleOptIn: values.doubleOptIn,
        petitionId: props.petitionId,
        lang: lang,
        queryParams: JSON.stringify(
          queryString.parse(window.location.search.slice(1))
        ),
      };
      
      setSavingSignature({ saveFlag: true, error: false });
      saveActionFormData(input)
        .then((r: any) => {
          localStorage.setItem('signedUser', JSON.stringify(input));
          ReactPixel.track('Completed Registration');
          ReactGA.event({
            category: 'Petition',
            action: 'Action submitted',
          });
          ReactGA.event({
            category: 'Petition',
            action: 'Opt-in',
            label: values.consent,
          });
          sessionStorage.setItem('isPetitionSigned', 'true');
          navigate(`/petition/${props.petitionId}/share`);
        })
        .catch((error: any) => {
          ReactGA.event({
            category: 'Error',
            action: 'Error displayed',
            label: `Save signature error`,
          });
          setSavingSignature({ saveFlag: false, error: true });
        });
    },
  });

  function onCountryChange(selectedCountry: any) {
    setShowConsent(isEnableGDPR(selectedCountry.value));
    formik.setFieldValue('country', selectedCountry);
    formik.setFieldValue('consent', '');
  }

  function handleReset() {
    formik.setFieldValue('email', '');
    formik.setFieldValue('name', '');
    formik.setFieldValue('country', { value: '', label: '' });
    formik.setFieldValue('postalCode', '');
    formik.setFieldValue('phone', '');
    formik.setFieldValue('consent', '');
    formik.setFieldValue('doubleOptIn', 'false');
    setShowConsent(false);
    setUserDetails({});
  }

  return (
    <>
      <IntlProvider locale={lang} key={lang} messages={I18n[lang]}>
        <form onSubmit={formik.handleSubmit}>
          <Wrapper>
            {Object.keys(userDetails).length > 0 && (
              <KnownUser user={userDetails} setHandleReset={handleReset} />
            )}
            {Object.keys(userDetails).length === 0 && (
              <>
                <Input
                  autoFocus={false}
                  required
                  id="email"
                  name="email"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'label.email',
                    defaultMessage: 'Email',
                  })}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  error={
                    formik.errors.email
                      ? intl.formatMessage({ id: formik.errors.email })
                      : ''
                  }
                />
                <Margin />

                <Input
                  required
                  id="name"
                  name="name"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'label.fullName',
                    defaultMessage: 'Full Name',
                  })}
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  error={
                    formik.errors.name
                      ? intl.formatMessage({ id: formik.errors.name })
                      : ''
                  }
                />
                <Margin />

                <Select
                  required
                  id="country"
                  name="country"
                  placeholder={intl.formatMessage({
                    id: 'label.country',
                    defaultMessage: 'Select your country',
                  })}
                  onChange={(selectedOption: any) => {
                    onCountryChange(selectedOption);
                  }}
                  options={countriesList}
                  value={formik.values.country}
                  error={formik.errors.country}
                />
                <Margin />
              </>
            )}
            {(Object.keys(userDetails).length === 0 ||
              userDetails?.postalCode === '') && (
              <>
                <Input
                  required={false}
                  id="postal"
                  name="postalCode"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'label.postalCode',
                    defaultMessage: 'Postal Code',
                  })}
                  onChange={formik.handleChange}
                  value={formik.values.postalCode}
                  error={
                    formik.errors.postalCode
                      ? intl.formatMessage({ id: formik.errors.postalCode })
                      : ''
                  }
                />
                <Margin />
              </>
            )}
            {(Object.keys(userDetails).length === 0 ||
              userDetails?.phoneNumber === '') && (
              <>
                <Input
                  required={false}
                  id="phone"
                  name="phone"
                  type="text"
                  placeholder={intl.formatMessage({
                    id: 'label.phoneNumber',
                    defaultMessage: 'Phone Number',
                  })}
                  onChange={formik.handleChange}
                  value={formik.values.phone}
                  error={
                    formik.errors.phone
                      ? intl.formatMessage({ id: formik.errors.phone })
                      : ''
                  }
                />
                <Margin />
              </>
            )}
            {showConsent && (
              <Consent
                name="consent"
                value={formik.values.consent}
                error={formik.errors.consent}
                onChange={(event: any) => {
                  formik.setFieldValue(
                    'consent',
                    event.currentTarget.value === '1' ? 'true' : 'false'
                  );
                }}
              />
            )}
            <Margin />
            <SignButton
              type="submit"
              disabled={savingSignature.saveFlag}
              onClick={() => {
                formik.validateForm().then((errors: {}) => {
                  console.log(Object.keys(errors));
                });
              }}
            >
              {savingSignature.saveFlag
                ? intl.formatMessage({
                    id: 'label.savingSign',
                    defaultMessage: 'Saving your signature...',
                  })
                : intl.formatMessage({
                    id: 'label.signPetition',
                    defaultMessage: 'Sign this Petition',
                  })}
            </SignButton>
            <Margin />
            <TC>
              <FormattedMessage
                id="label.souPrivacy1"
                defaultMessage="SumOfUs will protect"
              />
              <a href="https://www.sumofus.org/privacy/">
                <FormattedMessage
                  id="label.souPrivacy2"
                  defaultMessage=" your privacy"
                />
              </a>
              <FormattedMessage
                id="label.souPrivacy3"
                defaultMessage=", and keep you updated about this and similar campaigns."
              />
              
            </TC>
            <Margin />
            <TC>
              <FormattedMessage
                id="label.recaptchaPolicy"
                defaultMessage="This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply."
              />
            </TC>

          </Wrapper>
        </form>
        {!savingSignature.saveFlag && savingSignature.error && (
          <Banner
            msg={intl.formatMessage({
              id: 'label.signFailure',
              defaultMessage:
                'Sorry! We were unable to record your signature at the moment. Please try again later.',
            })}
            setShowBanner={(flag: boolean) => {
              setSavingSignature({ saveFlag: false, error: flag });
            }}
            type="error"
          />
        )}
      </IntlProvider>
    </>
  );
};

export default ActionForm;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
`;

const Margin = styled.div`
  margin: 5px 0;
`;

const SignButton = styled(Button)`
  max-width: 100%;
`;

const TC = styled.div`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.6px;
  a {
    color: ${Colors.robinsEggBlue};
  }
`;
