const size = {
  mobileS: '375px',
  mobile: '600px',
  tablet: '1020px',
  laptop: '1440px',
  desktop: '2560px',
};

export const mobileSmall = `(max-width: ${size.mobileS})`;

export const mobile = `(max-width: ${size.mobile})`;

export const tablet = `(max-width: ${size.tablet})`;
