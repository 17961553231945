import React, { FunctionComponent, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Storage } from 'aws-amplify';
import { S3Image } from 'aws-amplify-react';
import { v4 as uuidv4 } from 'uuid';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../Button';
import Cropper from '../ImageCropper';
import Instructions from '../Instructions';
import { Step4InstructionsList } from '../Constants';
import Colors from '../../globals/Colors';
// import Input from '../../../components/Input';
import { mobile, tablet } from '../../globals/Devices';
import Banner from '../Banner';
import UploadImage from '../../images/upload-icon.png';

type Step4Props = {
  formik: any;
  currentStep: number;
  handleNextStep: any;
  openModal: any;
};

const ImagePickerContainer = styled.div`
  height: 450px;
  width: 800px;
  background-color: ${Colors.zircon3};
  margin: 20px 0;
  border: dashed 1px ${Colors.eastBay3};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    height: 365px;
    width: 650px;
  }
  @media ${mobile} {
    height: 200px;
    width: 356px;
  }
`;

const ImagePicker = styled.input`
  height: 200px;
  width: calc(100vw);
  max-width: 800px;
  opacity: 0;
  position: absolute;
  /* top: 55%; */
  &:focus {
    outline-color: transparent;
    outline-width: 0;
  }
`;

const UploadPhotoButton = styled.button`
  height: 40px;
  border: 0.8px solid ${Colors.lightGrey};
  border-radius: 12px;
`;

const loading = keyframes`
  0% { background: ${Colors.lightGrey} }
  25% { background: ${Colors.darkGray} }
  50% { background: ${Colors.lightGrey} }
  75% { background: ${Colors.darkGray} }
  100% { background: ${Colors.lightGrey} }
`;
const Wrapper = styled.div`
  border: 0.3px solid ${Colors.lightGrey};
  height: 450px;
  width: 800px;
  display: flex;
  justify-content: center;
  border-radius: 12px;
  margin-bottom: 38px;
  background-color: ${Colors.lightGrey};
  animation-name: ${loading};
  animation-duration: 3s;
  animation-iteration-count: 50;
  @media ${tablet} {
    height: 365px;
    width: 650px;
  }
  @media ${mobile} {
    height: 200px;
    width: 356px;
  }
`;

const ActionButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 20px 0;
  @media ${mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

const UploadButton = styled(Button)`
  margin: 0 8px;
  @media ${mobile} {
    max-width: 80%;
    margin: 10px 0;
  }
`;

const ChangePhotoButton = styled(Button)`
  margin: 0 8px;
  background-color: transparent;
  color: ${Colors.redOrange};
  @media ${mobile} {
    max-width: 80%;
    margin: 10px 0;
  }
`;

const ImageFromS3 = styled(S3Image)`
  width: 800px;
  height: 450px;
  border-radius: 6px;
  @media ${tablet} {
    height: 365px;
    width: 650px;
  }
  @media ${mobile} {
    height: 200px;
    width: 356px;
  }
`;

const UploadIcon = styled.img`
  height: 100px;
  width: 100px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
`;

const Step4: FunctionComponent<Step4Props> = ({
  formik,
  currentStep,
  openModal,
}) => {
  const [dimensions, setDimensions] = useState<{
    height: number;
    width: number;
  }>({ height: 450, width: 800 });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadProgress, setUploadProgress] = useState<{
    progress: number;
    loading: boolean;
  }>({ progress: 0, loading: false });

  const [originalImage, setoriginalImage] = useState<{
    file: any;
    src: string | ArrayBuffer | null;
    croppedFile: any;
  }>({ file: null, src: null, croppedFile: null });

  const [isImageSelected, setIsImageSelected] = useState(false);

  const [isImageSaveError, setIsImageSaveError] = useState<boolean>(false);

  const { formatMessage: fm } = useIntl();

  useEffect(() => {
    if (formik.values.imageUrl && formik.values.imageUrl !== '') {
      setoriginalImage({
        file: null,
        src: formik.values.imageUrl,
        croppedFile: formik.values.imageUrl,
      });
    }
  }, [formik.values.imageUrl]);

  useEffect(() => {
    function isMobile() {
      window.scrollTo(0, 0);
      if (window.innerWidth < 500) {
        setDimensions({ height: 200, width: 356 });
      } else if (window.innerWidth < 800) {
        setDimensions({ height: 365, width: 650 });
      }
    }
    isMobile();
  }, []);

  function getFilePath(fileName: any) {
    // console.log('filenmee', fileName);
    Storage.get(fileName.key)
      .then((result: any) => {
        formik.setFieldValue('imageUrl', result);
        setIsImageSelected(true);
        openModal({ open: true, error: false });
        setoriginalImage({ ...originalImage, src: result });
      })
      .catch((err: any) => {
        setIsImageSaveError(true);
        console.log(err);
      });
  }

  async function uploadS3Image(fileData: any) {
    if (formik.values.imageUrl === '') {
      setUploadProgress({
        ...uploadProgress,
        loading: true,
      });

      const fileName = `${uuidv4()}-${originalImage.file.name.replace(/[^\w]/g, '')}`;
      setIsImageSaveError(false);
      const blob = await (await fetch(fileData)).blob();
      Storage.put(fileName, blob, {
        contentType: originalImage.file.type,
        progressCallback(progress: { loaded: any; total: any }) {
          // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          const progressValue = Math.floor(
            (progress.loaded / progress.total) * 100
          );
          setUploadProgress({
            loading: true,
            progress: progressValue,
          });
        },
      })
        .then((result) => {
          getFilePath(result);
        })
        .catch((err) => {
          setIsImageSaveError(true);
          setUploadProgress({
            progress: 0,
            loading: false,
          });
        });
    } else {
      // setIsImageSelected(true);
    }
  }

  function getDataUrl(event: any) {
    // event.preventDefault();
    let files: any;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }
    if (files && files.length) {
      if (files[0] && files[0].type.indexOf('image') === 0) {
        const reader = new FileReader();
        reader.onload = () => {
          setoriginalImage({
            ...originalImage,
            src: reader?.result,
            file: files[0],
          });
        };
        reader.readAsDataURL(files[0]);
      }
    }
  }

  function getImageKey(url: string) {
    const imgUrl = url.split('?');
    const imageKey = imgUrl[0].split('/').pop();
    console.log(imageKey);
    return imageKey;
  }

  // function handleUrl(event: any) {
  //   const imageUrl = event.currentTarget.value;
  //   console.log(imageUrl);
  //   setoriginalImage({
  //     ...originalImage,
  //     src: imageUrl,
  //   });
  // }

  return currentStep !== 3 ? null : (
    <>
      <h2>
        <FormattedMessage id="label.addPhoto" defaultMessage="Add a photo" />
      </h2>
      <p>
        <FormattedMessage
          id="label.fileDesc"
          defaultMessage="Petitions with a photo or video receive six times more signatures than those without. Include one that captures the emotion of your story."
        />
      </p>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {originalImage.src === null && (
          <>
            <ImagePickerContainer>
              <UploadIcon src={UploadImage} />
              <UploadPhotoButton>
                <FormattedMessage
                  id="label.selectPhoto"
                  defaultMessage="Select a photo"
                />
              </UploadPhotoButton>

            </ImagePickerContainer>
            <ImagePicker
              id="add-image-file-input"
              type="file"
              onClick={() => {
                console.log('file picker opened');
              }}
              accept="image/*"
              onChange={(event: any) => {
                getDataUrl(event);
              }}
            />
            {/* <Input
              placeholder="Add a link to the image"
              onChange={event => handleUrl(event)}
            /> */}
          </>
        )}

        {originalImage?.src && formik.values.imageUrl === '' && (
          <Cropper
            imageURL={originalImage?.src}
            height={dimensions.height}
            width={dimensions.width}
            setCroppedData={(file: any) => {
              setoriginalImage({
                ...originalImage,
                croppedFile: file,
              });
            }}
          />
        )}
        {originalImage.croppedFile && formik.values.imageUrl && (
          <Wrapper>
            <ImageFromS3 imgKey={getImageKey(formik.values.imageUrl)} />
          </Wrapper>
        )}
      </div>
      {originalImage.croppedFile && (
        <ActionButtons>
          {!isImageSelected && (
            <ChangePhotoButton
              type="button"
              onClick={() => {
                setoriginalImage({
                  file: null,
                  src: null,
                  croppedFile: null,
                });
                formik.setFieldValue('imageUrl', '');
              }}
              disabled={uploadProgress.loading}
            >
              <FormattedMessage
                id="label.changePhoto"
                defaultMessage="Change Photo"
              />
            </ChangePhotoButton>
          )}
          {!isImageSelected && (
            <UploadButton
              type="button"
              onClick={() => uploadS3Image(originalImage.croppedFile)}
              disabled={uploadProgress.loading}
            >
              {uploadProgress.progress === 0
                ? fm({
                    id: 'label.uploadPhoto',
                    defaultMessage: 'Upload Photo',
                  })
                : fm(
                    {
                      id: 'label.uploading',
                      defaultMessage: 'Uploading...',
                    },
                    { progress: uploadProgress.progress }
                  )}
            </UploadButton>
          )}
          {isImageSelected && (
            <Button
              type="button"
              onClick={() => openModal({ open: true, error: false })}
            >
              <FormattedMessage id="label.save" defaultMessage="Save" />
            </Button>
          )}
        </ActionButtons>
      )}
      {isImageSaveError && (
        <Banner
          msg={fm({
            id: 'label.photoNotUploaded',
            defaultMessage:
              "Sorry! Your photo couldn't be uploaded at the moment. Please try again later.",
          })}
          setShowBanner={setIsImageSaveError}
          type="error"
        />
      )}
      <Instructions listOfIntructions={Step4InstructionsList} />
    </>
  );
};

export default Step4;
