/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import Slider from 'react-slick';
import Moment from 'react-moment';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Colors from '../../globals/Colors';
import { ReportCategories } from '../Constants';

enum PetitionFlagReason {
  DISLIKE_DISAGREE = 'DISLIKE_DISAGREE',
  MISLEADING_SPAM = 'MISLEADING_SPAM',
  RIGHTS_INFRINGEMENT = 'RIGHTS_INFRINGEMENT',
  ABUSIVE_HATEFUL = 'ABUSIVE_HATEFUL',
  INAPPROPRIATE_IMAGES = 'INAPPROPRIATE_IMAGES',
  HARMFUL_TO_CHILDREN = 'HARMFUL_TO_CHILDREN',
  VIOLENCE_SELF_HARM_SUICIDE = 'VIOLENCE_SELF_HARM_SUICIDE',
  IMPERSONATION = 'IMPERSONATION',
}
interface ReportReason {
  comment: string | null;
  reason: PetitionFlagReason;
  createdAt: string | null;
}
interface ReportReasonsModalProps {
  reasons: ReportReason[];
  openModal: boolean;
  setOpenModal: any;
}

const settings = {
  dots: true,
  arrows: true,
  adaptiveHeight: true,
  centerMode: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    height: '50vh',
    maxWidth: '900px',
    maxHeight: '500px',
  },
};
const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  .slick-slider {
    width: 750px;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 30px;
    color: ${Colors.regalBlue};
  }
`;
const ModalTitle = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin: 20px;
`;

const Cross = styled.div`
  font-size: 20px;
  background-color: ${Colors.redOrange};
  color: white;
  padding: 2px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  border: 0.3px solid ${Colors.lightGray};
  font-weight: normal;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
`;

const ReasonsWrapper = styled.div`
  display: flex;
  height: 90%;
  align-items: center;
  justify-content: center;
`;
const Reasons = styled.div`
  display: flex;
  border: 1px solid;
  border-radius: 6px;
  padding: 20px;
  max-width: 600px;
  max-height: 350px;
  overflow: scroll;
  outline: none;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;
const Comment = styled.div`
  font-size: 14px;
`;
const Created = styled.div`
  font-size: 14px;
`;

Modal.setAppElement('#root');

const ReportReasonsModal = (props: ReportReasonsModalProps) => {
  const { reasons, openModal, setOpenModal } = props;

  function getReason(reason: string) {
    const result = ReportCategories.filter(category => {
      return category.key === reason;
    });
    return result[0].value;
  }

  return (
    <Modal
      isOpen={openModal}
      ariaHideApp={false}
      onRequestClose={() => setOpenModal(false)}
      contentLabel="Action Modal"
      closeTimeoutMS={200}
      style={customStyles}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <ModalContentWrapper>
        <Cross onClick={() => setOpenModal(false)}>X</Cross>
        <ModalTitle>Reports by users</ModalTitle>
        <ReasonsWrapper>
          <Slider {...settings}>
            {reasons.map((reason, idx) => {
              return (
                <Reasons key={idx}>
                  <Heading>
                    <Title>{getReason(reason.reason)}</Title>
                    {reason.createdAt && (
                      <Created>
                        <Moment fromNow>{reason.createdAt}</Moment>
                      </Created>
                    )}
                  </Heading>
                  <Comment>{reason.comment}</Comment>
                </Reasons>
              );
            })}
          </Slider>
        </ReasonsWrapper>
      </ModalContentWrapper>
    </Modal>
  );
};

export default ReportReasonsModal;
