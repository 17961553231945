import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Modal from 'react-modal';
import Colors from '../../globals/Colors';
import Button from '../Button';

interface ModalProps {
  openModal: boolean;
  setModalOpen: any;
  setClick: any;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '40vw',
    maxWidth: '650px',
    minHeight: '450px',
  },
};

const customMobileStyles = {
  content: {
    top: '0%',
    left: '0%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '0%',
    // height: '100vh',
    width: '100vw',
  },
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Content = styled.div`
  padding: 20px 0;
  .Congrats {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
`;

const Cross = styled.div`
  font-size: 20px;
  background-color: ${Colors.redOrange};
  color: white;
  padding: 2px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  border: 0.3px solid ${Colors.lightGray};
  font-weight: normal;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
`;

const CreatePetitionButton = styled(Button)`
  max-width: 80vw;
  text-transform: uppercase;
`;

const ChangePhotoModal = (props: ModalProps) => {
  const { openModal, setModalOpen, setClick } = props;

  const [dimensions, setDimensions] = React.useState<{
    height: number;
    width: number;
  }>({ height: 450, width: 800 });

  React.useEffect(() => {
    function isMobile() {
      if (window.innerWidth < 500) {
        setDimensions({ height: 200, width: 356 });
      } else if (window.innerWidth < 800) {
        setDimensions({ height: 365, width: 650 });
      }
    }
    isMobile();
  }, []);

  return (
    <Modal
      isOpen={openModal}
      shouldCloseOnOverlayClick
      ariaHideApp={false}
      shouldCloseOnEsc
      onRequestClose={() => setModalOpen({ open: false, error: false })}
      style={dimensions.width < 660 ? customMobileStyles : customStyles}
      contentLabel="TC Modal"
      closeTimeoutMS={200}
    >
      <Wrapper>
        <Cross onClick={() => setModalOpen({ open: false, error: false })}>
          X
        </Cross>
        <Content>
          <div className="Congrats">
            <FormattedMessage
              id="label.congrats"
              defaultMessage="Congratulations"
            />
          </div>
          <br />
          <br />
          <div style={{ textAlign: 'center' }}>
            <FormattedMessage
              id="label.petitionReady"
              defaultMessage="Your petition is almost ready."
            />
          </div>
          <br />
          <div>
            <FormattedMessage
              id="label.petitionPopupContent"
              defaultMessage="You’re responsible for what you post on this platform. You should keep your campaigns and comments in line with our Community Guidelines and the law, and respect the intellectual property rights of others. It’s not something we want to do, but we can terminate your account for repeated or major offenses."
            />
          </div>
          <br />
          <br />
          <div>
            <span>
              <FormattedMessage
                id="label.petitionPopupTerm1"
                defaultMessage="By creating a petition, you agree to all our"
              />
            </span>
            <a
              href="https://www.sumofus.org/privacy/#user-contributions"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage
                id="label.petitionPopupTerm2"
                defaultMessage=" terms and conditions."
              />
            </a>
          </div>
        </Content>
        <CreatePetitionButton
          type="button"
          onClick={() => {
            setClick();
          }}
        >
          <FormattedMessage
            id="label.createPetition"
            defaultMessage="Create Petition"
          />
        </CreatePetitionButton>
      </Wrapper>
    </Modal>
  );
};

export default ChangePhotoModal;
