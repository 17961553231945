import * as React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { Storage } from 'aws-amplify';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';
import Colors from '../../globals/Colors';
import Cropper from '../ImageCropper';
import Button from '../Button';
import { mobile, tablet } from '../../globals/Devices';
import UploadImage from '../../images/upload-icon.png';

interface ModalProps {
  openModal: boolean;
  setModalOpen: any;
  setFileProps: any;
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '70vw',
    maxWidth: '850px',
    maxHeight: '650px',
  },
};

const customMobileStyles = {
  content: {
    top: '0%',
    left: '0%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '0%',
    // height: '100vh',
    width: '100vw',
  },
};

const Header = styled.div`
  height: 30px;
  font-size: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: bold;
`;

const Cross = styled.div`
  font-size: 20px;
  background-color: ${Colors.redOrange};
  color: white;
  padding: 2px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  border: 0.3px solid ${Colors.lightGray};
  font-weight: normal;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const ImagePickerContainer = styled.div`
  height: 450px;
  width: 800px;
  background-color: ${Colors.zircon3};
  margin: 20px auto;
  border: dashed 1px ${Colors.eastBay3};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media ${tablet} {
    height: 365px;
    width: 650px;
  }
  @media ${mobile} {
    height: 200px;
    width: 356px;
  }
`;

const ImagePicker = styled.input`
  height: 200px;
  width: calc(100vw);
  max-width: 800px;
  opacity: 0;
  position: absolute;
  top: 40%;
  left: 5%;
  &:focus {
    outline-color: transparent;
    outline-width: 0;
  }
  @media ${mobile} {
    top: 10%;
    width: 80vw;
  }
`;

const UploadPhotoButton = styled.button`
  height: 40px;
  border: 0.8px solid ${Colors.lightGrey};
  border-radius: 12px;
`;

const UploadIcon = styled.img`
  height: 100px;
  width: 100px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
`;

const UpdateImageButton = styled(Button)`
  float: right;
`;

const ChangePhotoModal = (props: ModalProps) => {
  const { openModal, setModalOpen, setFileProps } = props;

  const { formatMessage: fm } = useIntl();

  const [dimensions, setDimensions] = React.useState<{
    height: number;
    width: number;
  }>({ height: 450, width: 800 });

  const [originalImage, setoriginalImage] = React.useState<{
    file: any;
    src: string | ArrayBuffer;
    croppedFile: any;
  }>({ file: null, src: '', croppedFile: null });

  const [uploadProgress, setUploadProgress] = React.useState<{
    progress: number;
    loading: boolean;
  }>({ progress: 0, loading: false });

  React.useEffect(() => {
    function isMobile() {
      if (window.innerWidth < 500) {
        setDimensions({ height: 200, width: 356 });
      } else if (window.innerWidth < 800) {
        setDimensions({ height: 365, width: 650 });
      }
    }
    isMobile();
  }, []);

  function getDataUrl(event: any) {
    event.preventDefault();
    let files: any;
    if (event.dataTransfer) {
      files = event.dataTransfer.files;
    } else if (event.target) {
      files = event.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setoriginalImage({
        ...originalImage,
        src: reader?.result || '',
        file: files[0],
      });
    };
    reader.readAsDataURL(files[0]);
  }

  function getFilePath(fileName: any) {
    console.log('filenmee', fileName);
    Storage.get(fileName.key)
      .then((result: any) => {
        // formik.setFieldValue('imageUrl', result);
        // setIsImageSelected(true);
        localStorage.setItem('editedImageUrl', result);
        setFileProps(result);
        setoriginalImage({ ...originalImage, src: result });
        setModalOpen(false);
        console.log(result);
      })
      .catch((err: any) => console.log(err));
  }

  async function uploadS3Image(fileData: any) {
    // if (formik.values.imageUrl === '') {
    setUploadProgress({
      ...uploadProgress,
      loading: true,
    });
    const fileName = `${uuidv4()}-${originalImage.file.name.replace(
      /[^\w]/g,
      ''
    )}`;
    const blob = await (await fetch(fileData)).blob();
    Storage.put(fileName, blob, {
      contentType: originalImage.file.type,
      progressCallback(progress: { loaded: any; total: any }) {
        // console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        const progressValue = Math.floor(
          (progress.loaded / progress.total) * 100
        );
        setUploadProgress({
          loading: true,
          progress: progressValue,
        });
      },
    })
      .then((result) => getFilePath(result))
      .catch((err) =>
        setUploadProgress({
          progress: 0,
          loading: false,
        })
      );
    // } else {
    //   setIsImageSelected(true);
    // }
  }

  return (
    <Modal
      isOpen={openModal}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      onRequestClose={() => setModalOpen(false)}
      style={dimensions.width < 660 ? customMobileStyles : customStyles}
      contentLabel="Change photo Modal"
      closeTimeoutMS={200}
    >
      <Header>
        <div>
          <FormattedMessage
            id="label.changeCoverPhoto"
            defaultMessage="Change cover photo"
          />
        </div>
        {uploadProgress.progress === 0 && (
          <Cross onClick={() => setModalOpen(false)}>X</Cross>
        )}
      </Header>
      {originalImage && originalImage.src === '' && (
        <>
          <ImagePickerContainer>
            <UploadIcon src={UploadImage} />
            <UploadPhotoButton>
              <FormattedMessage
                id="label.selectPhoto"
                defaultMessage="Select a photo"
              />
            </UploadPhotoButton>
          </ImagePickerContainer>
          <ImagePicker
            id="add-image-file-input"
            type="file"
            accept="image/*"
            onChange={(event: any) => {
              getDataUrl(event);
            }}
          />
        </>
      )}
      {originalImage && originalImage.src !== '' && (
        <Cropper
          imageURL={originalImage?.src}
          height={dimensions.height}
          width={dimensions.width}
          setCroppedData={
            (file: any) =>
              setoriginalImage({
                ...originalImage,
                croppedFile: file,
              })
            // eslint-disable-next-line react/jsx-curly-newline
          }
        />
      )}
      <UpdateImageButton
        type="button"
        disabled={uploadProgress.loading || originalImage?.src === ''}
        onClick={() => uploadS3Image(originalImage.croppedFile)}
      >
        {uploadProgress.progress === 0
          ? fm({
              id: 'label.savePreview',
              defaultMessage: 'Save & Preview',
            })
          : fm(
              {
                id: 'label.uploading',
                defaultMessage: 'Uploading...',
              },
              { progress: uploadProgress.progress - 1 }
            )}
      </UpdateImageButton>
    </Modal>
  );
};

export default ChangePhotoModal;
