import React, { useContext } from 'react';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';
import { AuthContext } from '../../services/auth.service';
import Button from '../Button/index';
import Colors from '../../globals/Colors';
import { mobile } from '../../globals/Devices';
import { LangContext } from '../../services/lang.service';

interface LoginButtonProps {
  isDarkLogo: boolean;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media ${mobile} {
    width: 60%;
  }
`;

const LoginBtn = styled(Button)`
  display: block;
  /* width: 90px; */
  height: 32px;
  margin: 0px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${Colors.redOrange};
  @media ${mobile} {
    display: none;
  }
`;

interface LangSelectProps {
  isDarkColor: boolean;
}
const LangSelect = styled.select<LangSelectProps>`
  width: 110px;
  margin: 0 20px;
  padding: 0 15px;
  border: 0;
  font-size: 14px;
  font-weight: 500;
  color: ${({ isDarkColor }) => (isDarkColor ? Colors.black : Colors.white)};
  cursor: pointer;
  background-color: transparent;
  outline: none;
  appearance: none;
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0) 50%, #04ccde 50%),
    linear-gradient(135deg, #04ccde 50%, rgba(0, 0, 0, 0) 50%);
  background-position: calc(100% - 5px) 10px, calc(100% - 0px) 10px,
    calc(100% - 1.5em) 3.5px;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
`;

const LoginButton = (props: LoginButtonProps) => {
  const { isDarkLogo } = props;
  const authContext: any = useContext(AuthContext);
  const langContext: any = useContext(LangContext);
  const { lang, setLang } = langContext;

  function changeLang(langValue: string) {
    setLang(langValue);
    localStorage.setItem('locale', langValue);
  }

  return (
    <>
      <Wrapper>
        <LangSelect
          id="lang"
          name="lang"
          isDarkColor={isDarkLogo}
          value={lang}
          onChange={(e: any) => {
            changeLang(e.target.value);
          }}
        >
          <option value="en">ENGLISH</option>
          <option value="de">DEUTSCH</option>
          <option value="fr">FRANÇAIS</option>
          {/* <option value="es">ESPAÑOL</option> */}
        </LangSelect>
        {authContext ? (
          <LoginBtn
            type="button"
            onClick={() => {
              sessionStorage.removeItem('isAdmin');
              Auth.signOut();
            }}
          >
            <FormattedMessage id="label.signOut" defaultMessage="Sign out" />
          </LoginBtn>
        ) : (
          <LoginBtn
            type="button"
            onClick={() => {
              localStorage.setItem('previousPath', window.location.pathname);
              navigate('/login');
            }}
          >
            <FormattedMessage id="label.login" defaultMessage="Login" />
          </LoginBtn>
        )}
      </Wrapper>
    </>
  );
};

export default LoginButton;
