import * as React from 'react';
import styled from 'styled-components';
import { FormikErrors } from 'formik';
import Colors from '../../globals/Colors';

export interface Props extends React.HTMLProps<HTMLInputElement> {
  error?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
}

const InputComponent = styled.input`
  width: 100%;
  background-color: ${Colors.whiteSmoke};
  color: ${Colors.black};
  padding: 12px 18px 12px;
  font-size: 18px;
  line-height: 21px;
  -webkit-appearance: none;
  border-radius: 3px;
  border: 0.3px solid ${Colors.whiteSmoke};
  &:focus {
    outline: 0;
    border-color: ${Colors.robinsEggBlue};
    border-width: 1px;
    border-radius: 3px;
  }
  &::placeholder {
    opacity: 0.41;
  }
  &:disabled {
    color: ${Colors.darkGray};
  }
`;

const ErrorContainer = styled.div`
  text-align: left;
  color: ${Colors.red};
  font-size: 14px;
`;

const Input = (props: Props) => {
  const {
    value,
    error,
    minLength,
    maxLength,
    required,
    autoFocus,
    id,
    name,
    onFocus,
    onBlur,
    onChange,
    placeholder,
    disabled,
    type,
  } = props;
  const inputRef = React.useRef<HTMLInputElement>(null);
  return (
    <>
      <InputComponent
        ref={inputRef}
        maxLength={maxLength}
        minLength={minLength}
        value={value}
        required={required}
        autoFocus={autoFocus}
        id={id}
        name={name}
        type={type}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      {error && <ErrorContainer>{error}</ErrorContainer>}
    </>
  );
};

export default Input;
