import * as React from 'react';
import styled from 'styled-components';
import Colors from '../../globals/Colors';
import { FormattedMessage } from 'react-intl';

// import supportIcon from '../../images/supporticon.png';

type Props = {
  totalSupporters: number;
};

const Band = styled.div`
  float: right;
  padding-top: 20px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.09px;
  color: ${Colors.blueWhale};
  cursor: pointer;
  .fa {
    font-size: 16px;
    padding-right: 4px;
    color: ${Colors.robinsEggBlue};
  }
`;

export default function SupporterBand(props: Props) {
  const { totalSupporters } = props;

  return (
    <Band>
      <i className="fa fa-users" />
      {totalSupporters}
      <FormattedMessage id="label.supporters" defaultMessage=" supporters" />
    </Band>
  );
}
