import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { ResetPasswordValidationSchema } from './helpers/ValidationSchema';
import { Wrapper, Margin, Links } from './helpers/AuthStyles';
import Input from '../Input';
import Button from '../Button';

interface ResetPasswordProps {
  changeAuthState: any;
  setBannerDetails: any;
  autoFillData: { email: string; password: string };
}
interface ResetPasswordFormValues {
  code: string;
  password: string;
}

const ResetPassword = (props: ResetPasswordProps) => {
  const { changeAuthState, autoFillData, setBannerDetails } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const { formatMessage: fm } = useIntl();

  const initialValues: ResetPasswordFormValues = {
    code: '',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ResetPasswordValidationSchema,
    onSubmit: (values: ResetPasswordFormValues) => {
      setLoading(true);
      Auth.forgotPasswordSubmit(
        autoFillData.email,
        values.code,
        values.password
      )
        .then(() => {
          setBannerDetails({
            msg: fm({
              id: 'msg.pwdChangeSuccess',
              defaultMessage: 'Password changed successfully',
            }),
            type: 'success',
            show: true,
          });
          setLoading(false);
          changeAuthState('sign-in');
        })
        .catch((err: any) => {
          setLoading(false);
          setBannerDetails({
            msg: fm({
              id: 'msg.pwdChangeFailed',
              defaultMessage: 'Password change failed',
            }),
            type: 'error',
            show: true,
          });
          console.log('error', err, err.code);
        });
    },
  });

  return (
    <Wrapper>
      <div className="title">
        <FormattedMessage
          id="label.resetPassword"
          defaultMessage="Reset your password"
        />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          autoFocus={false}
          required
          id="code"
          name="code"
          type="text"
          placeholder={fm({
            id: 'label.verificationCode',
            defaultMessage: 'Verification code',
          })}
          onChange={formik.handleChange}
          value={formik.values.code}
          error={formik.errors.code ? fm({ id: `${formik.errors.code}` }) : ''}
        />
        <Margin />
        <Input
          autoFocus={false}
          required
          id="password"
          name="password"
          type="password"
          placeholder={fm({
            id: 'label.newPwd',
            defaultMessage: 'New Password',
          })}
          onChange={formik.handleChange}
          value={formik.values.password}
          error={
            formik.errors.password
              ? fm({ id: `${formik.errors.password}` })
              : ''
          }
        />
        <Margin />
        <Button
          type="submit"
          disabled={loading}
          onClick={() => {
            formik.validateForm().then((errors: {}) => {
              console.log(Object.keys(errors));
            });
          }}
        >
          {loading
            ? fm({
                id: 'msg.changingPwd',
                defaultMessage: 'Changing password...',
              })
            : fm({
                id: 'label.changePwd',
                defaultMessage: 'Change Password',
              })}
        </Button>
      </form>
      <Links>
        <div
          onClick={() => changeAuthState('sign-in')}
          onKeyPress={() => console.log('sign-in')}
          role="button"
          tabIndex={0}
        >
          <FormattedMessage
            id="label.backToSignIn"
            defaultMessage="Back to Sign In"
          />
        </div>
      </Links>
    </Wrapper>
  );
};

export default ResetPassword;
