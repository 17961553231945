import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Colors from '../../globals/Colors';
import { mobile } from '../../globals/Devices';

export interface Props {
  value: string;
  current: boolean;
  onClick: any;
}

const Step = styled.div`
  height: 56px;
  width: 56px;
  border-radius: 50%;
  background-color: ${({ current }: Props) =>
    current ? Colors.robinsEggBlue : Colors.gainsboro};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ current }: Props) => (current ? Colors.white : Colors.black)};
  font-size: 24px;
  font-weight: ${({ current }: Props) => (current ? `600` : `400`)};
  transition: background-color 0.5s;
  pointer-events: ${({ current }: Props) => (current ? 'visible' : 'none')};
`;

const StepText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.blueWhale};
  margin: 12px 0 0;
`;

const StepView = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Hr = styled.div`
  width: 100px;
  height: 2px;
  border-radius: 5px;
  background-color: ${Colors.shadyLady8};
  margin: 0 10px;
  @media ${mobile} {
    width: 0;
  }
`;

const StepNumber = (props: Props) => {
  const { value, current, onClick } = props;
  return (
    <StepView>
      <StepCount>
        <Step current={current} value={value} onClick={onClick}>
          {value}
        </Step>
        {value !== '4' && <Hr />}
      </StepCount>
      <StepText>
        <FormattedMessage id="label.step" defaultMessage="Step" />
        {value}
      </StepText>
    </StepView>
  );
};

export default StepNumber;
