/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:de5b5017-4e7c-45cd-88c9-750cb78dc27d",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_qAnFwRltx",
    "aws_user_pools_web_client_id": "7vhtjv3fbra37uqvjr494m9sb7",
    "oauth": {
        "domain": "communityaf1e375e-af1e375e-develop.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://community.sumofus.org,http://localhost:8000/,https://development.d2lilqwqx30886.amplifyapp.com/",
        "redirectSignOut": "http://localhost:8000/,https://development.d2lilqwqx30886.amplifyapp.com/,https://community.sumofus.org",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://mrcmv7x6djfhbhkmrhlmhkqsuy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-3ggudou3wfcwvfy7oscpjtm7dy",
    "aws_cloud_logic_custom": [
        {
            "name": "restAPI",
            "endpoint": "https://5pgo4vt803.execute-api.eu-west-1.amazonaws.com/develop",
            "region": "eu-west-1"
        }
    ],
    "aws_user_files_s3_bucket": "community-petition-images-bucket104439-develop",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_content_delivery_bucket": "community-20200623115836-hostingbucket-develop",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://dnqe93bd6vexf.cloudfront.net"
};


export default awsmobile;
