import React, { useEffect, useState } from 'react';
import { Router, globalHistory, Redirect } from '@reach/router';
import { Hub } from 'aws-amplify';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import IndexPage from './pages/index';
import LoginScreen from './components/LoginScreen';
import Petitions from './components/MyPetitions/MyPetitionList';
import NewPetition from './pages/u';
import Preview from './components/Preview';
import Petition from './components/Petition';
import Layout from './components/layout';
import ConfirmConsent from './components/ConfirmGDPR';
import SharePetition from './components/SharePetition';
import Dashboard from './components/Dashboard';
import DashboardListing from './components/Dashboard/Listing';
import NotFound from './pages/404';
import './App.css';

ReactGA.initialize('UA-26370633-7');
ReactGA.pageview(window.location.pathname + window.location.search);
ReactPixel.init('938117079582809');
ReactPixel.pageView();

function App() {
  const path = localStorage.getItem('previousPath') || '/';
  const [continuePetition, setContinuePetition] = useState(false);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      setContinuePetition(event === 'signIn' && path !== '/');
    });

    const queryString = window.location.search;
    if (queryString.match('akid|source|src')) {
      sessionStorage.setItem('akQueryString', window.location.search.slice(1));
    }

    return globalHistory.listen(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    });
  }, []);

  return (
    <Layout>
      <Router>
        {continuePetition && <Redirect from="/" to={path} noThrow />}
        <IndexPage path="/" />
        <LoginScreen path="/login" />
        <NewPetition path="/u/new" />
        <Petitions path="/my-petitions" />
        <Preview path="/u/:petitionId" />
        <Petition path="/petition/:petitionId" />
        <ConfirmConsent path="/u/confirm/:petitionId/:email/:consent" />
        <SharePetition path="/petition/:petitionId/share" />
        <Dashboard path="/dashboard" />
        <DashboardListing path="/dashboard/:type" />
        <NotFound default />
      </Router>
    </Layout>
  );
}

export default App;
