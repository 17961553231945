import React, { useState, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { AuthContext } from './services/auth.service';
import { PetitionContext } from './services/petition.service';
import { LangContext } from './services/lang.service';
import { I18n } from './globals/Locale';
import 'moment/locale/fr';
import 'moment/locale/de';
import 'moment/locale/es';

import config from './aws-exports';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn:
    'https://4825c22c24ae4982a3282370ed992a1b@o142675.ingest.sentry.io/5262915',
});

const { host } = window.location;

if (config.oauth.redirectSignIn.includes(',')) {
  const filterHost = (url: any) => new URL(url).host === host;
  config.oauth.redirectSignIn = config.oauth.redirectSignIn
    .split(',')
    .filter(filterHost)
    .shift();

  config.oauth.redirectSignOut = config.oauth.redirectSignOut
    .split(',')
    .filter(filterHost)
    .shift();
}

// To find default language to be loaded
const languageList: string[] = ['en', 'fr', 'de'];
let langSelected: string = localStorage.getItem('locale') || 'en';
const { search } = window.location;
if (search.includes('lang=')) {
  const queryParamLang = search.substring(
    search.indexOf('lang=') + 5, // search lang= and add 5 char to determine start position of lang that is given in query params
    search.indexOf('lang=') + 7 // search lang= and add 7 char to determine end position of lang that is given in query params
  );
  // If the language given in query params is valid only then change the lang
  if (languageList.includes(queryParamLang)) {
    langSelected = queryParamLang;
    localStorage.setItem('locale', queryParamLang);
  }
}

const handleAuthEvent = (payload: any, callback: any) => {
  switch (payload.event) {
    case 'signIn':
      ReactGA.event({
        category: 'User',
        action: 'Signed in',
      });
      callback();
      break;
    case 'signOut':
      callback(undefined);
      break;
    case 'customOAuthState':
      break;
    default:
      break;
  }
};

Amplify.configure(config);

const AuthWrapper = ({ children }: any) => {
  const [user, setUser] = useState(null);

  const getUser = async () => {
    if (!user) {
      try {
        const data = await Auth.currentAuthenticatedUser();
        // @ts-ignore
        window.user = data;

        setUser(data);
        console.log('signed in and set user');
        ReactGA.set({ userId: data.username });
      } catch (e) {
        setUser(null);
        console.info("authentication wasn't successful", e);
      }
    }
  };

  Hub.listen('auth', ({ payload: { event, data } }) => {
    if (event === 'signIn') {
      handleAuthEvent({ event, data }, getUser);
    } else {
      handleAuthEvent({ event, data }, setUser);
    }
  });
  if (!user) getUser();
  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};

const PetitionWrapper = ({ children }: any) => {
  const petitionFromStorage = localStorage.getItem('petitionDetails') || '';
  const [petitionDetails, setPetitionDetails] = useState(
    petitionFromStorage ? JSON.parse(petitionFromStorage) : null
  );

  const value = useMemo(
    () => ({
      petitionDetails,
      setPetitionDetails,
    }),
    [petitionDetails, setPetitionDetails]
  );

  return (
    <PetitionContext.Provider value={value}>
      {children}
    </PetitionContext.Provider>
  );
};

function FinalApp() {
  const [lang, setLang] = useState(langSelected);

  const value = useMemo(
    () => ({
      lang,
      setLang,
    }),
    [lang, setLang]
  );

  return (
    <LangContext.Provider value={value}>
      <IntlProvider locale={lang} key={lang} messages={I18n[lang]}>
        <AuthWrapper>
          <PetitionWrapper>
            <App />
          </PetitionWrapper>
        </AuthWrapper>
      </IntlProvider>
    </LangContext.Provider>
  );
}

ReactDOM.render(<FinalApp />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
