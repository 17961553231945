import * as React from 'react';
import styled from 'styled-components';
import stripHtml from 'string-strip-html';
import { useIntl, defineMessages } from 'react-intl';
// import StartNewPetition from './StartPetition';
import ReportPetition from './ReportPetition';
import PetitionImage from './PetitionImage';
import Colors from '../../globals/Colors';
import Input from '../Input';
import Editor from '../Editor';
import { tablet } from '../../globals/Devices';

const HtmlToReactParser = require('html-to-react').Parser;

const htmlToReactParser = new HtmlToReactParser();

interface LeftContentProps {
  petitionId: string | null;
  imageUrl: string;
  content: string;
  owner: string;
  target: string;
  editMode: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 20px 0 0;
  @media ${tablet} {
    width: 100%;
    margin-bottom: 100px;
  }
`;

const Content = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.2px;
  text-align: justify;
  color: ${Colors.blueWhale};
`;

const PetitionerDetails = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  margin: 5px 0 20px 0;
  justify-content: start;
  align-items: center;
`;

const Avatar = styled.div`
  height: 50px;
  min-width: 50px;
  background: ${Colors.redOrange};
  box-shadow: 0px 0px 1px 3px ${Colors.suvaGrey2};
  color: ${Colors.white};
  border-radius: 50%;
  margin-right: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Details = styled.div`
  font-size: 16px;
`;

const LeftContent = (props: LeftContentProps) => {
  const { imageUrl, content, owner, target, editMode, petitionId } = props;
  const [editedtarget, setEditedTarget] = React.useState(target);
  const [editedContent, setEditedContent] = React.useState(content);

  const { formatMessage: fm } = useIntl();

  React.useEffect(() => {
    if (editedtarget.length) {
      localStorage.setItem('editedTarget', editedtarget);
    } else {
      localStorage.setItem('editedTarget', target);
    }
  }, [editedtarget]);

  React.useEffect(() => {
    if (stripHtml(editedContent).length) {
      localStorage.setItem('editedDesc', editedContent);
    } else {
      localStorage.setItem('editedDesc', content);
    }
  }, [editedContent]);

  function getInitials(name: string) {
    if (name) {
      const splitName = name.split(' ');
      return splitName.length > 1
        ? splitName[0].substr(0, 1) + splitName[1].substr(0, 1)
        : name.substr(0, 1);
    }
    return 'A';
  }

  function getParsedContent(desc: string) {
    if (desc) {
      return htmlToReactParser.parse(
        desc.split('<p><br></p>').join('').split('<p>&nbsp;</p>').join('')
      );
    }
    return '';
  }

  const messages = defineMessages({
    greeting: {
      id: 'label.starterInfo',
      defaultMessage:
        'SumOfUs member <bold>{owner}</bold> started this petition to <bold>{target}</bold>',
    },
  });

  const starterInfo = fm(messages.greeting, {
    owner,
    target,
    bold: (str) => <b>{str}</b>,
  });

  return (
    <>
      <Wrapper>
        <PetitionerDetails>
          <Avatar>{getInitials(owner)}</Avatar>
          {editMode ? (
            <Input
              id="title"
              name="title"
              type="text"
              placeholder={fm({
                id: 'label.titlePlaceholder',
                defaultMessage: 'What do you want to achieve?',
              })}
              onChange={(event) => setEditedTarget(event.currentTarget.value)}
              value={editedtarget}
            />
          ) : (
            <Details>{starterInfo}</Details>
          )}
        </PetitionerDetails>
        <PetitionImage imageUrl={imageUrl} isMobile={false} />
        {editMode ? (
          <Editor
            value={editedContent}
            onChange={(value: any) => {
              setEditedContent(value);
            }}
            error=""
          />
        ) : (
          <Content>{getParsedContent(content)}</Content>
        )}
        {petitionId && !editMode && <ReportPetition petitionId={petitionId} />}
        {/* <StartNewPetition /> */}
      </Wrapper>
    </>
  );
};

export default LeftContent;
