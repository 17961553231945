/* eslint-disable react/no-array-index-key */
import * as React from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import Colors from '../../globals/Colors';

type InstructionProps = {
  titleId: string;
  title: string;
  descId: string;
  desc: string;
};

type InstructionList = {
  listOfIntructions: InstructionProps[];
};

const InstructionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 25px 0;
  border-radius: 6px;
`;

const InstructionHeader = styled.h2`
  color: ${Colors.black};
  margin: 16px 10px;
  letter-spacing: 0.2px;
  font-size: 24px;
`;

const Hr = styled.div`
  width: 100%;
  border: solid 0.8px ${Colors.eastBay1};
  border-radius: 4px;
`;
const UnorderedList = styled.ul`
  padding-inline-start: 10px;
  margin: 10px 0;
`;

const ListItems = styled.li`
  list-style: none;
  padding: 10px 0;
  margin: 0;
`;
const Info = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: 0.18px;
`;

const Desc = styled.div`
  font-size: 16px;
  letter-spacing: 0.18px;
`;

const Instructions = (props: InstructionList) => {
  const { listOfIntructions } = props;

  const { formatMessage: fm } = useIntl();

  return (
    <InstructionWrapper>
      <InstructionHeader>
        <FormattedMessage
          id="label.instructions"
          defaultMessage="Instructions"
        />
      </InstructionHeader>
      <Hr />
      <UnorderedList>
        {listOfIntructions.map((instruction, index) => {
          return (
            <ListItems key={`index${index}`}>
              <Info>
                {fm({
                  id: instruction.titleId,
                  defaultMessage: instruction.title,
                })}
              </Info>
              <Desc>
                {fm({
                  id: instruction.descId,
                  defaultMessage: instruction.desc,
                })}
              </Desc>
            </ListItems>
          );
        })}
      </UnorderedList>
    </InstructionWrapper>
  );
};

export default Instructions;
