import * as Yup from 'yup';

export const SignInValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('msg.emailRequired')
    .email('msg.emailNotValid'),
  password: Yup.string()
    .required('msg.pwdRequired')
    .min(8, 'msg.pwdMin8'),
});

export const SignUpValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('msg.emailRequired')
    .email('msg.emailNotValid'),
  name: Yup.string().required('msg.nameRequired'),
  password: Yup.string()
    .required('msg.pwdRequired')
    .min(8, 'msg.pwdMin8'),
});

export const ConfirmAccountValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('msg.emailRequired')
    .email('msg.emailNotValid'),
  code: Yup.string().required('msg.codeRequired'),
});

export const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .required('msg.emailRequired')
    .email('msg.emailNotValid'),
});

export const ResetPasswordValidationSchema = Yup.object().shape({
  code: Yup.string().required('msg.codeRequired'),
  password: Yup.string()
    .required('msg.pwdRequired')
    .min(8, 'msg.pwdMin8'),
});
