/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Redirect, navigate } from '@reach/router';
import ReactGA from 'react-ga';
import { API, graphqlOperation } from 'aws-amplify';
import Fade from 'react-reveal/Fade';
import { petitionsByOwner } from '../../graphql/queries';
import PetitionList from '../PetitionListComponents';
import { AuthContext } from '../../services/auth.service';
import { FormattedMessage } from 'react-intl';

const MyPetitionList = (props: RouteComponentProps) => {
  const authFromContext: any = React.useContext(AuthContext);

  type PetitionInfo = {
    id: string;
    title: string;
    target: string;
    body: string;
    imageUrl: string;
    actionCounter: number | null;
    publishedAt: string | null;
    createdAt: string | null;
    moderationStatus: string | null;
  };

  const [petitions, setPetitions] = useState<PetitionInfo[]>([]);
  const [fetchPetitionList, setFetchPetitionList] = useState<{
    loading: boolean;
    error: boolean;
  }>({ loading: false, error: false });

  const fetchPetitions = async () => {
    // eslint-disable-next-line no-return-await
    return await API.graphql(graphqlOperation(petitionsByOwner, { limit: 30 }));
  };

  useEffect(() => {
    setFetchPetitionList({ loading: true, error: false });
    fetchPetitions()
      .then((r: any) => {
        setPetitions(r.data.petitionsByOwner.items);
        setFetchPetitionList({ loading: false, error: false });
        console.log(r.data.petitionsByOwner.items);
      })
      .catch((error) => {
        setPetitions([]);
        setFetchPetitionList({ loading: false, error: true });
        ReactGA.event({
          category: 'Error',
          action: 'Error displayed',
          label: `Fetch petition list error`,
        });
        console.log('error', error);
      });
  }, []);

  return (
    <>
      {authFromContext !== undefined ? (
        <div
          style={{ margin: '30px 20px', marginTop: '150px', minHeight: '80vh' }}
        >
          <Fade up>
            <h2>
              <FormattedMessage
                id="label.petitions"
                defaultMessage="Petitions"
              />
            </h2>
          </Fade>
          {fetchPetitionList.loading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '26px',
                height: '80vh',
              }}
            >
              <FormattedMessage
                id="label.loading"
                defaultMessage="loading..."
              />
            </div>
          )}
          {!fetchPetitionList.loading &&
            petitions &&
            petitions.length > 0 &&
            petitions.map((petition) => {
              // eslint-disable-next-line react/jsx-props-no-spreading
              return <PetitionList {...petition} key={petition.id} />;
            })}
          {!fetchPetitionList.loading &&
            !fetchPetitionList.error &&
            petitions.length === 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '26px',
                  height: '80vh',
                }}
              >
                <FormattedMessage
                  id="label.noPetitionsSoFar"
                  defaultMessage="You haven't created any petition so far,"
                />
                <span onClick={() => navigate('/u/new/')} className="startLink">
                  <FormattedMessage
                    id="label.noPetitionsSoFarStart"
                    defaultMessage=" start "
                  />
                </span>
                <FormattedMessage
                  id="label.noPetitionsSoFarCreateNow"
                  defaultMessage="creating now."
                />
              </div>
            )}
          {!fetchPetitionList.loading && fetchPetitionList.error && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '26px',
                height: '80vh',
              }}
            >
              <FormattedMessage
                id="label.fetchPetitionFail"
                defaultMessage="Unable to fetch the list at the moment, please try again later."
              />
            </div>
          )}
        </div>
      ) : (
        <Redirect to="/" noThrow />
      )}
    </>
  );
};

export default MyPetitionList;
