import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { FormattedMessage } from 'react-intl';
import Colors from '../../globals/Colors';
import { mobile } from '../../globals/Devices';

export interface Props {
  value: string;
  current: boolean;
  onClick: any;
}

const stroke = keyframes`
  100% { stroke-dashoffset: 0; }
`;

const fill = keyframes`
  100% {
    box-shadow: inset 0px 0px 0px 30px ${Colors.robinsEggBlue};
  }
`;

const scale = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
`;

const SVGCircle = styled.svg`
  width: 56px;
  height: 56px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: ${Colors.white};
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px ${Colors.robinsEggBlue};
  box-shadow: inset 0px 0px 0px 30px ${Colors.robinsEggBlue};
  /* animation: ${fill} 0.4s ease-in-out 0.4s forwards,
    ${scale} 0.3s ease-in-out 0.9s both; */
`;

const Circle = styled.circle`
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: ${Colors.robinsEggBlue};
  fill: none;
  stroke-dashoffset: 0;
`;
const Tick = styled.path`
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: ${stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.4s forwards;
`;

const StepText = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${Colors.blueWhale};
  margin: 12px 0 0;
`;

const StepView = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepCount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const Hr = styled.div`
  width: 100px;
  height: 2px;
  border-radius: 5px;
  background: linear-gradient(
    to right,
    rgba(0, 192, 207, 0.6),
    rgb(0, 192, 207)
  );
  margin: 0 10px;
  @media ${mobile} {
    width: 0;
  }
`;

const TickedStep = (props: Props) => {
  const { value, onClick } = props;
  return (
    <StepView>
      <StepCount>
        <SVGCircle
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 52 52"
          onClick={onClick}
        >
          <Circle cx="26" cy="26" r="25" fill="none" />
          <Tick fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </SVGCircle>
        <Hr />
      </StepCount>
      <StepText>
        <FormattedMessage id="label.step" defaultMessage="Step" />
        {value}
      </StepText>
    </StepView>
  );
};

export default TickedStep;
