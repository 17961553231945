import React from 'react';
import styled from 'styled-components';
import Colors from '../../globals/Colors';
import FooterLogo from './FooterLogo';
import NavItems from './NavItems';
import SocialMediaLinks from './SocialMediaLinks';
import { mobile } from '../../globals/Devices';

const Wrapper = styled.footer`
  height: 160px;
  width: 100vw;
  padding: 0 30px;
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  background: ${Colors.blueWhale};
  color: ${Colors.white};
  flex-direction: row;
  a {
    color: ${Colors.white};
    padding: 0 10px;
    text-decoration: none;
    &:hover {
      color: ${Colors.robinsEggBlue};
    }
  }
  @media ${mobile} {
    height: 260px;
    flex-direction: column;
    justify-content: center;
    border-top: 0.3px solid ${Colors.lightGrey};
  }
`;

const MobileColumn1 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const MobileColumn2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Footer = () => {
  const isMobile = window.innerWidth < 600;
  return (
    <Wrapper>
      {isMobile ? (
        <>
          <MobileColumn1>
            <FooterLogo />
            <SocialMediaLinks />
          </MobileColumn1>
          <MobileColumn2>
            <NavItems />
          </MobileColumn2>
        </>
      ) : (
        <>
          <FooterLogo />
          <NavItems />
          <SocialMediaLinks />
        </>
      )}
    </Wrapper>
  );
};

export default Footer;
