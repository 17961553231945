import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConfirmAccountValidationSchema } from './helpers/ValidationSchema';
import { Wrapper, Margin, Links } from './helpers/AuthStyles';
import Input from '../Input';
import Button from '../Button';

interface ConfirmAccountProps {
  changeAuthState: any;
  setBannerDetails: any;
  autoFillData: { email: string; password: string };
}
interface ConfirmAccountFormValues {
  email: string;
  code: string;
}

const ConfirmAccount = (props: ConfirmAccountProps) => {
  const { changeAuthState, setBannerDetails, autoFillData } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const { formatMessage: fm } = useIntl();

  const initialValues: ConfirmAccountFormValues = {
    email: autoFillData.email,
    code: '',
  };

  function resendCode() {
    setBannerDetails({ msg: '', type: 'error', show: false });
    Auth.resendSignUp(autoFillData.email)
      .then((resp: any) => {
        setBannerDetails({
          msg: fm({
            id: 'msg.codeSentSuccess',
            defaultMessage: 'Code sent successfully',
          }),
          type: 'success',
          show: true,
        });
      })
      .catch((err: any) => {
        console.log(err, err.code);
        setBannerDetails({
          msg:
            err && err.code === 'LimitExceededException'
              ? fm({
                  id: 'msg.codeLimitReached',
                  defaultMessage:
                    'Resend code limit reached, please try again later',
                })
              : fm({
                  id: 'msg.codeSendFail',
                  defaultMessage: 'Code send failure, please try again later',
                }),
          type: 'error',
          show: true,
        });
      });
  }

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ConfirmAccountValidationSchema,
    onSubmit: (values: ConfirmAccountFormValues) => {
      setLoading(true);
      setBannerDetails({ msg: '', type: 'error', show: false });
      Auth.confirmSignUp(values.email, values.code)
        .then(() => {
          console.log('account verified');
          setBannerDetails({
            msg: fm({
              id: 'msg.accVerified',
              defaultMessage: 'Account successfully verified, Signing in ...',
            }),
            type: 'success',
            show: true,
          });
          Auth.signIn({
            username: autoFillData.email,
            password: autoFillData.password,
          })
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
        })
        .catch((err: any) => {
          setLoading(false);
          setBannerDetails({
            msg: fm({
              id: 'msg.accVerifyFailed',
              defaultMessage: 'Account verification failed',
            }),
            type: 'error',
            show: true,
          });
        });
    },
  });

  return (
    <Wrapper>
      <div className="title">
        <FormattedMessage
          id="label.confirmAcc"
          defaultMessage="Confirm your account"
        />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          required
          disabled
          id="email"
          name="email"
          type="text"
          placeholder={fm({
            id: 'label.email',
            defaultMessage: 'Email',
          })}
          onChange={formik.handleChange}
          value={formik.values.email}
          error={
            formik.errors.email ? fm({ id: `${formik.errors.email}` }) : ''
          }
        />
        <Margin />
        <Input
          autoFocus
          required
          id="code"
          name="code"
          type="password"
          placeholder={fm({
            id: 'label.verificationCode',
            defaultMessage: 'Verification Code',
          })}
          onChange={formik.handleChange}
          value={formik.values.code}
          error={formik.errors.code ? fm({ id: `${formik.errors.code}` }) : ''}
        />
        <Margin />
        <div
          className="link"
          onClick={() => {
            resendCode();
          }}
          onKeyPress={() => console.log('resend clicked')}
          role="button"
          tabIndex={0}
        >
          <FormattedMessage
            id="label.lostCode"
            defaultMessage="Lost your code?"
          />
          <span>
            <FormattedMessage id="label.resend" defaultMessage="Resend" />
          </span>
        </div>
        <Button
          type="submit"
          disabled={loading}
          onClick={() => {
            formik.validateForm().then((errors: {}) => {
              console.log(Object.keys(errors));
            });
          }}
        >
          {loading
            ? fm({
                id: 'msg.verifyingAcc',
                defaultMessage: 'Verifying account..',
              })
            : fm({
                id: 'label.confirm',
                defaultMessage: 'Confirm',
              })}
        </Button>
      </form>
      <Links>
        <div
          onClick={() => changeAuthState('sign-in')}
          onKeyPress={() => console.log('sign-in')}
          role="button"
          tabIndex={0}
        >
          <FormattedMessage
            id="label.backToSignIn"
            defaultMessage="Back to Sign In"
          />
        </div>
      </Links>
    </Wrapper>
  );
};

export default ConfirmAccount;
