import React from 'react';
import styled from 'styled-components';
import { mobile } from '../../globals/Devices';
import { FormattedMessage } from 'react-intl';

const NavWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 0.5;
  div {
    display: flex;
    justify-content: center;
  }
  @media ${mobile} {
    div {
      margin-top: 15px;
    }
  }
`;

const NavItem = styled.div`
  font-size: 14px;
`;

const Copyright = styled.div`
  position: relative;
  top: 35px;
  font-size: 14px;
  text-align: center;
  @media ${mobile} {
    width: 100vw;
    top: 0;
  }
`;

const NavItems = () => {
  const year = new Date().getFullYear();

  return (
    <NavWrapper>
      <NavItem>
        <a
          href="https://www.sumofus.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="label.home" defaultMessage="Home" />
        </a>
        <a
          href="https://www.sumofus.org/about"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="label.about" defaultMessage="About" />
        </a>
        <a
          href="https://www.sumofus.org/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="label.privacy" defaultMessage="Privacy" />
        </a>
        <a
          href="https://www.sumofus.org/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="label.contact" defaultMessage="Contact" />
        </a>
      </NavItem>
      <Copyright>
        {`© ${year} SumOfUs, Creative Commons Attribution 3.0 Unported License.`}
      </Copyright>
    </NavWrapper>
  );
};

export default NavItems;
