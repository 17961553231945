import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../Button';
import Input from '../Input';
import Instructions from '../Instructions';
import { Step2InstructionsList } from '../Constants';

type Step2Props = {
  formik: any;
  currentStep: number;
  handleNextStep: any;
};

const Wrapper = styled.div`
  min-height: calc(100vh - 350px);
`;

const ContinueButton = styled(Button)`
  float: right;
`;

const Step2: FunctionComponent<Step2Props> = ({
  formik,
  currentStep,
  handleNextStep,
}) => {
  const { formatMessage: fm } = useIntl();

  return currentStep !== 1 ? null : (
    <Wrapper>
      <h2>
        <FormattedMessage
          id="label.targetHeading"
          defaultMessage="Great! Who has the power to make this change?"
        />
      </h2>
      <p>
        <FormattedMessage
          id="label.targetDesc"
          defaultMessage="Choose the recipient of your petition. These are the corporations or leaders with power to solve your problem or take the action you're demanding."
        />
      </p>
      <div>
        <Input
          id="target"
          name="target"
          type="text"
          placeholder={fm({
            id: 'label.targetPlaceholder',
            defaultMessage:
              'Petition target ( e.g. Mark Zuckerberg, Facebook ) ',
          })}
          onChange={formik.handleChange}
          value={formik.values.target}
          error={formik.errors.target ? fm({ id: formik.errors.target }) : ''}
          maxLength={140}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <ContinueButton
          type="submit"
          onClick={handleNextStep}
          disabled={formik.errors.target || formik.values.target.length === 0}
        >
          <FormattedMessage id="label.continue" defaultMessage="Continue" />
        </ContinueButton>
      </div>
      <Instructions listOfIntructions={Step2InstructionsList} />
    </Wrapper>
  );
};

export default Step2;
