// @ts-nocheck

import * as React from 'react';
import Select from 'react-select';
import './Select.css';
import { FormikErrors } from 'formik';
import styled from 'styled-components';
import Colors from '../../globals/Colors';
import { FormattedMessage } from 'react-intl';

interface SelectOptions {
  value: string;
  label: string;
}

type SelectProps = {
  id: string;
  required: boolean;
  name: string;
  onFocus?: any;
  onBlur?: any;
  placeholder: string;
  value: SelectOptions;
  onChange: any;
  options: Array<SelectOptions>;
  error:
    | string
    | FormikErrors<any>
    | string[]
    | FormikErrors<any>[]
    | undefined;
};

const customStyles = {
  option: (provided: any, state: { isSelected: any; isFocussed: any }) => ({
    ...provided,
    color: Colors.black,
    background:
      state.isSelected || state.isFocussed
        ? Colors.robinsEggBlue
        : Colors.white,
  }),
  control: (provided: any, state: { isFocused: any; isSelected: any }) => ({
    ...provided,
    background:
      state.isFocused || state.isSelected ? Colors.white : Colors.whiteSmoke,
    borderRadius: 3,
    padding: '3.5px 9px 3.5px',
    borderColor:
      state.isFocused || state.isSelected
        ? Colors.robinsEggBlue
        : 'transparent',
    borderBottomWidth: '1px',
    boxShadow: state.isFocused || state.isSelected ? null : null,
    '&:hover': {
      borderColor:
        state.isFocused || state.isSelected
          ? Colors.robinsEggBlue
          : 'transparent',
      borderBottomWidth: state.isSelected ? '0' : '1px',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  }),
  indicatorSeparator: (provided: any) => ({
    ...provided,
    visibility: 'hidden',
  }),
  menu: (provided: any) => ({
    ...provided,
    margin: 0,
    marginTop: '-2px',
    padding: 0,
    borderRadius: 3,
    fontSize: '14px',
    border: `1px solid ${Colors.robinsEggBlue}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderTopWidth: '0px',
    position: 'relative',
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
  }),
};

const ErrorContainer = styled.div`
  text-align: left;
  color: ${Colors.red};
  font-size: 14px;
`;

const DropDown = (props: SelectProps) => {
  const {
    id,
    required,
    name,
    onFocus,
    onBlur,
    placeholder,
    value,
    onChange,
    options,
    error,
  } = props;
  // console.log(placeholder, error, value.value);
  //@ts-ignore
  return (
    <>
      <Select
        openOnFocus
        isSearchable
        id={id}
        required={required}
        name={name}
        onFocus={onFocus}
        onBlur={onBlur}
        styles={customStyles}
        placeholder={placeholder}
        value={value.value ? value : null}
        onChange={onChange}
        options={options}
        error={error}
      />
      {error && value.value === '' && (
        <ErrorContainer>
          <FormattedMessage
            id="label.countryRequired"
            defaultMessage="Country is required"
          />
        </ErrorContainer>
      )}
    </>
  );
};

export default DropDown;
