/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import Button from '../Button';
import Colors from '../../globals/Colors';
import { ActionablePetitionStatuses } from '../Constants';

type PetitionInfo = {
  id: string;
  title: string;
  target: string;
  body: string;
  imageUrl: string;
  actionCounter: number | null;
  publishedAt: string | null;
  moderationStatus: string;
  user: { name: string | null; email: string | null };
};

interface TakeActionModalProps {
  modalData: { petition: PetitionInfo | null; open: boolean };
  setModalData: any;
  petitionListStatusType: string;
  savePetition: any;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: '50vh',
    maxWidth: '700px',
    minHeight: '400px',
    maxHeight: '500px',
  },
};
const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const Cross = styled.div`
  font-size: 20px;
  background-color: ${Colors.redOrange};
  color: white;
  padding: 2px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  border: 0.3px solid ${Colors.lightGray};
  font-weight: normal;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
`;

const ModalTitle = styled.h2``;
const ModalForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  max-width: 350px;
  min-height: 300px;

  button {
    margin: 10px 0;
  }
`;
const StatusSelect = styled.select`
  outline: 0;
  box-shadow: none;
  border: 0.5px solid ${Colors.darkGray};
  border-radius: 6px;
  background: ${Colors.white};
  background-image: none;
  height: 50px;
  padding: 0 10px;
  color: ${Colors.black};
  cursor: pointer;
  margin: 10px 0;
  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+)
    no-repeat 103% 50%;
  appearance: none;
`;

const StatusDesc = styled.textarea`
  resize: none;
  outline: 0;
  padding: 10px;
  margin: 10px 0;
  border: 0.5px solid ${Colors.darkGray};
  border-radius: 6px;
  display: none;
`;
const ActionButton = styled(Button)`
  height: 50px;
  width: 100%;
  max-width: 100%;
  margin: 5px 0;
`;

Modal.setAppElement('#root');

const TakeActionModal = (props: TakeActionModalProps) => {
  const {
    modalData,
    setModalData,
    petitionListStatusType,
    savePetition,
  } = props;

  const [statusSelected, setStatusSelected] = useState<string>('');
  const [statusDesc, setStatusDesc] = useState<string>('');

  useEffect(() => {
    if (!modalData.open) {
      setStatusSelected('');
    }
  }, [modalData]);

  return (
    <Modal
      isOpen={modalData.open}
      ariaHideApp={false}
      onRequestClose={() => setModalData(null, false)}
      contentLabel="Action Modal"
      closeTimeoutMS={200}
      style={customStyles}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <ModalContentWrapper>
        <Cross onClick={() => setModalData(null, false)}>X</Cross>
        <ModalTitle>Update Status</ModalTitle>
        <ModalForm>
          <StatusSelect
            id="status"
            name="status"
            value={statusSelected}
            onChange={(e: any) => {
              setStatusSelected(e.target.value);
            }}
          >
            <option value="" disabled defaultValue="">
              Select the status for the petition
            </option>
            {ActionablePetitionStatuses.map((status, indx) => {
              return petitionListStatusType !== status.value ? (
                <option key={indx} value={status.key}>
                  {status.value}
                </option>
              ) : null;
            })}
          </StatusSelect>
          {false && (
            <StatusDesc
              onChange={(e: any) => setStatusDesc(e.target.value)}
              placeholder="Comments if any"
              name="content"
              value={statusDesc}
              wrap=""
              rows={5}
              cols={50}
            />
          )}
          <ActionButton
            type="button"
            onClick={() => {
              savePetition(modalData.petition, statusSelected);
            }}
            disabled={statusSelected === ''}
          >
            SAVE
          </ActionButton>
        </ModalForm>
      </ModalContentWrapper>
    </Modal>
  );
};

export default TakeActionModal;
