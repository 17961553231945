export const getSignatureGoal = (currentSignatures: number) => {
  if (currentSignatures) {
    if (currentSignatures < 10) {
      console.log('less than 10');
      return Math.ceil((currentSignatures * 2) / 10) * 10;
    }
    if (currentSignatures < 100) {
      console.log('less than 100');
      return Math.ceil((currentSignatures * 1.6) / 100) * 100;
    }
    if (currentSignatures < 1000) {
      console.log('less than 1000');
      return Math.ceil((currentSignatures * 1.4) / 100) * 100;
    }
    if (currentSignatures < 100000) {
      console.log('less than 1 lakh');
      return Math.ceil((currentSignatures * 1.2) / 1000) * 1000;
    }
    console.log('greater than 1 lakh');
    return Math.ceil((currentSignatures * 1.02) / 10000) * 10000;
  }
  console.log('equals 0');
  return 10;
};

export const dummyFunc = () => {
  console.log('func template');
};
