import styled, { keyframes } from 'styled-components';
import Colors from '../../globals/Colors';

const spin = keyframes`
  to { -webkit-transform: rotate(360deg); }
`;

const Spinner = styled.div`
  display: block;
  width: 20px;
  height: 20px;
  margin: 0 10px;
  border: 3px solid ${Colors.redOrange};
  border-radius: 50%;
  border-top-color: ${Colors.white};
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: ${spin} 800ms ease-in-out infinite;
`;

export default Spinner;
