import React from 'react';
import styled from 'styled-components';
import Colors from '../../globals/Colors';

interface ErrorBannerProps {
  msg: string;
  setShowBanner: any;
  type: 'success' | 'error';
}

interface StyleProps {
  bannerType: 'success' | 'error';
}

const Banner = styled.div<StyleProps>`
  position: fixed;
  width: 100vw;
  min-height: 60px;
  background: ${({ bannerType }) =>
    bannerType === 'success' ? Colors.green : Colors.red};
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${Colors.white};
  padding: 10px 30px;
`;

const Cross = styled.div<StyleProps>`
  font-size: 20px;
  background: ${({ bannerType }) =>
    bannerType === 'success' ? Colors.green : Colors.red};
  color: ${Colors.white};
  padding: 2px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  border: 0.3px solid ${Colors.lightGray};
  font-weight: normal;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
`;

const ErrorBanner = (props: ErrorBannerProps) => {
  const { msg, setShowBanner, type } = props;
  return (
    <Banner bannerType={type || 'success'}>
      {msg}
      <Cross
        onClick={() => setShowBanner(false)}
        bannerType={type || 'success'}
      >
        X
      </Cross>
    </Banner>
  );
};

export default ErrorBanner;
