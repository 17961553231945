/* eslint-disable import/prefer-default-export */
export const I18n = {
  en: {
    'label.login': 'Login',
    'label.signInLocal': 'Sign in to your account',
    'label.signInFB': 'Sign in with Facebook',
    'label.connectingFB': 'Connecting to Facebook...',
    'label.or': 'or',
    'label.email': 'Email',
    'label.password': 'Password',
    'label.signIn': 'Sign In',
    'label.signOut': 'Sign Out',
    'label.forgotPwd': 'Forgot Password',
    'label.signUp': 'Sign Up',
    'label.createAcc': 'Create your own account',
    'label.signUpFB': 'Sign up with Facebook',
    'label.name': 'Name',
    'label.backToSignIn': 'Back to Sign In',
    'label.resetPassword': 'Reset your password',
    'label.sendCode': 'Send Code',
    'label.verifyPwd': 'Please verify your account',
    'msg.noAccExists':
      'No account exists for user with {email}, Create one to login.',
    'msg.incorrectCreds': 'Incorrect username or password, please check again',
    'msg.tryAgainLater': 'Please try again later',
    'msg.signingIn': 'Signing in...',
    'msg.verificationCodeSent':
      'A verification code has been sent to {email}, please enter that code to verify your account',
    'msg.userExists':
      'User with email address {email} exists, try signing in instead',
    'msg.signingUp': 'Signing up...',
    'msg.codeSentSuccess': 'Code sent successfully',
    'msg.codeLimitReached': 'Resend code limit reached, please try again later',
    'msg.codeSendFail': 'Code send failure, please try again later',
    'msg.accVerified': 'Account successfully verified, Signing in ...',
    'msg.accVerifyFailed': 'Account verification failed',
    'label.confirmAcc': 'Confirm your account',
    'label.verificationCode': 'Verification Code',
    'label.lostCode': 'Lost your code?',
    'label.resend': 'Resend',
    'msg.verifyingAcc': 'Verifying account...',
    'label.confirm': 'Confirm',
    'msg.accNotVerified':
      'Account not verified for this user, please verify before changing the password',
    'msg.accNotExist': 'Account does not exist',
    'msg.sendingCode': 'Sending code...',
    'msg.pwdChangeSuccess': 'Password changed successfully',
    'msg.pwdChangeFailed': 'Password change failed',
    'label.newPwd': 'New Password',
    'msg.changingPwd': 'Changing password...',
    'label.changePwd': 'Change Password',
    'msg.emailNotValid': 'Email is not valid',
    'msg.emailRequired': 'Email is required',
    'msg.pwdRequired': 'Password is required',
    'msg.pwdMin8': 'Password must have minimum 8 characters',
    'msg.nameRequired': 'Name is required',
    'msg.rTokenRequired': "Don't forget to complete the recaptcha",
    'msg.nameMin3': 'Name must have minimum 3 characters',
    'msg.codeRequired': 'Verification Code is required',

    'label.landingText': 'The best way to predict the future is to create it',
    'label.startPetition': 'Start a petition',
    'label.myPetitions': 'My petitions',
    'label.admin': 'Admin Panel',

    'label.step': 'Step ',
    'label.petitionTitle': 'Petition Title',
    'label.titleHeading':
      'This is the first thing people will see about your petition. Get their attention with a short title that focuses on the change you’d like them to support.',
    'label.titlePlaceholder': 'What do you want to achieve?',
    'label.continue': 'Continue',
    'label.step1.instHeading1': 'Keep it short and to the point',
    'label.step1.instDesc1':
      'Example: "Make Covid19 vaccines available for all"',
    'label.step1.instHeading2': 'Focus on the solution',
    'label.step1.instDesc2':
      'Example: "Pay your employees the minimum £15 an hour"',
    'label.step1.instHeading3': 'Communicate urgency',
    'label.step1.instDesc3':
      'Example: "Approve life-saving medication for my daughter, before it’s too late"',
    'label.titleRequired': 'Title Is Required',
    'label.titleBlank': 'Title cant be blank',

    'label.targetHeading': 'Great! Who has the power to make this change?',
    'label.targetDesc':
      "Choose the recipient of your petition. These are the corporations or leaders with power to solve your problem or take the action you're demanding.",
    'label.targetPlaceholder':
      'Petition target ( e.g. Mark Zuckerberg, Facebook )',
    'label.targetRequired': 'Target Is Required',
    'label.targetBlank': 'Target cant be blank',
    'label.instructions': 'Instructions',
    'label.step2.instHeading1':
      'Many petitions have companies or businesses as targets',
    'label.step2.instDesc1':
      "Examples: “Amazon” or “Ben's Chicken Shop”. Feel free to also list the CEO or another company official.",
    'label.step2.instHeading2':
      'Politicians, legislatures, or government agencies are also important for creating change in corporate behaviour',
    'label.step2.instDesc2': 'Examples: Your local MP or the city council',
    'label.step2.instHeading3': 'Don’t overthink it',
    'label.step2.instDesc3':
      'You can add or change targets later. For now, just make your best guess and write the rest of your petition!',

    'label.contentHeading': 'Explain the problem you want to solve',
    'label.contentDesc':
      'People are more likely to support your petition if it’s clear why you care. Explain how this change will impact you, your family, or your community.',
    'label.contentPlaceholder':
      'Explain what needs to happen and who can make the change.',
    'label.contentLength1':
      'Describe what happened, why you are concerned, and what you want to happen now. The most successful petitions tend to be at least 3 paragraphs long — 1000 characters in length',
    'label.contentLength2':
      'Great — you’ve started writing your petition. We recommend adding another {remaining} more characters before you finish — keep going!',
    'label.contentLength3':
      'Great — your petition is currently {count} characters long — that’s within the description length of some of the most successful petitions!',
    'label.step3.instHeading1':
      'Describe the people involved and the problem they are facing',
    'label.contentRequired': 'A description is required',
    'label.step3.instDesc1':
      'Readers are most likely to take action when they understand who is affected.',
    'label.step3.instHeading2': 'Describe the solution',
    'label.step3.instDesc2':
      'Explain what needs to happen and who can make the change. Make it clear what happens if you win or lose.',
    'label.step3.instHeading3': 'Make it personal',
    'label.step3.instDesc3':
      'Readers are more likely to sign and support your petition if it’s clear why you care.',
    'label.step3.instHeading4': 'Respect others',
    'label.step3.instDesc4':
      "Don't bully, use hate speech, threaten violence or make things up.",

    'label.addPhoto': 'Add a photo',
    'label.fileRequired': 'A file is required',
    'label.fileDesc':
      'Petitions with a photo or video receive six times more signatures than those without. Include one that captures the emotion of your story.',
    'label.selectPhoto': 'Select a photo',
    'label.changePhoto': 'Change Photo',
    'label.save': 'Save',
    'label.uploadPhoto': 'Upload Photo',
    'label.uploading': 'Uploading {progress}%',
    'label.step4.instHeading1':
      'Choose a photo that captures the emotion of your petition',
    'label.step4.instDesc1': 'Photos of people or animals work well.',
    'label.step4.instHeading2':
      'Try to upload photos that are 1600 x 900 pixels or larger',
    'label.step4.instDesc2': 'Large photos look good on all screen sizes.',
    'label.step4.instHeading3': 'Keep it friendly for all audiences',
    'label.step4.instDesc3':
      "Make sure your photo doesn't include graphic violence or sexual content.",
    'label.photoNotUploaded':
      "Sorry! Your photo couldn't be uploaded at the moment. Please try again later.",
    'label.dragPhoto': 'Drag to reposition cover photo',

    'label.congrats': 'Congratulations',
    'label.saveUnable':
      'Unable to save the petition at the moment, please try again later',
    'label.petitionReady': 'Your petition is almost ready.',
    'label.petitionPopupContent':
      'You’re responsible for what you post on this platform. You should keep your campaigns and comments in line with our Community Guidelines and the law, and respect the intellectual property rights of others. It’s not something we want to do, but we can terminate your account for repeated or major offenses.',
    'label.petitionPopupTerm1': 'By creating a petition, you agree to all our',
    'label.petitionPopupTerm2': ' terms and conditions.',
    'label.createPetition': 'Create Petition',

    'label.edit': 'Edit',
    'label.publish': 'Publish',
    'label.confirmPublish': 'Confirm Publish',
    'label.publishFinalMsg':
      'Petition cannot be further edited or updated after getting published. Are you sure you want to proceed publishing the petition?',
    'label.cancel': 'Cancel',
    'label.startPetitionOwn': 'Start a petition of your own',
    'label.petitionStarterMsg':
      'This petition starter stood up and took action. Will you do the same?',
    'label.petitionStarterButton': 'Start a petition',
    'label.updateFailure':
      'Sorry! We were unable to update your petition. Please try again later.',
    'label.changeCoverPhoto': 'Change cover photo',
    'label.savePreview': 'Save & Preview',
    'label.saving': 'Saving...',

    'label.starterInfo':
      'SumOfUs member <bold>{owner}</bold> started this petition to <bold>{target}</bold>',
    'label.thermometer': " have signed. Let's get to {target}!",
    'label.fullName': 'Full Name',
    'label.country': 'Select your country',
    'label.countryProceed': 'Select a country to proceed',
    'label.postalCode': 'Postal Code',
    'label.phoneNumber': 'Phone Number',
    'label.signPetition': 'Sign this Petition',
    'label.souPrivacy1': 'SumOfUs will protect',
    'label.souPrivacy2': ' your privacy',
    'label.souPrivacy3':
      ', and keep you updated about this and similar campaigns.',
    'label.countryRequired': 'Country is required',
    'label.consentYes':
      'Yes! Tell me if this campaign wins, and how I can help other relevant campaigns.',
    'label.consentNo':
      'No. I do not want to hear about this campaign’s progress or other relevant campaigns.',
    'label.selectOption': 'Please select an option',
    'label.reset': 'Reset',
    'label.petitionNotAvailable':
      "The petition you're looking for isn't available.",
    'label.urlIncorrect':
      'Either the URL is incorrect, or it violated our Community Guidelines, and was removed.',
    'label.savingSign': 'Saving your signature...',
    'label.signFailure':
      'Sorry! We were unable to record your signature at the moment. Please try again later.',
    'label.checkEmailValidate':
      'Please check your e-mail to confirm your e-mail address and validate your signature',
    'label.ok': 'OK',
    'label.copiedClipBoard':
      'Link copied to clipboard. Paste it in your favorite app and spread your support for this petition.',
    'label.greatChoice': 'Great choice! Sharing leads to way more signatures.',
    'label.shareWithFriends':
      'Now go ahead and share it with your friends and family.',

    'label.reportOptionHeading': 'Report a policy violation',
    'label.reportHeading': 'Report abuse',
    'label.reportContent1':
      'Please report any content that may violate sumofus.org’s ',
    'label.reportContent2': 'Terms of Service or Community Guidelines',
    'label.reportContent3':
      'Please explain why you are reporting this petition and include any information that might assist us in reviewing this content.',
    'label.submitting': 'Submitting...',
    'label.submit': 'Submit',
    'label.reportSuccess': 'Reported Successfully!!',
    'label.reportFailure': 'Failed to Report, please try again later',
    'label.reportContent4':
      'Flagged petitions are reviewed by sumofus.org staff to determine if they violate our Terms of Service or Community Guidelines.',
    'label.reportReasonDislike': 'I dislike or disagree with this petition',
    'label.reportReasonMisleading': 'Misleading or spam',
    'label.reportReasonInfringement': 'Infringes on my rights',
    'label.reportReasonHateful': 'Abusive or hateful',
    'label.reportReasonInappropriate': 'Inappropriate images',
    'label.reportReasonHarmful': 'Harmful to children',
    'label.reportReasonViolence': 'Violence, suicide, or self harm',
    'label.reportReasonImpersonation': 'Impersonation',

    'label.downloadSignContent1': 'Download Signatures',
    'label.downloadSignContent2': 'No signatures recorded',
    'label.downloadSignContent3': 'Check email for download link',
    'label.downloadSignContent4':
      'Failed to generate link, please try again later',
    'label.downloadSignContent5': 'Generating link...',

    'label.petitions': 'Petitions',
    'label.supporters': ' supporters',
    'label.loading': 'loading...',
    'label.noPetitionsSoFar': "You haven't created any petition so far,",
    'label.noPetitionsSoFarStart': ' start ',
    'label.noPetitionsSoFarCreateNow': 'creating now.',
    'label.fetchPetitionFail':
      'Unable to fetch the list at the moment, please try again later.',

    'label.endOfRoad': 'Uh-oh! This is the end of the road.',
    'label.goBackHome': 'Go Back Home',
    'label.pageNotFound':
      'This page was not found. You may have mistyped the address or the page may have moved.',

    'label.home': 'Home',
    'label.about': 'About',
    'label.privacy': 'Privacy',
    'label.contact': 'Contact',

    'label.signThePetition': 'Sign the petition - {title}',
    'label.emailContent1':
      "Dear friends, \n\nI just signed the petition {title} and wanted to ask if you could add your name too.\n\nThis campaign means a lot to me and the more support we can get behind it, the better chance we have of succeeding. You can read more and sign the petition here:\n\n{url}?source=mlt\n\nThank you!\n\nP.S. Can you also take a moment to share the petition with others? It's really easy – all you need to do is forward this email or share this link on Facebook or Twitter: {url}?source=mlt",
    'label.emailContent2':
      "Dear friends, \n\nI just created the petition {title} and wanted to ask if you could add your name too.\n\nThis campaign means a lot to me and the more support we can get behind it, the better chance we have of succeeding. You can read more and sign the petition here:\n\n{url}?source=mlt\n\nThank you!\n\nP.S. Can you also take a moment to share the petition with others? It's really easy – all you need to do is forward this email or share this link on Facebook or Twitter: {url}?source=mlt",
    'label.confirmClose': 'Close Petition',
    'label.confirmCloseMsg': 'Are you sure you want to close this petition?',
    'msg.noReply':"This inbox is not monitored. For any support questions, please email us at info@sumofus.org so we can provide you with the assistance you need. Thank you for using our platform.",
    'msg.thankYouForPetition': "Thank you for setting up a petition on the SumOfUs community petition platform!",
    'label.clickToViewPetition': "Click here to view your petition to {target} titled '{title}'",
    'msg.thankYouForSigning': "Thank you for signing a petition on the SumOfUs community platform.",
    'label.clicktoShare': "Click here to share the petition to triple your impact!",
    'msg.downloadReady': "Your petition download is ready.",
    'label.clickToDownload': "Click here to access the file.",
  },
  es: {
    'label.login': 'Login',
    'label.signInLocal': 'Sign in to your account',
    'label.signInFB': 'Sign in with Facebook',
    'label.connectingFB': 'Connecting to Facebook...',
    'label.or': 'or',
    'label.email': 'Email',
    'label.password': 'Password',
    'label.signIn': 'Sign In',
    'label.signOut': 'Sign Out',
    'label.forgotPwd': 'Forgot Password',
    'label.signUp': 'Sign Up',
    'label.createAcc': 'Create your own account',
    'label.signUpFB': 'Sign up with Facebook',
    'label.name': 'Name',
    'label.backToSignIn': 'Back to Sign In',
    'label.resetPassword': 'Reset your password',
    'label.sendCode': 'Send Code',
    'label.verifyPwd': 'Please verify your account',
    'msg.noAccExists':
      'No account exists for user with {email}, Create one to login.',
    'msg.incorrectCreds': 'Incorrect username or password, please check again',
    'msg.tryAgainLater': 'Please try again later',
    'msg.signingIn': 'Signing in...',
    'msg.verificationCodeSent':
      'A verification code has been sent to {email}, please enter that code to verify your account',
    'msg.userExists':
      'User with email address {email} exists, try signing in instead',
    'msg.signingUp': 'Signing up...',
    'msg.codeSentSuccess': 'Code sent successfully',
    'msg.codeLimitReached': 'Resend code limit reached, please try again later',
    'msg.codeSendFail': 'Code send failure, please try again later',
    'msg.accVerified': 'Account successfully verified, Signing in ...',
    'msg.accVerifyFailed': 'Account verification failed',
    'label.confirmAcc': 'Confirm your account',
    'label.verificationCode': 'Verification Code',
    'label.lostCode': 'Lost your code?',
    'label.resend': 'Resend',
    'msg.verifyingAcc': 'Verifying account...',
    'label.confirm': 'Confirm',
    'msg.accNotVerified':
      'Account not verified for this user, please verify before changing the password',
    'msg.accNotExist': 'Account does not exist',
    'msg.sendingCode': 'Sending code...',
    'msg.pwdChangeSuccess': 'Password changed successfully',
    'msg.pwdChangeFailed': 'Password change failed',
    'label.newPwd': 'New Password',
    'msg.changingPwd': 'Changing password...',
    'label.changePwd': 'Change Password',
    'msg.emailNotValid': 'Email is not valid',
    'msg.emailRequired': 'Email is required',
    'msg.pwdRequired': 'Password is required',
    'msg.pwdMin8': 'Password must have minimum 8 characters',
    'msg.nameRequired': 'Name is required',
    'msg.rTokenRequired': "Don't forget to complete the recaptcha",    
    'msg.nameMin3': 'Name must have minimum 3 characters',
    'msg.codeRequired': 'Verification Code is required',

    'label.landingText':
      '$ The best way to predict the future is to create it $',
    'label.startPetition': '$ Start a Petition $',
    'label.myPetitions': '$ My Petitions $',
    'label.admin': 'Admin Panel',

    'label.step': 'Step ',
    'label.petitionTitle': 'Petition Title',
    'label.titleHeading':
      'This is the first thing people will see about your petition. Get their attention with a short title that focuses on the change you’d like them to support.',
    'label.titlePlaceholder': 'What do you want to achieve?',
    'label.continue': 'Continue',
    'label.step1.instHeading1': 'Keep it short and to the point',
    'label.step1.instDesc1':
      'Example: "Make Covid19 vaccines available for all"',
    'label.step1.instHeading2': 'Focus on the solution',
    'label.step1.instDesc2':
      'Example: "Pay your employees the minimum £15 an hour"',
    'label.step1.instHeading3': 'Communicate urgency',
    'label.step1.instDesc3':
      'Example: "Approve life-saving medication for my daughter, before it’s too late"',
    'label.titleRequired': 'Title Is Required',
    'label.titleBlank': 'Title cant be blank',

    'label.targetHeading': 'Great! Who has the power to make this change?',
    'label.targetDesc':
      "Choose the recipient of your petition. These are the corporations or leaders with power to solve your problem or take the action you're demanding.",
    'label.targetPlaceholder':
      'Petition target ( e.g. Mark Zuckerberg, Facebook )',
    'label.targetRequired': 'Target Is Required',
    'label.targetBlank': 'Target cant be blank',
    'label.instructions': 'Instructions',
    'label.step2.instHeading1':
      'Many petitions have companies or businesses as targets',
    'label.step2.instDesc1':
      "Examples: “Amazon” or “Ben's Chicken Shop”. Feel free to also list the CEO or another company official.",
    'label.step2.instHeading2':
      'Politicians, legislatures, or government agencies are also important for creating change in corporate behaviour',
    'label.step2.instDesc2': 'Examples: Your local MP or the city council',
    'label.step2.instHeading3': 'Don’t overthink it',
    'label.step2.instDesc3':
      'You can add or change targets later. For now, just make your best guess and write the rest of your petition!',

    'label.contentHeading': 'Explain the problem you want to solve',
    'label.contentDesc':
      'People are more likely to support your petition if it’s clear why you care. Explain how this change will impact you, your family, or your community.',
    'label.contentPlaceholder':
      'Explain what needs to happen and who can make the change.',
    'label.contentLength1':
      'Describe what happened, why you are concerned, and what you want to happen now. The most successful petitions tend to be at least 3 paragraphs long — 1000 characters in length',
    'label.contentLength2':
      'Great — you’ve started writing your petition. We recommend adding another {remaining} more characters before you finish — keep going!',
    'label.contentLength3':
      'Great — your petition is currently {count} characters long — that’s within the description length of some of the most successful petitions!',
    'label.step3.instHeading1':
      'Describe the people involved and the problem they are facing',
    'label.contentRequired': 'A description is required',
    'label.step3.instDesc1':
      'Readers are most likely to take action when they understand who is affected.',
    'label.step3.instHeading2': 'Describe the solution',
    'label.step3.instDesc2':
      'Explain what needs to happen and who can make the change. Make it clear what happens if you win or lose.',
    'label.step3.instHeading3': 'Make it personal',
    'label.step3.instDesc3':
      'Readers are more likely to sign and support your petition if it’s clear why you care.',
    'label.step3.instHeading4': 'Respect others',
    'label.step3.instDesc4':
      "Don't bully, use hate speech, threaten violence or make things up.",

    'label.addPhoto': 'Add a photo',
    'label.fileRequired': 'A file is required',
    'label.fileDesc':
      'Petitions with a photo or video receive six times more signatures than those without. Include one that captures the emotion of your story.',
    'label.selectPhoto': 'Select a photo',
    'label.changePhoto': 'Change Photo',
    'label.save': 'Save',
    'label.uploadPhoto': 'Upload Photo',
    'label.uploading': 'Uploading {progress}%',
    'label.step4.instHeading1':
      'Choose a photo that captures the emotion of your petition',
    'label.step4.instDesc1': 'Photos of people or animals work well.',
    'label.step4.instHeading2':
      'Try to upload photos that are 1600 x 900 pixels or larger',
    'label.step4.instDesc2': 'Large photos look good on all screen sizes.',
    'label.step4.instHeading3': 'Keep it friendly for all audiences',
    'label.step4.instDesc3':
      "Make sure your photo doesn't include graphic violence or sexual content.",
    'label.photoNotUploaded':
      "Sorry! Your photo couldn't be uploaded at the moment. Please try again later.",
    'label.dragPhoto': 'Drag to reposition cover photo',

    'label.congrats': 'Congratulations',
    'label.saveUnable':
      'Unable to save the petition at the moment, please try again later',
    'label.petitionReady': 'Your petition is almost ready.',
    'label.petitionPopupContent':
      'You’re responsible for what you post on this platform. You should keep your campaigns and comments in line with our Community Guidelines and the law, and respect the intellectual property rights of others. It’s not something we want to do, but we can terminate your account for repeated or major offenses.',
    'label.petitionPopupTerm1': 'By creating a petition, you agree to all our',
    'label.petitionPopupTerm2': ' terms and conditions.',
    'label.createPetition': 'Create Petition',

    'label.edit': 'Edit',
    'label.publish': 'Publish',
    'label.confirmPublish': 'Confirm Publish',
    'label.publishFinalMsg':
      'Petition cannot be further edited or updated after getting published. Are you sure you want to proceed publishing the petition?',
    'label.cancel': 'Cancel',
    'label.startPetitionOwn': 'Start a petition of your own',
    'label.petitionStarterMsg':
      'This petition starter stood up and took action. Will you do the same?',
    'label.petitionStarterButton': 'Start a petition',
    'label.updateFailure':
      'Sorry! We were unable to update your petition. Please try again later.',
    'label.changeCoverPhoto': 'Change cover photo',
    'label.savePreview': 'Save & Preview',
    'label.saving': 'Saving...',

    'label.starterInfo':
      'SumOfUs member <bold>{owner}</bold> started this petition to <bold>{target}</bold>',
    'label.thermometer': " have signed. Let's get to {target}!",
    'label.fullName': 'Full Name',
    'label.country': 'Select your country',
    'label.countryProceed': 'Select a country to proceed',
    'label.postalCode': 'Postal Code',
    'label.phoneNumber': 'Phone Number',
    'label.signPetition': 'Sign this Petition',
    'label.souPrivacy1': 'SumOfUs will protect',
    'label.souPrivacy2': ' your privacy',
    'label.souPrivacy3':
      ', and keep you updated about this and similar campaigns.',
    'label.countryRequired': 'Country is required',
    'label.consentYes':
      'Yes! Tell me if this campaign wins, and how I can help other relevant campaigns.',
    'label.consentNo':
      'No. I do not want to hear about this campaign’s progress or other relevant campaigns.',
    'label.selectOption': 'Please select an option',
    'label.reset': 'Reset',
    'label.petitionNotAvailable':
      "The petition you're looking for isn't available.",
    'label.urlIncorrect':
      'Either the URL is incorrect, or it violated our Community Guidelines, and was removed.',
    'label.savingSign': 'Saving your signature...',
    'label.signFailure':
      'Sorry! We were unable to record your signature at the moment. Please try again later.',
    'label.checkEmailValidate':
      'Please check your e-mail to confirm your e-mail address and validate your signature',
    'label.ok': 'OK',
    'label.copiedClipBoard':
      'Link copied to clipboard. Paste it in your favorite app and spread your support for this petition.',
    'label.greatChoice': 'Great choice! Sharing leads to way more signatures.',
    'label.shareWithFriends':
      'Now go ahead and share it with your friends and family.',

    'label.reportOptionHeading': 'Report a policy violation',
    'label.reportHeading': 'Report abuse',
    'label.reportContent1':
      'Please report any content that may violate sumofus.org’s ',
    'label.reportContent2': 'Terms of Service or Community Guidelines',
    'label.reportContent3':
      'Please explain why you are reporting this petition and include any information that might assist us in reviewing this content.',
    'label.submitting': 'Submitting...',
    'label.submit': 'Submit',
    'label.reportSuccess': 'Reported Successfully!!',
    'label.reportFailure': 'Failed to Report, please try again later',
    'label.reportContent4':
      'Flagged petitions are reviewed by sumofus.org staff to determine if they violate our Terms of Service or Community Guidelines.',
    'label.reportReasonDislike': 'I dislike or disagree with this petition',
    'label.reportReasonMisleading': 'Misleading or spam',
    'label.reportReasonInfringement': 'Infringes on my rights',
    'label.reportReasonHateful': 'Abusive or hateful',
    'label.reportReasonInappropriate': 'Inappropriate images',
    'label.reportReasonHarmful': 'Harmful to children',
    'label.reportReasonViolence': 'Violence, suicide, or self harm',
    'label.reportReasonImpersonation': 'Impersonation',

    'label.downloadSignContent1': 'Download Signatures',
    'label.downloadSignContent2': 'No signatures recorded',
    'label.downloadSignContent3': 'Check email for download link',
    'label.downloadSignContent4':
      'Failed to generate link, please try again later',
    'label.downloadSignContent5': 'Generating link...',

    'label.petitions': 'Petitions',
    'label.supporters': ' supporters',
    'label.loading': 'loading...',
    'label.noPetitionsSoFar': "You haven't created any petition so far,",
    'label.noPetitionsSoFarStart': ' start ',
    'label.noPetitionsSoFarCreateNow': 'creating now.',
    'label.fetchPetitionFail':
      'Unable to fetch the list at the moment, please try again later.',

    'label.endOfRoad': 'Uh-oh! This is the end of the road.',
    'label.goBackHome': 'Go Back Home',
    'label.pageNotFound':
      'This page was not found. You may have mistyped the address or the page may have moved.',

    'label.home': 'Inicio',
    'label.about': 'Acerca de nosotros',
    'label.privacy': 'Privacidad y Protección de Datos',
    'label.contact': 'Contacto',

    'label.signThePetition': 'Sign the petition - {title}',
    'label.emailContent1':
      "Dear friends, \n\nI just signed the petition {title} and wanted to ask if you could add your name too.\n\nThis campaign means a lot to me and the more support we can get behind it, the better chance we have of succeeding. You can read more and sign the petition here:\n\n{url}?source=mlt\n\nThank you!\n\nP.S. Can you also take a moment to share the petition with others? It's really easy – all you need to do is forward this email or share this link on Facebook or Twitter: {url}?source=mlt",
    'label.emailContent2':
      "Dear friends, \n\nI just created the petition {title} and wanted to ask if you could add your name too.\n\nThis campaign means a lot to me and the more support we can get behind it, the better chance we have of succeeding. You can read more and sign the petition here:\n\n{url}?source=mlt\n\nThank you!\n\nP.S. Can you also take a moment to share the petition with others? It's really easy – all you need to do is forward this email or share this link on Facebook or Twitter: {url}?source=mlt",
    'label.confirmClose': 'Close Petition',
    'label.confirmCloseMsg': 'Are you sure you want to close this petition?',
    'msg.noReply':"This inbox is not monitored. For any support questions, please email us at info@sumofus.org so we can provide you with the assistance you need. Thank you for using our platform.",
    'msg.thankYouForPetition': "Thank you for setting up a petition on the SumOfUs community petition platform!",
    'label.clickToViewPetition': "Click here to view your petition to {target} titled '{title}'",
    'msg.thankYouForSigning': "Thank you for signing a petition on the SumOfUs community platform.",
    'label.clicktoShare': "Click here to share the petition to triple your impact!",
    'msg.downloadReady': "Your petition download is ready.",
    'label.clickToDownload': "Click here to access the file.",
  },
  de: {
    'label.login': 'Anmelden',
    'label.signInLocal': 'Anmelden',
    'label.signInFB': 'Mit Facebook anmelden',
    'label.connectingFB': 'Mit Facebook verbinden...',
    'label.or': 'oder',
    'label.email': 'E-Mail',
    'label.password': 'Passwort',
    'label.signIn': 'Anmelden',
    'label.signOut': 'Abmelden',
    'label.forgotPwd': 'Passwort vergessen',
    'label.signUp': 'Registrieren',
    'label.createAcc': 'Legen Sie Ihr Konto an',
    'label.signUpFB': 'Mit Facebook registrieren',
    'label.name': 'Name',
    'label.backToSignIn': 'Zurück zur Anmeldung',
    'label.resetPassword': 'Passwort zurücksetzen',
    'label.sendCode': 'Code senden',
    'label.verifyPwd': 'Bitte bestätigen Sie Ihr Konto',
    'msg.noAccExists':
      'Es existiert kein Konto für den*die Benutzer*in {email}, Legen Sie ein Konto an, um sich anzumelden',
    'msg.incorrectCreds':
      'Benutzername oder Passwort sind inkorrekt, bitte überprüfen Sie Ihre Eingabe',
    'msg.tryAgainLater': 'Bitte versuchen Sie es später nochmals',
    'msg.signingIn': 'Anmeldung läuft...',
    'msg.verificationCodeSent':
      'Es existiert bereits ein Konto für den*die Benutzer*in mit der E-Mail-Adresse {email}, melden Sie sich bei diesem Konto an',
    'msg.userExists':
      'Es existiert bereits ein Konto für den/die Benutzer*in mit der E-Mail-Adresse {email}, melden Sie sich bei diesem Konto an',
    'msg.signingUp': 'Registrierung läuft...',
    'msg.codeSentSuccess': 'Code erfolgreich verschickt',
    'msg.codeLimitReached':
      'Die maximale Anzahl der möglichen Versuche ist erreicht, bitte versuchen Sie es später noch einmal',
    'msg.codeSendFail':
      'Code konnte nicht verschickt werden, bitte versuchen Sie es später noch einmal',
    'msg.accVerified': 'Konto verifiziert, Anmeldung läuft...',
    'msg.accVerifyFailed': 'Konto konnte nicht verifiziert werden',
    'label.confirmAcc': 'Bestätigen Sie Ihr Konto',
    'label.verificationCode': 'Bestätigungscode',
    'label.lostCode': 'Haben Sie Ihren Code verloren?',
    'label.resend': 'Noch einmal schicken',
    'msg.verifyingAcc': 'Konto wird bestätigt...',
    'label.confirm': 'Bestätigen',
    'msg.accNotVerified':
      'Konto konnte nicht verifiziert werden. Bitte verifizieren Sie Ihr Konto, bevor Sie das Passwort ändern.',
    'msg.accNotExist': 'Konto existiert nicht',
    'msg.sendingCode': 'Code wird verschickt...',
    'msg.pwdChangeSuccess': 'Passwort erfolgreich geändert',
    'msg.pwdChangeFailed': 'Passwort konnte nicht geändert werden',
    'label.newPwd': 'Neues Passwort',
    'msg.changingPwd': 'Passwort wird geändert...',
    'label.changePwd': 'Passwort ändern',
    'msg.emailNotValid': 'E-Mail-Adresse ist ungültig',
    'msg.emailRequired': 'E-Mail-Adresse erforderlich',
    'msg.pwdRequired': 'Passwort erforderlich',
    'msg.pwdMin8': 'Passwort muss mindestens 8 Zeichen lang sein',
    'msg.nameRequired': 'Name erforderlich',
    'msg.rTokenRequired': "Don't forget to complete the recaptcha",
    'msg.nameMin3': 'Name muss mindestens 3 Zeichen lang sein',
    'msg.codeRequired': 'Bestätigungscode erforderlich',

    'label.landingText':
      'Der beste Weg in eine gute Zukunft? Die Zukunft selbst gestalten!',
    'label.startPetition': 'Eine Petition starten',
    'label.myPetitions': 'Meine Petitionen',
    'label.admin': 'Admin-Panel',

    'label.step': 'Schritt ',
    'label.petitionTitle': 'Petitionstitel',
    'label.titleHeading':
      'Das ist das Erste, was Leute von Ihrer Petition sehen werden. Wecken Sie ihre Aufmerksamkeit mit einem kurzen Titel, der die von Ihnen angestrebte Veränderung hervorhebt.',
    'label.titlePlaceholder': 'Was wollen Sie erreichen?',
    'label.continue': 'Fortfahren',
    'label.step1.instHeading1': 'Schreiben Sie kurz und prägnant',
    'label.step1.instDesc1':
      'Beispiel: "Machen Sie eine Covid-19-Impfung für alle zugänglich"',
    'label.step1.instHeading2': 'Stellen Sie die Lösung in den Mittelpunkt',
    'label.step1.instDesc2':
      'Beispiel: "Zahlen Sie Ihren Angestellten einen Mindestlohn von 15 € pro Stunde"',
    'label.step1.instHeading3': 'Betonen Sie die Dringlichkeit',
    'label.step1.instDesc3':
      'Beispiel: "Genehmigen Sie die lebensrettenden Medikamente für meine Tochter, bevor es zu spät ist" ',
    'label.titleRequired': 'Titel erforderlich',
    'label.titleBlank': 'Feld "Titel" ist ein Pflichtfeld',

    'label.targetHeading': 'Großartig! Wer kann diese Veränderung vornehmen?',
    'label.targetDesc':
      'Wählen Sie die Adressat*innen Ihrer Petition. Das sind die Konzerne oder Entscheider*innen, die die Macht haben, die von Ihnen angestrebte Veränderung zu berwirken.',
    'label.targetPlaceholder':
      'Adressat*in der Petition (z.B. Mark Zuckerberg, Facebook)',
    'label.targetRequired': 'Adressat*in erforderlich',
    'label.targetBlank': 'Feld "Adressat*in" ist ein Pflichtfeld',
    'label.instructions': 'Anleitung',
    'label.step2.instHeading1':
      'Viele Petitionen haben Konzerne und Unternehmen als Adressaten',
    'label.step2.instDesc1':
      'Beispiele: "Amazon" oder "Bayer". Sie können auch Konzernchefs oder andere führende Mitarbeiter*innen angeben.',
    'label.step2.instHeading2':
      'Politiker*innen, Gesetzgeber*innen und Behörden sind ebenfalls wichtig, um Unternehmen zu einem anderen Verhalten zu bewegen.',
    'label.step2.instDesc2':
      'Beispiel: Ihr*e Wahlkreisabgeordnete oder Stadträt*in',
    'label.step2.instHeading3': 'Denken Sie nicht zu viel darüber nach',
    'label.step2.instDesc3':
      'Sie können die Adressat*innen Ihrer Petition später noch ändern oder ergänzen. Wählen Sie jetzt einfach nach bestem Wissen eine*n Adressat*in aus und schreiben Sie den Rest Ihrer Petition',

    'label.contentHeading': 'Erklären Sie das Problem, das Sie lösen wollen',
    'label.contentDesc':
      'Es ist wahrscheinlicher, dass Menschen Ihre Petition unterstützen, wenn klar ist, weshalb Sie sich für dieses Anliegen stark machen. Erklären Sie, inwiefern Sie oder Menschen, die Ihnen nahestehen, persönlich betroffen sind.',
    'label.contentPlaceholder':
      'Erklären Sie, welche Veränderung nötig ist und wer diese umsetzen kann',
    'label.contentLength1':
      'Erklären Sie was passiert ist, weshalb Sie das beunruhigt und was als nächstes passieren muss. Die erfolgreichsten Petitionen haben maximal 3 Absätze -- das sind ca. 1000 Zeichen.',
    'label.contentLength2':
      'Großartig -- Sie haben angefangen Ihre Petition zu verfassen. Wir empfehlen, dass Sie noch {remaining} weitere Zeichen schreiben. Machen Sie weiter so!',
    'label.contentLength3':
      'Großartig -- Ihre Petition ist momentan {count} Zeichen lang -- manche der erfolgreichsten Petitionen haben eine ähnliche Länge.',
    'label.step3.instHeading1':
      'Beschreiben Sie, welche Menschen betroffen sind und was ihr Problem ist ',
    'label.contentRequired': 'Eine Beschreibung ist erforderlich',
    'label.step3.instDesc1':
      'Es ist wahrscheinlicher, dass Menschen Ihre Petition unterschreiben, wenn sie wissen, wer die Betroffenen sind.',
    'label.step3.instHeading2': 'Beschreiben Sie die Lösung',
    'label.step3.instDesc2':
      'Beschreiben Sie, welche Veränderung nötig ist, und wer sie herbeiführen kann. Machen Sie deutlich was passiert, wenn Sie gewinnen bzw. verlieren.',
    'label.step3.instHeading3':
      'Heben Sie Ihre persönliche Betroffenheit hervor',
    'label.step3.instDesc3':
      'Es ist wahrscheinlicher, dass Menschen Ihre Petition unterstützen, wenn Sie verstehen, weshalb Sie sich für das Anliegen stark mache.',
    'label.step3.instHeading4': 'Seien Sie respektvoll',
    'label.step3.instDesc4':
      'Diskriminieren Sie niemanden, verwenden Sie keine Hassrede, drohen Sie nicht mit Gewalt und erfinden Sie keine Dinge.',

    'label.addPhoto': 'Foto hinzufügen',
    'label.fileRequired': 'Eine Datei ist erforderlich',
    'label.fileDesc':
      'Petitionen mit einem Foto oder Video erhalten sechs Mal so viele Unterschriften wie Petitionen ohne Foto oder Video. Wählen Sie ein Foto oder Video aus, das die Emotion Ihrer Geschichte einfängt.',
    'label.selectPhoto': 'Wählen Sie ein Foto aus',
    'label.changePhoto': 'Foto ändern',
    'label.save': 'Speichern',
    'label.uploadPhoto': 'Foto hochladen',
    'label.uploading': 'Hochladen zu {progress}% abgeschlossen',
    'label.step4.instHeading1':
      'Wählen Sie ein Foto aus, das die mit Ihrer Petition verbundenen Emotionen gut vermittelt',
    'label.step4.instDesc1':
      'Fotos von Menschen oder Tieren sind oft eine gute Wahl.',
    'label.step4.instHeading2':
      'Versuchen Sie, Fotos mit 1600 x 900 Pixel oder mehr hochzuladen',
    'label.step4.instDesc2':
      'Große Fotos sehen auf allen Bildschirmgrößen gut aus',
    'label.step4.instHeading3':
      'Achten Sie darauf, dass es für jedes Publikum geeignet ist',
    'label.step4.instDesc3':
      'Achten Sie darauf, dass Ihr Foto keine Gewalt oder Pornographie darstellt.',
    'label.photoNotUploaded':
      'Entschuldigung! Ihr Foto konnte nicht hochgeladen werden. Bitte versuchen Sie es später noch einmal.',
    'label.dragPhoto':
      'Durch Verschieben können Sie das Titelbild neu positionieren',

    'label.congrats': 'Gratulation!',
    'label.saveUnable':
      'Die Petition kann momentan nicht gespeichert werden, bitte versuchen Sie es später noch einmal',
    'label.petitionReady': 'Ihre Petition ist fast fertig.',
    'label.petitionPopupContent':
      'Sie sind dafür verantwortlich was Sie auf dieser Plattform posten. Achten Sie bitte darauf, dass Ihre Kampagne und Kommentare nicht gegen unsere Community-Richtlinien und die geltenden Gesetze verstoßen. Achten Sie bitte auch darauf, keine Urheberrechtsverstöße zu begehen. Wir machen das zwar nicht gerne, aber wir werden Ihr Konto bei wiederholten oder schwerwiegenden Verstößen löschen.',
    'label.petitionPopupTerm1':
      'Durch das Erstellen der Petition, akzeptieren Sie unsere',
    'label.petitionPopupTerm2': ' Nutzungsbedingungen.',
    'label.createPetition': 'Petition erstellen',

    'label.edit': 'Bearbeiten',
    'label.publish': 'Veröffentlichen',
    'label.confirmPublish': 'Veröffentlichung bestätigen',
    'label.publishFinalMsg':
      'Die Petition kann nach der Veröffentlichung nicht mehr bearbeitet werden. Sind Sie sicher, dass Sie sie veröffentlichen wollen?',
    'label.cancel': 'Abbrechen',
    'label.startPetitionOwn': 'Starten Sie Ihre eigene Petition',
    'label.petitionStarterMsg':
      'Diese Petition stößt eine wichtige Veränderung an. Willst du auch etwas verändern?',
    'label.petitionStarterButton': 'Eine Petition starten',
    'label.updateFailure':
      'Entschuldigung! Wir konnten Ihre Petition nicht updaten. Bitte versuchen Sie es später noch einmal.',
    'label.changeCoverPhoto': 'Titelbild ändern',
    'label.savePreview': 'Speichern & Vorschau anzeigen',
    'label.saving': 'Speichert...',

    'label.starterInfo':
      'SumOfUs-Mitglied <bold>{owner}</bold>, hat diese Petition an <bold>{target}</bold> gestartet',
    'label.thermometer':
      ' Personen haben schon unterschrieben. Wir schaffen {target} Unterschriften!',
    'label.fullName': 'Ganzer Name',
    'label.country': 'Wählen Sie Ihr Land aus',
    'label.countryProceed': 'Wählen Sie Ihr Land aus, um fortzufahren',
    'label.postalCode': 'Postleitzahl',
    'label.phoneNumber': 'Telefonnummer',
    'label.signPetition': 'Petition unterzeichnen',
    'label.souPrivacy1': 'SumOfUs schützt ',
    'label.souPrivacy2': ' Ihre Privatsphäre',
    'label.souPrivacy3': ', und wird Sie über ähnliche Kampagnen informieren.',
    'label.countryRequired': 'Land erforderlich',
    'label.consentYes':
      'Ja! Informieren Sie mich, wenn wir die Kampagne gewonnen haben und wie ich ähnliche Kampagnen unterstützen kann.',
    'label.consentNo':
      'Nein! Ich möchte keine weiteren Informationen über diese oder ähnliche Kampagnen.',
    'label.selectOption': 'Bitte wählen Sie eine Option aus',
    'label.reset': 'Zurücksetzen',
    'label.petitionNotAvailable':
      'Die Petition, die Sie suchen, ist nicht mehr verfügbar.',
    'label.urlIncorrect':
      'Entweder ist die URL inkorrekt, oder die Seite hat gegen unsere Nutzungsbedingungen verstoßen und wurde entfernt.',
    'label.savingSign': 'Ihre Unterschrift wird gespeichert...',
    'label.signFailure':
      'Entschuldigung! Wir können Ihre Unterschrift momentan nicht speichern. Bitte versuchen Sie es später noch einmal.',
    'label.checkEmailValidate':
      'Sie haben von uns eine E-Mail erhalten, mit der Sie Ihre Unterschrift bestätigen können',
    'label.ok': 'OK',
    'label.copiedClipBoard':
      'Link in der Zwischenablage gespeichert. Bitte kopieren und verbreiten Sie ihn und teilen Sie anderen Menschen mit, dass Sie diese Petition unterstützen.',
    'label.greatChoice':
      'Hervorragende Wahl! Durch das Teilen bekommt die Petition viel mehr Unterschriften',
    'label.shareWithFriends':
      'Teilen Sie die Petition jetzt mit Ihren Bekannten und Ihrer Familie',

    'label.reportOptionHeading': 'Verstoß gegen Nutzungsbedingungen melden',
    'label.reportHeading': 'Missbrauch melden',
    'label.reportContent1': 'Bitte melden Sie jeden Verstoß gegen die SumOfUs-',
    'label.reportContent2': 'Nutzungsbedingungen und Community-Richtlinien',
    'label.reportContent3':
      'Bitte sagen Sie uns, weshalb Sie diese Petition gemeldet haben und fügen Sie alle Informationen bei, die uns bei der Überprüfung der Petition helfen können.',
    'label.submitting': 'Wird eingereicht...',
    'label.submit': 'Einreichen',
    'label.reportSuccess': 'Erfolgreich gemeldet!',
    'label.reportFailure':
      'Meldung war nicht möglich, bitte probieren Sie es später noch einmal',
    'label.reportContent4':
      'Gemeldete Petitionen werden von SumOfUs-Mitarbeiter*innen überprüft, um festzustellen, ob sie gegen unsere Nutzungsbedingungen oder Community-Richtlinien verstoßen.',
    'label.reportReasonDislike':
      'Ich mag diese Petition nicht oder stimme nicht mir ihr überein',
    'label.reportReasonMisleading': 'Irreführend oder Spam',
    'label.reportReasonInfringement': 'Verstößt gegen meine Rechte',
    'label.reportReasonHateful': 'Beleidigend oder hasserfüllt',
    'label.reportReasonInappropriate': 'Unangemessene Bilder',
    'label.reportReasonHarmful': 'Schädlich für Kinder',
    'label.reportReasonViolence': 'Gewalt, Suizide oder Selbstverletzung',
    'label.reportReasonImpersonation': 'Identitätsdiebstahl',

    'label.downloadSignContent1': 'Unterschriften herunterladen',
    'label.downloadSignContent2': 'Keine Unterschriften gespeichert',
    'label.downloadSignContent3': 'E-Mail für Download-Link prüfen',
    'label.downloadSignContent4':
      'Link konnte nicht generiert werden, bitte versuchen Sie es später noch einmal',
    'label.downloadSignContent5': 'Link wird generiert...',

    'label.petitions': 'Petitionen',
    'label.supporters': ' Unterstützer*innen',
    'label.loading': 'Lädt...',
    'label.noPetitionsSoFar': 'Sie haben noch keine Petition erstellt',
    'label.noPetitionsSoFarStart': ' starten ',
    'label.noPetitionsSoFarCreateNow': 'jetzt erstellen.',
    'label.fetchPetitionFail':
      'Die Liste kann im Moment nicht abgerufen werden, bitte versuchen Sie es später noch einmal.',

    'label.endOfRoad': 'Oha ... hier geht es nicht weiter.',
    'label.goBackHome': 'Zurück zur Startseite',
    'label.pageNotFound':
      'Die Seite konnte nicht gefunden werden. Möglicherweise haben Sie Adresse falsch eingegeben oder die Seite befindet sich hier nicht mehr.',

    'label.home': 'Startseite',
    'label.about': 'Über uns',
    'label.privacy': 'Privatsphäre & Datenschutz',
    'label.contact': 'Kontakt (Impressum)',

    'label.signThePetition': 'Petition unterschreiben - {title}',
    'label.emailContent1':
      'Liebe Freund*innen, \n\nich habe eben die Petition {title} unterschrieben und wollte fragen, ob du das auch tun kannst.\n\nDiese Kampagne ist mir sehr wichtig und je mehr Menschen unterschreiben, desto größer ist unsere Chance auf Erfolg. Hier kannst du die Petition unterschreiben und mehr über sie erfahren:\n\n{url}?source=mlt\n\nVielen Dank!\n\nPS: Kannst du die Petition bitte auch mit anderen teilen? Es geht ganz schnell -- du musst nur diese E-Mail weiterleiten oder diesen Link auf Facebook oder Twitter teilen: {url}?source=mlt',
    'label.emailContent2':
      'Liebe Freund*innen, \n\nich habe die Petition gestartet {title} und wollte fragen, ob du sie unterschreiben möchtest.\n\nDiese Kampagne ist mir sehr wichtig und je mehr Menschen unterschreiben, desto größer ist unsere Chance auf Erfolg. Hier kannst du die Petition unterschreiben und mehr über sie erfahren:\n\n{url}?source=mlt\n\nVielen Dank!\n\nPS: Kannst du die Petition bitte auch mit anderen teilen? Es geht ganz schnell -- du musst nur diese E-Mail weiterleiten oder diesen Link auf Facebook oder Twitter teilen: {url}?source=mlt',
    'label.confirmClose': 'Close Petition',
    'label.confirmCloseMsg': 'Are you sure you want to close this petition?',
    'msg.noReply':"This inbox is not monitored. For any support questions, please email us at info@sumofus.org so we can provide you with the assistance you need. Thank you for using our platform.",
    'msg.thankYouForPetition': "Thank you for setting up a petition on the SumOfUs community petition platform!",
    'label.clickToViewPetition': "Click here to view your petition to {target} titled '{title}'",
    'msg.thankYouForSigning': "Thank you for signing a petition on the SumOfUs community platform.",
    'label.clicktoShare': "Click here to share the petition to triple your impact!",
    'msg.downloadReady': "Your petition download is ready.",
    'label.clickToDownload': "Click here to access the file.",
  },
  fr: {
    'label.login': 'Connexion',
    'label.signInLocal': 'Connectez-vous à votre compte',
    'label.signInFB': 'Connectez-vous avec Facebook',
    'label.connectingFB': 'Connexion à Facebook...',
    'label.or': 'ou',
    'label.email': 'Email',
    'label.password': 'Mot de passe',
    'label.signIn': "S'identifier",
    'label.signOut': 'Se déconnecter',
    'label.forgotPwd': 'Mot de passe oublié',
    'label.signUp': "S'inscrire",
    'label.createAcc': 'Créez votre compte',
    'label.signUpFB': "S'inscrire avec Facebook",
    'label.name': 'Nom',
    'label.backToSignIn': 'Retour à la page de connexion',
    'label.resetPassword': 'Réinitialisez votre mot de passe',
    'label.sendCode': 'Envoyer le code',
    'label.verifyPwd': 'Veuillez vérifier votre compte',
    'msg.noAccExists':
      "Il n'existe pas de compte pour l'utilisateur {email}, Créez un compte pour vous connecter.",
    'msg.incorrectCreds':
      "Nom d'utilisateur ou mot de passe incorrect, veuillez vérifier vos identifiants",
    'msg.tryAgainLater': 'Veuillez réessayer plus tard',
    'msg.signingIn': 'Authentification en cours...',
    'msg.verificationCodeSent':
      'Un code de vérification a été envoyé à {email}, veuillez entrer ce code pour vérifier votre compte',
    'msg.userExists':
      "L'email {email} a déjà un compte, essayez de vous connecter via cet email",
    'msg.signingUp': 'Inscription en cours...',
    'msg.codeSentSuccess': 'Code envoyé avec succès',
    'msg.codeLimitReached':
      'Le nombre de code renvoyé a été atteint, veuillez réessayer plus tard',
    'msg.codeSendFail':
      "Echec de l'envoi du code, veuillez réessayer plus tard",
    'msg.accVerified': 'Compte vérifié avec succès, Connexion en cours...',
    'msg.accVerifyFailed': 'Échec de la vérification du compte',
    'label.confirmAcc': 'Confirmez votre compte',
    'label.verificationCode': 'Code de vérification',
    'label.lostCode': 'Vous avez perdu votre code ?',
    'label.resend': 'Renvoyez',
    'msg.verifyingAcc': 'Compte en cours de vérification...',
    'label.confirm': 'Confirmez',
    'msg.accNotVerified':
      "Le compte pour cet utilisateur n'a pas été vérifié, veuillez le vérifier avant de changer le mot de passe",
    'msg.accNotExist': "Ce compte n'existe pas",
    'msg.sendingCode': 'Envoi du code en cours...',
    'msg.pwdChangeSuccess': 'Le mot de passe a été modifié avec succès',
    'msg.pwdChangeFailed': 'Le changement de mot de passe a échoué',
    'label.newPwd': 'Nouveau mot de passe',
    'msg.changingPwd': 'Changement du mot de passe en cours...',
    'label.changePwd': 'Changez votre mot de passe',
    'msg.emailNotValid': "L'adresse email n'est pas valide",
    'msg.emailRequired': 'Email obligatoire',
    'msg.pwdRequired': 'Mot de passe obligatoire',
    'msg.pwdMin8': 'Le mot de passe doit contenir au minimum 8 caractères',
    'msg.nameRequired': 'Le nom est obligatoire',
    'msg.rTokenRequired': "Don't forget to complete the recaptcha",    
    'msg.nameMin3': 'Name must have minimum 3 characters',
    'msg.codeRequired': 'Le code de vérification est requis',

    'label.landingText':
      "La meilleure façon de prédire l'avenir est de le créer",
    'label.startPetition': 'Lancer une pétition',
    'label.myPetitions': 'Mes pétitions',
    'label.admin': 'Admin',

    'label.step': 'Etape ',
    'label.petitionTitle': 'Titre de la pétition',
    'label.titleHeading':
      "C'est la première chose que les gens verront de votre pétition. Attirez leur attention avec un titre court qui met l'accent sur le changement que vous souhaitez qu'ils soutiennent.",
    'label.titlePlaceholder': 'Quel est votre objectif ?',
    'label.continue': 'Continuez',
    'label.step1.instHeading1': 'Soyez précis et bref',
    'label.step1.instDesc1':
      'Exemple : « Rendre les vaccins contre le Covid-19 accessibles à tous »',
    'label.step1.instHeading2': 'Insistez sur la solution',
    'label.step1.instDesc2':
      "Exemple : « Payez vos employés au minimum 15 € de l'heure »",
    'label.step1.instHeading3': "Mettez en avant l'urgence",
    'label.step1.instDesc3':
      "Exemple : « Autorisez un traitement vital pour ma fille, avant qu'il ne soit trop tard »",
    'label.titleRequired': 'Le titre est obligatoire',
    'label.titleBlank': 'La case « Titre » ne peut pas restée vide',

    'label.targetHeading': 'Super ! Qui a le pouvoir de créer ce changement ?',
    'label.targetDesc':
      "Choisissez le destinataire de votre pétition. Il s'agit des entreprises ou des dirigeants qui ont le pouvoir de résoudre votre problème ou de mettre en place les mesures que vous demandez.",
    'label.targetPlaceholder':
      'Cible de la pétition (exemple : Mark Zuckerberg, Facebook)',
    'label.targetRequired': 'La cible est obligatoire',
    'label.targetBlank': 'La case « Cible » ne peut pas restée vide',
    'label.instructions': 'Instructions',
    'label.step2.instHeading1':
      'De nombreuses pétitions ont pour cible des entreprises ou des commerces',
    'label.step2.instDesc1':
      "Exemples : « Amazon » ou « Ben's Chicken Shop ». N'hésitez pas à citer également le PDG ou un autre responsable de l'entreprise.",
    'label.step2.instHeading2':
      'Les politiciens, les corps législatifs ou les agences gouvernementales sont également importants pour changer le comportement des entreprises',
    'label.step2.instDesc2': 'Exemples : votre député ou le conseil municipal',
    'label.step2.instHeading3': 'Ne ne vous tracassez pas',
    'label.step2.instDesc3':
      "Vous pouvez ajouter ou modifier les objectifs plus tard. Pour l'instant, faites votre meilleur choix et rédigez le reste de votre pétition !",

    'label.contentHeading': 'Expliquez le problème que vous voulez résoudre',
    'label.contentDesc':
      'Les gens seront plus enclins à soutenir votre pétition si vous expliquez clairement pourquoi cela compte pour vous. Expliquez comment ce changement vous affectera, ainsi que votre famille ou votre communauté.',
    'label.contentPlaceholder':
      'Expliquez ce qui doit être fait et qui a le pouvoir de changer les choses.',
    'label.contentLength1':
      "Décrivez ce qui s'est passé, en quoi vous êtes concerné et ce que vous voulez voir changer maintenant. Les pétitions les plus réussies comportent en général au moins 3 paragraphes - 1000 caractères",
    'label.contentLength2':
      "Super - vous avez commencé à rédiger votre pétition. Nous vous recommandons d'ajouter {remaining} caractères supplémentaires avant de terminer - continuez !",
    'label.contentLength3':
      'Super - votre pétition compte actuellement {count} caractères - ça correspond à la longueur de texte de certaines des pétitions les plus réussies !',
    'label.step3.instHeading1':
      'Décrivez les personnes concernées et le problème auquel elles sont confrontées',
    'label.contentRequired': 'Une description est nécessaire',
    'label.step3.instDesc1':
      "Les lecteurs sont plus susceptibles de se mobiliser lorsqu'ils comprennent qui est touché par le problème.",
    'label.step3.instHeading2': 'Décrivez la solution',
    'label.step3.instDesc2':
      'Expliquez ce qui doit être fait et qui a le pouvoir de changer les choses. Expliquez clairement ce qui se passera si vous gagnez ou perdez.',
    'label.step3.instHeading3': 'Personnalisez votre texte',
    'label.step3.instDesc3':
      "Les lecteurs seront plus susceptibles de signer et de soutenir votre pétition s'ils comprennent pourquoi cela vous affecte",
    'label.step3.instHeading4': 'Respectez les autres',
    'label.step3.instDesc4':
      "N'harcelez pas, ne propagez pas de discours haineux, ne menacez pas, ne soyez pas violent et n'inventez pas des choses.",

    'label.addPhoto': 'Ajoutez une photo',
    'label.fileRequired': 'Un fichier est nécessaire',
    'label.fileDesc':
      "Les pétitions comportant une photo ou une vidéo récoltent six fois plus de signatures que celles qui n'en ont pas. Insérez une photo ou une vidéo qui transmet toute l'émotion de votre histoire.",
    'label.selectPhoto': 'Sélectionner une photo',
    'label.changePhoto': 'Changer la photo',
    'label.save': 'Sauvegarder',
    'label.uploadPhoto': 'Télécharger une photo',
    'label.uploading': 'Téléchargement en cours {progress}%',
    'label.step4.instHeading1':
      "Choisissez une photo qui transmette l'émotion contenue dans votre pétition",
    'label.step4.instDesc1':
      "Les photos de personnes ou d'animaux fonctionnent bien.",
    'label.step4.instHeading2':
      'Essayez de télécharger des photos de 1600 x 900 pixels ou plus',
    'label.step4.instDesc2':
      "Les photos grand format rendent bien sur toutes les tailles d'écran.",
    'label.step4.instHeading3':
      "Faites en sorte qu'elle soit adaptée à tout public",
    'label.step4.instDesc3':
      'Assurez-vous que votre photo ne comporte pas de violence explicite ou de contenu à caractère sexuel.',
    'label.photoNotUploaded':
      "Désolé ! Votre photo n'a pas pu être téléchargée pour le moment. Veuillez réessayer plus tard.",
    'label.dragPhoto':
      'Faites glisser pour repositionner la photo de couverture',

    'label.congrats': 'Félicitations',
    'label.saveUnable':
      'Impossible de sauvegarder la pétition pour le moment, veuillez réessayer plus tard',
    'label.petitionReady': 'Votre pétition est presque prête.',
    'label.petitionPopupContent':
      "Vous êtes responsable de ce que vous publiez sur cette plateforme. Vous devez veiller à ce que vos campagnes et vos commentaires soient conformes à nos conditions d'utilisation et à la loi, et respecter les droits de propriété intellectuelle d'autrui. Nous ne souhaitons pas en arriver là, mais nous pouvons résilier votre compte en cas d'infractions graves ou répétées.",
    'label.petitionPopupTerm1':
      'En créant une pétition, vous acceptez toutes nos',
    'label.petitionPopupTerm2': " conditions d'utilisation",
    'label.createPetition': 'Créer une pétition',

    'label.edit': 'Modifier',
    'label.publish': 'Publier',
    'label.confirmPublish': 'Confirmer la publication',
    'label.publishFinalMsg':
      'La pétition ne peut plus être modifiée ou mise à jour une fois publiée. Êtes-vous sûr de vouloir la publier ? ',
    'label.cancel': 'Annuler',
    'label.startPetitionOwn': 'Lancez votre propre pétition',
    'label.petitionStarterMsg':
      "Ce lanceur de pétition s'est mobilisé. Et vous ?",
    'label.petitionStarterButton': 'Lancer une pétition',
    'label.updateFailure':
      "Désolé ! Nous n'avons pas pu mettre à jour votre pétition. Veuillez réessayer plus tard.",
    'label.changeCoverPhoto': 'Changer la photo de couverture',
    'label.savePreview': 'Enregistrer et prévisualiser',
    'label.saving': 'En cours de sauvegarde...',

    'label.memberSOU': 'Membre de SumOfUs ',
    'label.petitionInfo': ' a lancé cette pétition à  ',
    'label.starterInfo':
      '<bold>{owner}</bold>, membre de SumOfUs, a lancé cette pétition à <bold>{target}</bold>',
    'label.thermometer':
      ' personnes ont signé. Atteignons les {target} signataires !',
    'label.fullName': 'Nom complet',
    'label.country': 'Sélectionnez votre pays',
    'label.countryProceed': 'Sélectionnez un pays pour continuer',
    'label.postalCode': 'Code postal',
    'label.phoneNumber': 'Numéro de téléphone',
    'label.signPetition': 'Signez cette pétition',
    'label.souPrivacy1': 'SumOfUs protégera',
    'label.souPrivacy2': ' votre vie privée ',
    'label.souPrivacy3':
      ", et vous tiendra informé sur la poursuite de cette campagne et d'autres campagnes similaires.",
    'label.countryRequired': 'Le pays est obligatoire',
    'label.consentYes':
      "Oui ! Dites-moi si cette campagne est victorieuse et comment je peux aider d'autres campagnes importantes.",
    'label.consentNo':
      "Non. Je ne veux pas entendre parler de l'évolution de cette campagne ou d'autres campagnes importantes.",
    'label.selectOption': 'Veuillez sélectionner une option',
    'label.reset': 'Réinitialiser',
    'label.petitionNotAvailable':
      "La pétition que vous recherchez n'est pas disponible.",
    'label.urlIncorrect':
      "Soit l'URL est incorrecte, soit elle a enfreint nos conditions d'utilisation et a été supprimée.",
    'label.savingSign': 'Sauvegarde de votre signature en cours...',
    'label.signFailure':
      "Désolé ! Nous n'avons pas pu enregistrer votre signature pour le moment. Veuillez réessayer plus tard.",
    'label.checkEmailValidate':
      'Veuillez vérifier votre boite mail pour confirmer votre adresse électronique et valider votre signature',
    'label.ok': 'OK',
    'label.copiedClipBoard':
      'Lien copié dans le presse-papier. Partagez-le via votre plateforme préférée pour montrer que vous soutenez cette pétition.',
    'label.greatChoice':
      "Excellent choix ! Partager permet d'obtenir beaucoup plus de signatures.",
    'label.shareWithFriends':
      'Maintenant partagez-la avec vos amis et votre famille.',

    'label.reportOptionHeading':
      "Signaler une violation de nos conditions d'utilisation",
    'label.reportHeading': 'Signaler un abus',
    'label.reportContent1':
      'Veuillez signaler tout contenu qui semble enfreindre les règles de sumofus.org ',
    'label.reportContent2': "Conditions d'utilisation",
    'label.reportContent3':
      "Veuillez expliquer pourquoi vous signalez cette pétition/ merci d'inclure toute information qui pourrait nous aider à examiner ce contenu.",
    'label.submitting': 'Envoi en cours...',
    'label.submit': 'Soumettre',
    'label.reportSuccess': 'Signalé avec succès !',
    'label.reportFailure': 'Echec du signalement, veuillez réessayer plus tard',
    'label.reportContent4':
      "Les pétitions signalées sont examinées par l'équipe sumofus.org afin de déterminer si elles enfreignent nos conditions d'utilisation",
    'label.reportReasonDislike':
      "Je n'aime pas ou je ne suis pas d'accord avec cette pétition",
    'label.reportReasonMisleading': 'Arnaque ou spam',
    'label.reportReasonInfringement': 'Porte atteinte à mes droits',
    'label.reportReasonHateful': 'Contenu abusif ou haineux',
    'label.reportReasonInappropriate': 'Photos inappropriées',
    'label.reportReasonHarmful': 'Nuisible pour les enfants',
    'label.reportReasonViolence': 'Violence, suicide ou automutilation',
    'label.reportReasonImpersonation': "Usurpation d'identité",

    'label.downloadSignContent1': 'Télécharger les signatures',
    'label.downloadSignContent2': 'Aucune signature enregistrée',
    'label.downloadSignContent3':
      'Vérifiez votre boite mail pour obtenir le lien de téléchargement',
    'label.downloadSignContent4':
      "Le lien n'a pas été généré, veuillez réessayer plus tard",
    'label.downloadSignContent5': 'lien en cours de création...',

    'label.petitions': 'Pétitions',
    'label.supporters': ' soutiens',
    'label.loading': 'chargement en cours...',
    'label.noPetitionsSoFar': "Vous n'avez pas encore créé de pétition,",
    'label.noPetitionsSoFarStart': ' commencer ',
    'label.noPetitionsSoFarCreateNow': 'composer.',
    'label.fetchPetitionFail':
      "Impossible d'afficher la liste pour le moment, veuillez réessayer plus tard.",

    'label.endOfRoad': "Oh oh... c'est une impasse",
    'label.goBackHome': "Revenir à la page d'accueil",
    'label.pageNotFound':
      "Cette page est introuvable. Vous avez pu faire une erreur en renseignant l'adresse de la page ou peut-être qu'elle n'est plus là",

    'label.home': 'Accueil',
    'label.about': 'À propos',
    'label.privacy': 'Confidentialité & protection des données',
    'label.contact': 'Contact',

    'label.signThePetition': 'Signer la pétition - {title}',
    'label.emailContent1':
      "Chers amis, \n\nJe viens de signer la pétition {title} et je voulais vous demander si vous pouviez ajouter votre nom aussi. \n\nCette campagne signifie beaucoup pour moi et plus nous serons nombreux à la soutenir, plus nous aurons de chances de réussir. Pour en savoir plus et signer la pétition, c'est ici:\n\n{url}?source=mlt\n\nMerci !\n\nP.S. Pouvez-vous également prendre un moment pour partager la pétition ? C'est très simple : il vous suffit de transférer cet e-mail ou de partager ce lien sur Facebook ou Twitter : {url}?source=mlt",
    'label.emailContent2':
      "Chers amis, \n\nJe viens de créer la pétition {title} et je voulais vous demander si vous pouviez ajouter votre nom aussi. \n\nCette campagne signifie beaucoup pour moi et plus nous serons nombreux à la soutenir, plus nous aurons de chances de réussir. Pour en savoir plus et signer la pétition, c'est ici:\n\n{url}?source=mlt\n\nMerci !\n\nP.S. Pouvez-vous également prendre un moment pour partager la pétition ? C'est très simple : il vous suffit de transférer cet e-mail ou de partager ce lien sur Facebook ou Twitter : {url}?source=mlt",
    'label.confirmClose': 'Close Petition',
    'label.confirmCloseMsg': 'Are you sure you want to close this petition?',
    'msg.noReply':"Cette boîte de réception n'est pas supervisée. Pour toute demande d'assistance, veuillez nous envoyer un courriel à info@sumofus.org afin que nous puissions vous fournir l'aide dont vous avez besoin. Merci d'utiliser notre plateforme.",
    'msg.thankYouForPetition': "Merci d'avoir lancé une pétition sur la plateforme de pétition de la communauté SumOfUs !",
    'label.clickToViewPetition': "Cliquez ici pour voir votre pétition adressée à {target} et intitulée '{title}'.",
    'msg.thankYouForSigning': "Merci d'avoir signé une pétition sur la plateforme de la communauté de SumOfUs.",
    'label.clicktoShare': "Cliquez ici pour partager la pétition afin de tripler votre impact !",
    'msg.downloadReady': "Votre téléchargement de la pétition est prêt.",
    'label.clickToDownload': "Cliquez ici pour accéder au fichier.",
  },
};
