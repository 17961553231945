import React from 'react';
import styled from 'styled-components';

const SocialMediaWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 0.25;
`;
const SocialMediaLinks = () => {
  return (
    <SocialMediaWrapper>
      <a
        href="https://www.facebook.com/SumOfUsOrg/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-facebook" aria-hidden="true" />
      </a>
      <a
        href="https://twitter.com/SumOfUs/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-twitter" aria-hidden="true" />
      </a>
      <a
        href="https://github.com/SumOfUs/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-github" aria-hidden="true" />
      </a>
      <a
        href="https://www.youtube.com/user/SumOfUsTube/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-youtube" aria-hidden="true" />
      </a>
      <a
        href="https://www.instagram.com/sumofus/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i className="fa fa-instagram" aria-hidden="true" />
      </a>
    </SocialMediaWrapper>
  );
};

export default SocialMediaLinks;
