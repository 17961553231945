/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState, useContext } from 'react';
import { ActivityIndicator, FlatList, View } from 'react-native-web';
import { RouteComponentProps, Redirect } from '@reach/router';
import { API, graphqlOperation } from 'aws-amplify';
import {
  petitionsByModerationStatus,
  flagsByDateGrouping,
} from '../../graphql/queries';
import { Wrapper, Heading, SearchInput, SortWrapper } from './ListingStyles';
import Colors from 'globals/Colors';
import { PetitionInfo } from './PetitionInterfaces';
import PetitionCard from './PetitionCard';
import { LangContext } from 'services/lang.service';

const DashboardListing = (props: RouteComponentProps) => {
  const isAdmin: boolean =
    sessionStorage.getItem('isAdmin') === 'true' || false;

  const petitionListStatusType = window.location.pathname
    .replace('/dashboard/', '')
    .replace('/', '');

  const langContext: any = useContext(LangContext);
  const { lang } = langContext;

  const [petitions, setPetitions] = useState<PetitionInfo[]>([]);
  const [nextToken, setNextToken] = useState<string | null>(null);
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortingType, setSortingType] = useState<string>('ASC');

  const [fetchPetitionList, setFetchPetitionList] = useState<{
    loading: boolean;
    error: boolean;
  }>({ loading: true, error: false });

  const flatlistRef = React.useRef(null);

  const fetchPetitions = async () => {
    const params = {
      limit: 30,
      moderationStatus: petitionListStatusType.toUpperCase(),
      sortDirection: sortingType,
      publishedAt: { beginsWith: lang ? lang : 'en' },
      nextToken,
    };
    if (petitions.length === 0) {
      //@ts-ignore
      delete(params.nextToken);
    }
    // eslint-disable-next-line no-return-await
    return await API.graphql(
      graphqlOperation(petitionsByModerationStatus, params)
    );
  };

  const fetchReportedPetitions = async () => {
    const params = {
      limit: 30,
      dateGrouping: 'LATEST',
      sortDirection: sortingType,
      nextToken,
    };
    if (petitions.length === 0) {
      //@ts-ignore
      if(params && params.nextToken) delete(params?.nextToken);
    }
    // eslint-disable-next-line no-return-await
    return await API.graphql(graphqlOperation(flagsByDateGrouping, params));
  };

  useEffect(() => {
    const parentDiv = document?.getElementById('parent');
    if (parentDiv) {
      parentDiv.style.maxWidth = 'unset';
    }
    return () => {
      if (parentDiv) {
        parentDiv.style.maxWidth = '1020px';
      }
    };
  }, []);

  function getPetitions() {
    setFetchPetitionList({ loading: true, error: false });
    fetchPetitions()
      .then((r: any) => {
        setPetitions([
          ...petitions,
          ...r.data.petitionsByModerationStatus.items,
        ]);
        setFetchPetitionList({ loading: false, error: false });
        setNextToken(r.data.petitionsByModerationStatus.nextToken);
      })
      .catch((error) => {
        setPetitions([]);
        setFetchPetitionList({ loading: false, error: true });
        console.log('error', error);
      });
  }

  function getReportedPetitions() {
    setFetchPetitionList({ loading: true, error: false });
    fetchReportedPetitions()
      .then((r: any) => {
        const localReports = r.data.flagsByDateGrouping.items;
        let localReportedPetitions: PetitionInfo[] = [];
        let localReportedPetitionIds: string[] = [];
        localReports.map((report: any) => {
          if (!localReportedPetitionIds.includes(report.petitionId)) {
            localReportedPetitions = [
              report.petition,
              ...localReportedPetitions,
            ];
            localReportedPetitionIds = [
              report.petitionId,
              ...localReportedPetitionIds,
            ];
          }
        });
        setPetitions([...petitions, ...localReportedPetitions]);
        setFetchPetitionList({ loading: false, error: false });
      })
      .catch((error) => {
        setPetitions([]);
        setFetchPetitionList({ loading: false, error: true });
      });
  }

  useEffect(() => {
    if (petitionListStatusType !== 'Reported') {
      getPetitions();
    } else {
      getReportedPetitions();
    }
  }, [sortingType]);

  // TODO can be used for local search
  // function isSearchTextMatched(petition: any) {
  //   if (
  //     searchValue === '' ||
  //     petition.title.includes(searchValue) ||
  //     petition.user.name.includes(searchValue) ||
  //     petition.target.includes(searchValue) ||
  //     petition.body.includes(searchValue)
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }

  return (
    <>
      {isAdmin ? (
        <View
          style={{
            height: '100vh',
            width: '100%',
          }}
        >
          <FlatList
            ref={flatlistRef}
            numColumns={3}
            ListHeaderComponent={() => (
              <Wrapper>
                <Heading>{petitionListStatusType} Petitions</Heading>
                <SearchInput
                  type="text"
                  value={searchValue}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
                <SortWrapper>
                  <div
                    className={
                      sortingType === 'ASC' ? 'oldest active' : 'oldest'
                    }
                    onClick={() => {
                      setPetitions([]);
                      setSortingType('ASC');
                    }}
                  >
                    Oldest first
                  </div>
                  <div
                    className={
                      sortingType === 'DESC' ? 'latest active' : 'latest'
                    }
                    onClick={() => {
                      setPetitions([]);
                      setSortingType('DESC');
                    }}
                  >
                    Latest first
                  </div>
                </SortWrapper>
              </Wrapper>
            )}
            keyExtractor={(item: PetitionInfo) => item.id}
            data={petitions}
            renderItem={(item: {
              index: any;
              item: PetitionInfo;
              separators: any;
            }) => (
              <PetitionCard
                petition={item.item}
                currentStatusType={petitionListStatusType}
              />
            )}
            columnWrapperStyle={{
              display: 'flex',
              justifyContent: 'center',
            }}
            onEndReachedThreshold={0.8}
            onEndReached={() => {
              if (nextToken === null) return;
              if (petitionListStatusType !== 'Reported') {
                getPetitions();
              } else {
                getReportedPetitions();
              }
            }}
            accessibilityLabel={'Petition List'}
            ListFooterComponent={() =>
              fetchPetitionList.loading ? (
                <ActivityIndicator
                  animating={true}
                  color={Colors.redOrange}
                  size="large"
                  style={{ marginTop: 15, marginBottom: 15, height: 30 }}
                />
              ) : (
                <View style={{ height: 30 }} />
              )
            }
            ListEmptyComponent={() =>
              !fetchPetitionList.loading && petitions.length === 0 ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: '26px',
                    height: '80vh',
                  }}
                >
                  {`No petitions found in ${petitionListStatusType.toUpperCase()} category`}
                </div>
              ) : (
                <View style={{ height: 30 }} />
              )
            }
          />
        </View>
      ) : (
        <Redirect to="/" noThrow />
      )}
    </>
  );
};

export default DashboardListing;
