import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, navigate } from '@reach/router';
import NotFoundImage from '../images/404.svg';
import Colors from 'globals/Colors';
import Button from 'components/Button';
import { FormattedMessage } from 'react-intl';

const NotFoundPage = (props: RouteComponentProps) => {
  return (
    <Wrapper>
      <Title>
        <FormattedMessage
          id="label.endOfRoad"
          defaultMessage="Uh-oh! This is the end of the road."
        />
      </Title>
      <Image src={NotFoundImage} alt="Not Found" />
      <HomeButton onClick={() => navigate('/')}>
        <FormattedMessage id="label.goBackHome" defaultMessage="Go Back Home" />
      </HomeButton>
      <Description>
        <FormattedMessage
          id="label.pageNotFound"
          defaultMessage="This page was not found. You may have mistyped the address or the page may have moved."
        />
      </Description>
    </Wrapper>
  );
};

export default NotFoundPage;

const Wrapper = styled.div`
  min-height: calc(100vh - 240px);
  margin-top: 100px;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h3``;

const Description = styled.div`
  font-weight: 500;
  span {
    cursor: pointer;
    color: ${Colors.robinsEggBlue};
  }
`;

const Image = styled.img`
  height: 600px;
  width: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
`;

const HomeButton = styled(Button)`
  color: ${Colors.redOrange};
  background: ${Colors.white};
  border-radius: 8px;
`;
