import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '../Button';
import Input from '../Input';
import Instructions from '../Instructions';
import { Step1InstructionsList } from '../Constants';

type Step1Props = {
  formik: any;
  currentStep: number;
  handleNextStep: any;
};

const Wrapper = styled.div`
  min-height: calc(100vh - 350px);
`;

const ContinueButton = styled(Button)`
  float: right;
`;

const Step1: FunctionComponent<Step1Props> = ({
  formik,
  currentStep,
  handleNextStep,
}) => {
  const { formatMessage: fm } = useIntl();

  return currentStep !== 0 ? null : (
    <Wrapper>
      <h2>
        <FormattedMessage
          id="label.petitionTitle"
          defaultMessage="Petition Title"
        />
      </h2>
      <p>
        <FormattedMessage
          id="label.titleHeading"
          defaultMessage="This is the first thing people will see about your petition. Get their attention with a short title that focuses on the change you’d like them to support."
        />
      </p>
      <div>
        <Input
          id="title"
          name="title"
          type="text"
          placeholder={fm({
            id: 'label.titlePlaceholder',
            defaultMessage: 'What do you want to achieve?',
          })}
          onChange={formik.handleChange}
          value={formik.values.title}
          error={formik.errors.title ? fm({ id: formik.errors.title }) : ''}
          maxLength={140}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <ContinueButton
          type="submit"
          onClick={handleNextStep}
          disabled={formik.errors.title || formik.values.title.length === 0}
        >
          <FormattedMessage id="label.continue" defaultMessage="Continue" />
        </ContinueButton>
      </div>
      <Instructions listOfIntructions={Step1InstructionsList} />
    </Wrapper>
  );
};

export default Step1;
