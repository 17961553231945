import styled from 'styled-components';
import * as themes from '../../themes';
import Colors from '../../globals/Colors';

export interface Props extends React.HTMLProps<HTMLButtonElement> {
  theme?: typeof themes;
  width?: number;
}

const Button = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: ${({ width }: Props) =>
    width && width > 200 ? `${width}px` : `200px`};
  width: 100%;
  border-radius: 4px;
  border: 2px solid ${Colors.redOrange};
  background-color: ${Colors.redOrange};
  color: ${Colors.white};
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  /* text-transform: uppercase; */
  height: 55px;
  cursor: pointer;
  margin-bottom: 16px;
  user-select: none;
  outline: none;
  &:disabled {
    border-radius: 4px;
    border: 2px solid ${Colors.lightGray};
    background-color: ${Colors.darkGray};
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.5;
    color: black;
  }
  margin: 20px 0;
`;

export default Button;
