import React from 'react';
import styled from 'styled-components';
import { navigate } from '@reach/router';
import SOULogo from '../../images/sumofus-white-logo.svg';

const LogoWrapper = styled.div`
  display: flex;
  flex: 0.25;
  justify-content: space-between;
  align-items: baseline;
`;

const SOUImage = styled.img`
  min-width: 96px;
  height: 86px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0;
`;

const FooterLogo = () => {
  return (
    <LogoWrapper>
      <SOUImage src={SOULogo} onClick={() => navigate('/')} />
    </LogoWrapper>
  );
};

export default FooterLogo;
