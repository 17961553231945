const Colors = {
  white: '#fff',
  black: '#000',
  robinsEggBlue: '#00c0cf',
  blueWhale: '#172340',
  redOrange: '#f8492d',
  lightGray: '#d0d0d0',
  darkGray: '#b2b2b2',
  lightGrey: '#d7d7d7',
  whiteSmoke: '#efefef',
  gainsboro: '#d8d8d8',
  shadyLady8: '#97979780',
  red1: '#ff00001a',
  red: '#ff0000',
  zircon3: '#f0f1f24d',
  zircon6: '#f0f1f299',
  eastBay1: '#4b546b1a',
  eastBay3: '#4b546b4d',
  suvaGrey2: '#8883',
  regalBlue: '#19284a',
  blueWhale7: '#172340bf',
  facebookBlue: '#3b5998',
  green: '#188038',
  goldenYellow: '#FFDF00',
  seaFogOpacited3: '#deded557',
};

export default Colors;
