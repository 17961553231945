import styled from 'styled-components';
import Colors from '../../globals/Colors';

const PetitionTitle = styled.h3`
  margin-bottom: 15px;
  font-size: 22px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 0.17px;
  color: ${Colors.blueWhale};
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    color: ${Colors.robinsEggBlue};
  }
`;

export default PetitionTitle;
