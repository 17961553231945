import * as React from 'react';
import styled from 'styled-components';
import stripHtml from 'string-strip-html';
import HTMLEllipsis from 'react-lines-ellipsis/lib/html';
import Colors from '../../globals/Colors';

const Wrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: 0.2px;
  color: ${Colors.blueWhale};
`;

// const Learn = styled.div`
//   padding-left: 5px;
//   font-size: 16px;
//   font-weight: 500;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: 0.2px;
//   color: ${Colors.redOrange};
//   text-decoration: underline;
// `;

type Props = {
  content: string;
};

export default function PetitionContent(props: Props) {
  const { content } = props;
  return (
    <Wrapper>
      <HTMLEllipsis
        unsafeHTML={stripHtml(content)}
        maxLine="5"
        basedOn="letters"
      />
    </Wrapper>
  );
}
