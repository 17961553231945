import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { ForgotPasswordValidationSchema } from './helpers/ValidationSchema';
import Input from '../Input';
import Button from '../Button';
import { Wrapper, Margin, Links } from './helpers/AuthStyles';

interface ForgotPasswordProps {
  changeAuthState: any;
  setBannerDetails: any;
  setAutoFillData: any;
}
interface ForgotPasswordFormValues {
  email: string;
}

const ForgotPassword = (props: ForgotPasswordProps) => {
  const { changeAuthState, setAutoFillData, setBannerDetails } = props;
  const [loading, setLoading] = useState<boolean>(false);

  const { formatMessage: fm } = useIntl();

  const initialValues: ForgotPasswordFormValues = {
    email: '',
  };

  const formik = useFormik({
    initialValues,
    validateOnMount: false,
    validateOnBlur: true,
    validateOnChange: true,
    validationSchema: ForgotPasswordValidationSchema,
    onSubmit: (values: ForgotPasswordFormValues) => {
      setLoading(true);
      Auth.forgotPassword(values.email)
        .then((resp: any) => {
          setAutoFillData({
            email: values.email,
            password: '',
          });
          changeAuthState('reset-password');
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
          if (err) {
            switch (err.code) {
              case 'InvalidParameterException':
                setBannerDetails({
                  msg: fm({
                    id: 'msg.accNotVerified',
                    defaultMessage:
                      'Account not verified for this user, please verify before changing the password.',
                  }),
                  type: 'error',
                  show: true,
                });
                changeAuthState('sign-in');
                break;
              case 'UserNotFoundException':
                setBannerDetails({
                  msg: fm({
                    id: 'msg.accNotExist',
                    defaultMessage: 'Account does not exist',
                  }),
                  type: 'error',
                  show: true,
                });
                changeAuthState('sign-up');
                break;
              default:
                break;
            }
          }
        });
    },
  });

  return (
    <Wrapper>
      <div className="title">
        <FormattedMessage
          id="label.resetPassword"
          defaultMessage="Reset your password"
        />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <Input
          autoFocus={false}
          required
          id="email"
          name="email"
          type="text"
          placeholder={fm({
            id: 'label.email',
            defaultMessage: 'Email',
          })}
          onChange={formik.handleChange}
          value={formik.values.email}
          error={
            formik.errors.email ? fm({ id: `${formik.errors.email}` }) : ''
          }
        />
        <Margin />
        <Button
          type="submit"
          disabled={loading}
          onClick={() => {
            formik.validateForm().then((errors: {}) => {
              console.log(Object.keys(errors));
            });
          }}
        >
          {loading
            ? fm({
                id: 'msg.sendingCode',
                defaultMessage: 'Sending code...',
              })
            : fm({
                id: 'label.sendCode',
                defaultMessage: 'Send code',
              })}
        </Button>
      </form>
      <Links>
        <div
          onClick={() => changeAuthState('sign-in')}
          onKeyPress={() => console.log('sign-in')}
          role="button"
          tabIndex={0}
        >
          <FormattedMessage
            id="label.backToSignIn"
            defaultMessage="Back to Sign In"
          />
        </div>
      </Links>
    </Wrapper>
  );
};

export default ForgotPassword;
