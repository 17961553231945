import React, { useEffect, useState } from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import styled from 'styled-components';
import { confirmConsent } from '../../graphql/mutations';
import {
  ConfirmConsentMutationVariables,
  ConfirmConsentMutation,
} from '../../API';
import { gqlOp } from '../../services/graphql';
import Spinner from '../Spinner';

interface ConfirmConsentProps extends RouteComponentProps {
  petitionId?: string;
  email?: string;
  consent?: string;
  doubleOptIn?: string;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
`;

const Content = styled.h1``;

const Loader = styled(Spinner)`
  margin-bottom: 26px;
`;

const RedirectContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Redirect = styled.div`
  display: flex;
`;

const setSignedUserConsent = (consent: string) => {
  // Update the consent state in user details in localStorage.
  // We use this to decide whether to display the GDPR checkbox or not.
  const signedUser = localStorage.getItem('signedUser');
  const userDetails = signedUser ? JSON.parse(signedUser) : {};
  userDetails.consent = consent;
  // if the consent hasn't been given, we will want to continue sending double opt in emails. That's why the value of
  // doubleOptIn depends on the value of consent at this point.
  userDetails.doubleOptIn = consent;
  localStorage.setItem('signedUser', JSON.stringify(userDetails));
};

const ConfirmConsent = (props: ConfirmConsentProps) => {
  const { petitionId, email, consent } = props;

  if (!petitionId || !email || !consent) {
    throw new Error('Missing petitionId and/or email');
  }
  // if the consent hasn't been given, we will want to continue sending double opt in emails. That's why the value of
  // doubleOptIn depends on the value of consent at this point.
  const doubleOptIn = consent;
  const [loading, updateLoading] = useState(true);
  const [timer, setTimer] = useState<number>(5);

  useEffect(() => {
    if (!loading) {
      if (timer) {
        setTimeout(() => {
          setTimer(timer - 1);
        }, 1000);
      } else {
        navigate(`/petition/${petitionId}/share`);
      }
    }
  }, [loading, timer]);

  useEffect(() => {
    // Create an scoped async function in the hook
    const runMutation = async () => {
      try {
        const resp = await gqlOp<
          ConfirmConsentMutation,
          ConfirmConsentMutationVariables
        >(confirmConsent, 'API_KEY', {
          input: { petitionId, email, consent, doubleOptIn },
        });
        console.log(resp);
        updateLoading(false);
        setSignedUserConsent(consent);
      } catch (error) {
        console.log('error', error);
      }
    };
    // Execute the created function directly
    runMutation();
  }, [petitionId, email]);

  return (
    <Wrapper>
      {loading ? (
        <>
          <Content>Updating action</Content>
          <Loader />
        </>
      ) : (
        <RedirectContent>
          <Content>Done</Content>
          <Redirect>Redirecting in {timer} seconds</Redirect>
        </RedirectContent>
      )}
    </Wrapper>
  );
};

export default ConfirmConsent;
