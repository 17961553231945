import React, { useContext } from 'react';
import { navigate, RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import Pulse from 'react-reveal/Pulse';
import { FormattedMessage } from 'react-intl';
import { PetitionContext } from '../services/petition.service';
import Button from '../components/Button';
import Colors from '../globals/Colors';
import { mobile } from '../globals/Devices';
import CoverImage from '../images/cover.jpg';

const Image = styled.div`
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${Colors.lightGrey};
  background-size: cover;
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.4),
      rgba(23, 35, 64, 0.5)
    ),
    url(${CoverImage});
  @media ${mobile} {
    background: none;
    background: ${Colors.blueWhale};
  }
`;

const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Heading = styled.div`
  font-size: 38px;
  font-weight: bold;
  line-height: 1.45;
  letter-spacing: 0.29px;
  text-align: center;
  color: ${Colors.white};
  overflow: hidden;
  margin: 0 auto;

  @media ${mobile} {
    font-size: 24px;
    color: ${Colors.white};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  div {
    margin: 0 10px;
  }
`;

const Landing = (props: RouteComponentProps) => {
  const petitionFromContext: any = useContext(PetitionContext);
  const { setPetitionDetails } = petitionFromContext;

  return (
    <>
      <Image />
      <Content>
        <Fade up delay={200}>
          <Heading>
            <FormattedMessage
              id="label.landingText"
              defaultMessage="The best way to predict the future is to create it"
            />
          </Heading>
        </Fade>
        <ButtonWrapper>
          <Fade up delay={400}>
            <Pulse>
              <Button
                onClick={() => {
                  setPetitionDetails(null);
                  localStorage.removeItem('petitionDetails');
                  navigate('/u/new/');
                }}
              >
                <FormattedMessage
                  id="label.startPetition"
                  defaultMessage="Start a petition"
                />
              </Button>
            </Pulse>
          </Fade>
        </ButtonWrapper>
      </Content>
    </>
  );
};

export default Landing;
