import React, { useState } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line import/no-extraneous-dependencies
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { FormattedMessage } from 'react-intl';
import { Auth } from 'aws-amplify';
import Colors from '../../globals/Colors';
import Button from '../Button';

interface FBLoginProps {
  label: string;
}
const FacebookLogin = styled(Button)`
  background: ${Colors.facebookBlue};
  max-width: 450px;
  border-color: ${Colors.facebookBlue};
  &:disabled {
    background-color: ${Colors.darkGray};
  }
`;

const FBLogin = (props: FBLoginProps) => {
  const { label } = props;
  const [loading, setLoading] = useState<boolean>(false);
  return (
    <FacebookLogin
      type="button"
      disabled={loading}
      onClick={() => {
        setLoading(true);
        Auth.federatedSignIn({
          provider: CognitoHostedUIIdentityProvider.Facebook,
        })
          .then(() => console.log('Fetched data from facebook'))
          .catch(() => setLoading(false));
      }}
    >
      {loading ? (
        <FormattedMessage
          id="label.connectingFB"
          defaultMessage="Connecting to Facebook..."
        />
      ) : (
        label
      )}
    </FacebookLogin>
  );
};

export default FBLogin;
