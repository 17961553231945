import * as React from 'react';
// import styled from 'styled-components';
import StepNumber from './StepNumber';
import TickedStep from './TickedStep';

export interface Props {
  value: number;
  current: number;
  onClick: any;
}

const StepView = (props: Props) => {
  const { value, current, onClick } = props;
  return (
    <>
      {current > value ? (
        <TickedStep
          value={value.toString()}
          current={current === value}
          onClick={onClick}
        />
      ) : (
        <StepNumber
          value={value.toString()}
          current={current === value}
          onClick={onClick}
        />
      )}
    </>
  );
};

export default StepView;
