import * as React from 'react';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';
import Modal from 'react-modal';
import Button from '../Button';
import PetitionTitle from '../PetitionViewComponents/PetitionTitle';
import LeftContent from '../PetitionViewComponents/LeftContent';
import RightContent from '../PetitionViewComponents/RightContent';
import PetitionNotAvailable from '../PetitionViewComponents/PetitionNotAvailable';
import { getPublishedPetition } from '../../graphql/custom-queries';
import {
  GetPublishedPetitionQuery,
  GetPetitionQueryVariables,
} from '../../API';
import { PetitionContext } from '../../services/petition.service';
import { tablet, mobile } from '../../globals/Devices';
import Colors from '../../globals/Colors';
import { getSignatureGoal } from '../../services/Utils';
import { gqlOp } from '../../services/graphql';

// Interface declaration
interface PetitionViewProps extends RouteComponentProps {
  petitionId?: string;
}

// Styles

const customStyles = {
  content: {
    top: '0%',
    left: '0%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '0%',
    width: '100vw',
  },
};

const Wrapper = styled.div`
  margin-top: 120px;
  min-height: calc(100vh - 250px);
  @media ${mobile} {
    margin-top: 80px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: 60px;
  @media ${tablet} {
    flex-direction: column;
    margin-top: 0px;
  }
`;

const CtaBar = styled.div`
  height: 70px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100vw;
  background: ${Colors.whiteSmoke};
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
  @media ${tablet} {
    display: flex;
  }
  @media ${mobile} {
    /* display: ${isReachedBottom => (isReachedBottom ? `none` : `flex`)}; */
  }
`;

const CtaButton = styled(Button)`
  height: 40px;
  width: 90vw;
  max-width: 90vw;
  background: ${Colors.redOrange};
  color: ${Colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
`;

const ModalContent = styled.div``;

const Cross = styled.div`
  font-size: 20px;
  background-color: ${Colors.redOrange};
  color: white;
  padding: 2px;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  border: 0.3px solid ${Colors.lightGray};
  font-weight: normal;
  cursor: pointer;
  position: fixed;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

const Petition = (props: PetitionViewProps) => {
  const { petitionId } = props;
  const shouldNotShowList = ['SPAM', 'INAPPROPRIATE', 'CLOSED'];
  const petitionDetailsFromContext: any = React.useContext(PetitionContext);
  const { petitionDetails, setPetitionDetails } = petitionDetailsFromContext;
  const [loading, setLoading] = React.useState<boolean>(true);

  const [openModal, setModalOpen] = React.useState<boolean>(false);

  // mobile layout
  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      const elem = document.getElementById('right-content');
      const footer = document.querySelector('footer');
      if (elem && footer) {
        if (window.innerWidth < 800) {
          elem.style.display = 'none';
          footer.style.display = 'none';
        } else {
          elem.style.display = 'flex';
          footer.style.display = 'flex';
        }
      }
    });
  }, []);

  React.useEffect(() => {
    const fetchPetition = async () => {
      if (petitionId) {
        setPetitionDetails(null);
        console.log('petitionDetails');
        try {
          const resp = await gqlOp<
            GetPublishedPetitionQuery,
            GetPetitionQueryVariables
          >(getPublishedPetition, 'API_KEY', {
            id: petitionId,
          });
          localStorage.setItem(
            'petitionDetails',
            JSON.stringify(resp.getPetition)
          );
          setPetitionDetails(resp.getPetition);
          setLoading(false);
        } catch (error) {
          setPetitionDetails(null);
          setLoading(false);
          console.log('petition details error', error);
        }
      } else {
        setPetitionDetails(null);
        setLoading(false);
      }
    };
    fetchPetition();
  }, []);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '26px',
            height: '90vh',
          }}
        >
          Loading...
        </div>
      ) : (
        <>
          {petitionDetails &&
            !shouldNotShowList.includes(petitionDetails.moderationStatus) && (
              <>
                <Wrapper>
                  <PetitionTitle
                    petitionTitle={petitionDetails.title}
                    editMode={false}
                  />
                  <ContentWrapper>
                    <LeftContent
                      petitionId={petitionId || null}
                      imageUrl={petitionDetails.imageUrl}
                      content={petitionDetails.body}
                      owner={petitionDetails.user.name}
                      target={petitionDetails.target}
                      editMode={false}
                    />
                    <RightContent
                      thermometer={{
                        goal: getSignatureGoal(petitionDetails?.actionCounter),
                        achieved: petitionDetails?.actionCounter || 1,
                      }}
                      editMode={false}
                      petitionId={petitionDetails.id}
                      id="right-content"
                    />
                  </ContentWrapper>
                  <CtaBar id="cta-bar">
                    <CtaButton onClick={() => setModalOpen(true)}>
                      SIGN THIS PETITIONS
                    </CtaButton>
                  </CtaBar>
                  <Modal
                    isOpen={openModal}
                    shouldCloseOnOverlayClick
                    shouldCloseOnEsc
                    onRequestClose={() => setModalOpen(false)}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <ModalContent>
                      <Cross onClick={() => setModalOpen(false)}>X</Cross>
                      <RightContent
                        thermometer={{
                          goal: getSignatureGoal(
                            petitionDetails?.actionCounter
                          ),
                          achieved: petitionDetails?.actionCounter || 1,
                        }}
                        editMode={false}
                        petitionId={petitionDetails.id}
                        id="right-content"
                      />
                    </ModalContent>
                  </Modal>
                </Wrapper>
              </>
            )}
          {(petitionDetails === null ||
            shouldNotShowList.includes(petitionDetails.moderationStatus)) && (
            <PetitionNotAvailable />
          )}
        </>
      )}
    </>
  );
};

export default Petition;
